import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'

export const listMethod = (state = [], action) => {
  switch (action.type) {
    case Constant.WELL_RESERVES_UNDRILLED_METHOD_GET_SUCCESS:
      return action.payload ? action.payload : []
    case Constant.APP_DESTROY: return [];
    default:
      return state
  }
}

export const allIds = (state = [], action) => {
  switch (action.type) {
    case Constant.WELL_RESERVES_UNDRILLED_SYNC_SUCCESS:
    case Constant.WELL_RESERVES_UNDRILLED_SAVE_SUCCESS:
    case Constant.WELL_RESERVES_UNDRILLED_GET_SUCCESS: {
      if (!state.includes(action.payload.wellName)) {
        return [...state, action.payload.wellName]
      }
      return state
    }
    case Constant.APP_DESTROY: return [];
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case Constant.WELL_RESERVES_UNDRILLED_SYNC_SUCCESS:
    case Constant.WELL_RESERVES_UNDRILLED_SAVE_SUCCESS:
    case Constant.WELL_RESERVES_UNDRILLED_GET_SUCCESS:
      return {
        ...state,
        [action.payload.wellName]: {
          ...state[action.payload.wellName],
          ...action.payload,
        }
      }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export default combineReducers({
  listMethod,
  allIds,
  byId,
})

const sortByOrder = (a, b) => a.order - b.order

export const createListMethod = state => {
  return state.wellReservesUndrilled.listMethod.slice().sort(sortByOrder)
}

export const createUndrilledReservesIdSelector = (state, id) => {
  return state.wellReservesUndrilled.byId[id] ? Object.assign({}, state.wellReservesUndrilled.byId[id]) : null
}
