import { combineReducers } from 'redux'
import api from './api'
import session from './session'
import users from './users'
import projects from './projects'
import wells from './wells'
import wellReserves from './wellReserves'
import wellDecisions from './wellDecisions'
import wellReservesUndrilled from './wellReservesUndrilled'
import sandReserveOptions from './sandReserveOptions'
import projectSummary from './projectSummary'
import views from './views'
import admin from './admin'
import authenSession from './authenSession'

export default combineReducers({
  api,
  session,
  users,
  projects,
  wells,
  wellReserves,
  wellDecisions,
  wellReservesUndrilled,
  sandReserveOptions,
  views,
  admin,
  projectSummary,
  authenSession
})
