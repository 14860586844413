import RequestController from '../helpers/requestController'
import { delay } from '../helpers'
import { isParameter } from 'typescript'
import { useHistory } from 'react-router-dom'

export const clearCache = () => {
    RequestController.get('project/reload');
    return RequestController.clearCache();}

export const getAccount = () => RequestController.getAccount()

export const getSessionToken = () => RequestController.getSessionToken()

export const microsoftLogIn = () => RequestController.microsoftLogIn()

export const becomeSession = token => RequestController.becomeSession(token)

export const logOut = () => RequestController.logOut()

/**
 * List users with all foreign keys data
 * 
 * @return {Promise}
 */
export const getUsers = () => RequestController.get('user/list?responsibility=true')

/**
 * List users from AD
 * 
 * @return {Promise}
 */
export const syncUsers = () => RequestController.get('user/sync')

/**
 * Authenticate current login user
 * 
 * @return {Promise}
 */
export const authenUser = () => RequestController.post('user/authen').then(payload => delay(1000).then(() => payload))

/**
 * Get current user authorization roles and projects
 * 
 * @param {String} accessToken - Access token returns from /user/authen
 * @return {Promise}
 */
export const authorizeRole = accessToken => RequestController.post('role/authorize', { accessToken })
/**
 * Add users from AD to surface
 * 
 * @param {Object} payload - list of users
 * @return {Promise}
 */
export const registerUsers = payload => RequestController.post('user/register', payload)

/**
 * Disable users from Surface
 * 
 * @param {Object} payload - list of users
 * @return {Promise}
 */
export const removeUsers = payload => RequestController.post('user/remove', payload)

/**
 * @deprecated
 * @param {String} payload
 * @return {Promise}
 */
export const getAuthens = () => RequestController.get('user/authen/recent')

/**
 * @param {String} token
 * @return {Promise}
 */
export const authenticate = payload => RequestController.post('user/authenticate', payload)
//    .then(() => { }).catch(error => {
//    console.log(error)
//    //let history = useHistory();
//    //history.push('/Authenticate');
//})


/**
 * Update user authen properties
 * 
 * @param {Object} payload - object of user id and properties
 * @return {Promise}
 */
export const postAuthen = payload => RequestController.post('user/authen/post', payload)

/**
 * List authorized users in specific location
 * 
 * @param {String} locationId - location id
 * @return {Promise}
 */
export const getRolesAuthorize = locationId => RequestController.get(`role/authorize/recent?id=${locationId}`)

/**
 * List all possible roles
 * 
 * @return {Promise}
 */
export const getRoles = () => RequestController.get('role/list', { cache: { ignoreCache: false } })

/**
 * List projects with all authorized user
 * 
 * @return {Promise}
 */
export const getProjectAuthorize = () => RequestController.get('project/authorize/recent')

/**
 * @deprecated
 * @param {String} payload
 * @return {Promise}
 */
export const getProjectLocations = () => RequestController.get('project/location/recent')

/**
 * List all possible area of location `NORTH` or `SOUTH`
 * 
 * @return {Promise}
 */
export const getLocations = () => RequestController.get('area/location/list', { cache: { ignoreCache: false } })

/**
 * Update user's role
 * 
 * @param {String} payload - roles changes
 * @return {Promise}
 */
export const postRoleAuthorize = payload => RequestController.post('role/authorize/post', payload)

/**
 * Update project's users authorization
 * 
 * @param {String} payload - list of users changes
 * @return {Promise}
 */
export const postProjectAuthorize = payload => RequestController.post('project/authorize/post', payload)

/**
 * Update project's location
 * 
 * @param {String} payload - new location
 * @return {Promise}
 */
export const postProjectLocation = payload => RequestController.post('project/location/post', payload)

/**
 * @deprecated
 * @param {String} payload
 * @return {Promise}
 */
export const postUser = payload => RequestController.post('user/post', payload)

/**
 * @deprecated
 * @param {String} payload
 * @return {Promise}
 */
export const putUser = payload => RequestController.put('user/put', payload)

/**
 * Get project summary
 * 
 * @param {String} payload - project id
 * @return {Promise}
 */
export const getProject = id => RequestController.get(`project/getsummary?id=${id}`)

/**
 * List all active projects
 * 
 * @return {Promise}
 */
export const getSyncProjects = () => RequestController.get('project/sync')

/**
 * List all projects with all foreign key object
 * 
 * @return {Promise}
 */
export const getProjects = () => RequestController.get('project/list?responsibility=true')

/**
 * Get project summary metric data
 * @param {String} id - project id
 * @param {String} name - project name
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getProjectProductive = (id, name, ignoreCache) => RequestController.get(
  `project/productive?name=${name}&id=${id}`,
  { cache: { ignoreCache: !!ignoreCache } }
)

/**
 * Get project planning details
 * 
 * @param {String} id - project id
 * @param {String} name - project name
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getProjectPlanned = (id, name, ignoreCache) => RequestController.get(
  `project/planned?name=${name}&id=${id}`,
  { cache: { ignoreCache: !!ignoreCache } }
)

/**
 * Get project summary well setup
 * 
 * @param {String} id - project id
 * @param {String} name - project name
 * @return {Promise}
 */
export const getProjectSummary = (id, name) => RequestController.get(`project/well/recent?name=${name}&id=${id}`)

/**
 * Get project summary well setup if not existing in LIVE
 * 
 * @param {String} id - project id
 * @param {String} name - project name
 * @return {Promise}
 */
export const syncProjectSummary = (id, name) => RequestController.get(`project/well/sync?name=${name}&id=${id}`)

/**
 * Update project summary well setup
 * 
 * @param {Object} payload - new well setup list
 * @return {Promise}
 */
export const postProjectSummary = payload => RequestController.post('project/well/post', payload)

/**
 * Publish project summary well setup to public
 * 
 * @param {String} id - projectSpaceId returned from @see postProjectSummary
 * @param {Object} payload - new well setup list
 * @return {Promise}
 */
export const publishProjectSummary = (id, payload) => RequestController.post(`project/well/publish?id=${id}`, payload)

/**
 * Get well summary metric
 * 
 * @param {String} name - name of well
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getWell = (name, ignoreCache) => RequestController.get(`well/job/productive?name=${name}`, { cache: { ignoreCache: !!ignoreCache } })

/**
 * Get well planning details
 * 
 * @param {String} name - name of well
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getWellPlanned = (name, ignoreCache) => RequestController.get(`well/planned/get?name=${name}`, { cache: { ignoreCache: !!ignoreCache } })

/**
 * Get status of well
 * 
 * @param {String} name - name of well
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getWellStatus = (name, ignoreCache) => RequestController.get(`well/status?name=${name}`, { cache: { ignoreCache: !!ignoreCache } })

/**
 * List all wells in project
 * 
 * Note: Normally call this api twice, one with status and one without.
 * UI will display list of wells first then display status of each well later
 * 
 * @param {String} id - project id
 * @param {Boolean} withStatus - `true` to include well status, `false` to exclude well status.
 * @return {Promise}
 */
export const getWells = (id, withStatus) => RequestController.get(
  `well/list?id=${id}&status=${!!withStatus}`,
  { cache: { ignoreCache: !!withStatus } }
)

/**
 * List specific wells latest sor status
 * 
 * @param {Object} payload - list of wells
 * @return {Promise}
 */
export const getWellsSORStatus = payload => RequestController.post('well/sor', payload)

/**
 * @deprecated
 * @param {String} name - name of well
 * @return {Promise}
 */
export const getPlannedReserve = name => RequestController.get(`well/reserve?name=${name}`)

/**
 * Calculate drill reserve for specific well
 * 
 * @param {Object} payload - object returned from @see getWellReserves and @see syncWellReserves
 * @return {Promise}
 */
export const calculateWellReserves = payload => RequestController.post('well/drilled/calculate', payload)

/**
 * Calculate undrill reserve for specific well
 * 
 * @param {Object} payload - object returned from @see getUndrilledReserves and @see syncWellUndrilledReserves
 * @return {Promise}
 */
export const calculateWellUndrillReserves = payload => RequestController.post('well/undrilled/calculate', payload)

/**
 * Get drill reserve for specific well if not existing in LIVE
 * 
 * @param {String} name - well name
 * @param {String} project - project name
 * @param {String} platform - platform name
 * @return {Promise}
 */
export const syncWellReserves = (name, project, platform) => RequestController.get(
  `well/drilled/sync?name=${name}&project=${project}&platform=${platform}`
)

/**
 * Get undrill reserve for specific well if not existing in LIVE
 * 
 * @param {String} name - well name
 * @param {String} project - project name
 * @param {String} platform - platform name
 * @return {Promise}
 */
export const syncWellUndrilledReserves = (name, project, platform) => RequestController.get(
  `well/undrilled/sync?name=${name}&project=${project}&platform=${platform}`
)

/**
 * Get drill reserve for specific well
 * 
 * @param {String} name - well name
 * @return {Promise}
 */
export const getWellReserves = name => RequestController.get(`well/drilled/recent?name=${name}`)

/**
 * Update drill reserve for specific well
 * 
 * @param {Object} payload - a modified object @see getWellReserves and @see syncWellReserves
 * and should include these properties `id`, `projectName` `platformName` `wellName`
 * @return {Promise}
 */
export const saveWellReserves = (id, payload) => RequestController.post('well/drilled/post', payload)

/**
 * Merge @see getWellReserves and @see syncWellReserves and return updated data
 * 
 * @param {Object} payload - included these properties `id`, `projectName` `platformName` `wellName`
 * @return {Promise}
 */
export const mergeWellReserves = payload => RequestController.post('well/drilled/merge', payload)

/**
 * Get all sand openwork categories
 * 
 * @return {Promise}
 */
export const getOpenWorkCategories = () => RequestController.get('sand/category', { cache: { ignoreCache: false } })

/**
 * Get all sand depletions
 * 
 * @return {Promise}
 */
export const getDepletions = () => RequestController.get('sand/depletion', { cache: { ignoreCache: false } })

/**
 * Get all sand bo profiles
 * 
 * @return {Promise}
 */
export const getBoProfiles = () => RequestController.get('sand/fvfprofile?type=bo', { cache: { ignoreCache: false } })

/**
 * Get all sand bg profiles
 * 
 * @return {Promise}
 */
export const getBgProfiles = () => RequestController.get('sand/fvfprofile?type=bg', { cache: { ignoreCache: false } })

/**
 * Get all sand analogy set area names
 * 
 * @return {Promise}
 */
export const getAreaNames = () => RequestController.get('sand/analogy?type=set', { cache: { ignoreCache: false } })

/**
 * Get all sand analogy scenarios
 * 
 * @return {Promise}
 */
export const getAreaDiscountFactors = () => RequestController.get(`sand/analogy?type=scenario`, { cache: { ignoreCache: false } })

/**
 * Get all sand analogy scenario
 * 
 * @return {Promise}
 */
export const getWellDefaultAnalogy = name => RequestController.get(`well/analogy?name=${name}`)

/**
 * Publish well reserve to public
 * 
 * @param {String} id - well id
 * @return {Promise}
 */
export const publishWellReserves = id => RequestController.post(`well/publish?id=${id}`)

/**
 * Get well undrill method `Manual` `S-Curve` `Deterministic` `etc.`
 * 
 * @return {Promise}
 */
export const getMethodUndrilled = () => RequestController.get('well/undrilled/method', { cache: { ignoreCache: false } })

/**
 * Update drill reserve for specific well
 * 
 * @param {String} id - well id
 * @param {Object} payload - a modified object @see getUndrilledReserves and @see syncWellUndrilledReserves
 * and should include these properties `id`, `projectName` `platformName` `wellName`
 * @return {Promise}
 */
export const saveUndrilledReserves = (id, payload) => RequestController.post('well/undrilled/post', payload)

/**
 * Get latest undrill reserve for specific well
 * 
 * @param {String} name - well name
 * @return {Promise}
 */
export const getUndrilledReserves = name => RequestController.get(`well/undrilled/recent?name=${name}`)

/**
 * Get well reserve matric summary
 * 
 * @param {String} name - well name
 * @param {String} status - well status
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getCalculateReserves = (name, status, ignoreCache) => RequestController.get(
  `well/reserve?name=${name}&status=${status}`,
)

/**
 * Get project reserve matric summary
 * 
 * @param {String} name - well name
 * @param {String} status - well status
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getProjectCalculateReserves = (id, name, ignoreCache) => RequestController.get(
    `project/reserve?name=${name}&id=${id}`
)

/**
 * Get well dpi matric summary
 * 
 * @param {String} status - well status
 * @param {Object} payload - include these properties `wellName` `project` `platform`
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getCalculateDpi = (status, payload, ignoreCache) => RequestController.post(
  `well/dpi/simple?wellName=${payload.wellName}&wellStatus=${status}`,
  { ...payload, wellStatus: status }
)

/**
 * Get well dpi matric summary but only status planned
 * 
 * @param {Object} payload - include these properties `wellName` `project` `platform`
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache
 * @return {Promise}
 */
export const getCalculatePlannedDpi = (payload, ignoreCache) => getCalculateDpi('Planned', payload, ignoreCache)

/**
 * List all decision tree in specific well
 * 
 * @param {String} name - well name
 * @return {Promise}
 */
export const getDecisions = name => RequestController.get(`decision/get/list?wellName=${name}`)

/**
 * Get specific decision tree
 * 
 * @param {String} id - decision tree id
 * @return {Promise}
 */
export const getDecision = id => RequestController.get(`decision/get?tabId=${id}`)

/**
 * Delete specific decision tree
 * 
 * @param {Object} payload - include these properties `tabId`
 * @return {Promise}
 */
export const deleteDecision = payload => RequestController.post('decision/delete', payload)

/**
 * Save specific decision tree
 * 
 * @param {Object} payload - modified data from @see getDecision
 * @return {Promise}
 */
export const saveDecision = payload => RequestController.post('decision/save', payload)

/**
 * Publish specific decision tree to public
 * 
 * @param {Object} payload - modified data from @see getDecision
 * @return {Promise}
 */
export const publishDecision = payload => RequestController.post('decision/publish', payload)

/**
 * Get project dpi summary metric
 * 
 * @param {Object} payload - include these properties `projectName` `platformName` `rllcpProjectId` @see getProject
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache * 
 * @return {Promise}
 */
export const getProjectDpi 
= (payload, ignoreCache) => RequestController.post(
  `project/dpi/evaluate?projectName=${payload.projectName}`,
    payload
)

/**
 * Get project pay summary chart
 * 
 * @param {String} id - project id
 * @param {String} name - project name
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache * 
 * @return {Promise}
 */
export const getProjectSummaryPay = (id, name, ignoreCache) => RequestController.get(
    `project/well/pay?id=${id}&name=${name}`
)

/**
 * Get project reserve summary chart
 * 
 * @param {String} id - project id
 * @param {String} name - project name
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache * 
 * @return {Promise}
 */
export const getProjectSummaryReserves = (id, name, ignoreCache) => RequestController.get(
    `project/well/reserve?id=${id}&name=${name}`
)

/**
 * Get project rig efficiency summary chart
 * 
 * @param {String} id - project id
 * @param {String} name - project name
 * @param {Boolean} ignoreCache - `true` to ignore cache, `false` to use cache * 
 * @return {Promise}
 */
export const getProjectSummaryRig = (id, name, ignoreCache) => RequestController.get(
    `project/well/rig?id=${id}&name=${name}`
)
