import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, connect } from 'react-redux'
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog'

let globalStore

export const initWithStore = (store) => {
  globalStore = store
}

export default (
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
) => WrappedComponent => {
  const div = document.createElement('div')

  const Component = ({ title = '', minWidth = 400, onClose, children }) => (
    <Dialog
      hidden={false}
      onDismiss={onClose}
      dialogContentProps={{
        title,
        type: DialogType.normal,
        showCloseButton: true,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth={minWidth}
      maxWidth="100vw"
    >
      {children}
    </Dialog>
  )


  function open(props) {
    render(props)
  }

  function close() {
    destroy()
  }

  function destroy(callback = () => {}) {
    const unmountResult = ReactDOM.unmountComponentAtNode(div)
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div)
    }
    callback()
  }

  function render(props) {
    const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps, mergeProps)(
      innerProps => <WrappedComponent {...innerProps} {...props}/>
    )
    ReactDOM.render(
      <Provider store={globalStore}>
        <Component onClose={() => destroy(props.onClose)} title={props.title} minWidth={props.minWidth}>
          <ConnectedComponent />
        </Component>
      </Provider>
    , div)
  }

  return {
    close,
    open,
  }
}
