import Ajv from 'ajv'
import ajvKeywords from 'ajv-keywords'
import { gocTypes, oilTypes, gasTypes } from './wellReserve'
import * as StringHelper from './string'

const ajv = ajvKeywords(Ajv({ allErrors: true }), 'regexp')

/**
 * Require fields for value
 */
const defaultValueFields = [
    'pos', 'avgSw', 'avgPor', 'topTVDSS',
    'discountFactorMechanical', 'discountFactorCO2',
    'discountFactorCementQuality', 'discountFactorBC',
    'payClassification', 'openWorksContactCategory']
const oilValueFields = [
    'netOilVT', 'fvfProfileBoProfileId',
    'analogyOilAreaSetId', 'analogyOilAreaScenarioId']
const gasValueFields = [
    'netGasVT', 'fvfProfileBgProfileId',
    'analogyGasAreaSetId', 'analogyGasAreaScenarioId']
const pressureFields = ['pressureRFTInPSI', 'pressureEstimatedInitialInPSI']

/**
 * Require fields for header
 */
const defaultHeaderFields = ['wellType', 'pipelinePressure', 'bcPos', 'bcCompressionRatio']
const oilHeaderFields = ['gor']
const gasHeaderFields = ['cgr']

const validate = (schema = {}, input = {}) => {
    return new Promise((resolve, reject) => {
        if (ajv.validate(schema, input)) {
            return resolve()
        } else {
            console.error(ajv.errors)
            return reject(ajv.errors)
        }
    })
        .then(() => Promise.resolve([input]))
        .catch(errors => Promise.resolve([input, errors]))
}

const validateOpenWorkCategory = (dropdownOptions) => ({
    validate(schema, data) {
        return StringHelper.isValueNullOrEmpty(data.openWorksContactCategory) ? true : !!dropdownOptions.findOpenworkCategory(data.openWorksContactCategory)
    },
    errors: false,
})

const validateTypeGas = (dropdownOptions) => ({
    validate(schema, data) {
        if (StringHelper.isValueNullOrEmpty(data.analogyGasAreaName) && StringHelper.isValueNullOrEmpty(data.analogyGasAreaDiscountFactor)) {
            return true
        } else {
            const matched = dropdownOptions.findAreaDiscountFactor(data.analogyGasAreaName, data.analogyGasAreaDiscountFactor)
            if (!!matched) {
                return data.analogyGasAreaSetId === matched.analogyDataSetID && data.analogyGasAreaScenarioId === matched.analogyScenarioID
            } else {
                return false
            }
        }
    },
    errors: false,
})

const validateTypeOil = (dropdownOptions) => ({
    validate(schema, data) {
        if (StringHelper.isValueNullOrEmpty(data.analogyOilAreaName) && StringHelper.isValueNullOrEmpty(data.analogyOilAreaDiscountFactor)) {
            return true
        } else {
            const matched = dropdownOptions.findAreaDiscountFactor(data.analogyOilAreaName, data.analogyOilAreaDiscountFactor)
            if (!!matched) {
                return data.analogyOilAreaSetId === matched.analogyDataSetID && data.analogyOilAreaScenarioId === matched.analogyScenarioID
            } else {
                return false
            }
        }
    },
    errors: false,
})

const validateWithOptions = (schema, input, options = {}) => {
    if (options.dropdownOptions) {
        try {
            ajv.addKeyword('validateOpenWorkCategory', validateOpenWorkCategory(options.dropdownOptions))
            ajv.addKeyword('validateTypeGas', validateTypeGas(options.dropdownOptions))
            ajv.addKeyword('validateTypeOil', validateTypeOil(options.dropdownOptions))
        } catch (e) { }
    }
    return validate(schema, input)
}

const validateWellReserve = (input = {}, options = {}) => {
    return validateWithOptions({
        title: 'Oil/Gas',
        description: 'Oil/Gas Product for TCRS',
        type: 'object',
        required: ['header', 'value'],
        properties: {
            header: {
                description: 'Sand Header properties',
                type: 'object',
                required: options.required ? defaultHeaderFields : [],
                allOf: [
                    {
                        if: {
                            properties: {
                                wellType: {
                                    regexp: '/gas/i'
                                }
                            }
                        },
                        then: {
                            required: options.required ? gasHeaderFields : [],
                        },
                        else: {
                            required: options.required ? oilHeaderFields : [],
                        }
                    }
                ],
                properties: {
                    wellType: { type: 'string', regexp: '/gas|oil/i' },
                    pipelinePressure: { type: 'number' },
                    bcPos: { type: 'number' },
                    bcCompressionRatio: { type: 'number' },
                    gor: { type: 'number', minimum: 0 },
                    cgr: { type: 'number', minimum: 0 },
                    calculationType: { type: 'string' }
        }
      },
value: {
    description: 'Sand properties',
        type: 'array',
            minItems: 1,
                items: {
        type: 'object',
            required: options.required ? defaultValueFields : [],
                allOf: [
                    {
                        validateOpenWorkCategory: true
                    },
                    {
                        if: {
                            properties: {
                                payClassification: {
                                    regexp: '/pdva/i'
                                }
                            }
                        },
                        then: {
                            required: options.required ? pressureFields : [],
                        }
                    },
                    {
                        if: {
                            properties: {
                                openWorksContactCategory: {
                                    regexp: { pattern: gasTypes.join('|'), flags: 'i' }
                                }
                            }
                        },
                        then: {
                            required: options.required ? gasValueFields : [],
                            validateTypeGas: true,
                        }
                    },
                    {
                        if: {
                            properties: {
                                openWorksContactCategory: {
                                    regexp: { pattern: oilTypes.join('|'), flags: 'i' }
                                }
                            }
                        },
                        then: {
                            required: options.required ? oilValueFields : [],
                            validateTypeOil: true,
                        }
                    },
                    {
                        if: {
                            properties: {
                                openWorksContactCategory: {
                                    regexp: { pattern: gocTypes.join('|'), flags: 'i' }
                                }
                            }
                        },
                        then: {
                            required: options.required ? gasValueFields.concat(oilValueFields) : [],
                        }
                    },
                ],
                    properties: {
            pos: { type: 'number', minimum: 0, maximum: 1 },
            fvfProfileBgProfileId: { type: 'number' },
            fvfProfileBoProfileId: { type: 'number' },
            analogyOilAreaSetId: { type: 'number' },
            analogyOilAreaScenarioId: { type: 'number' },
            analogyGasAreaSetId: { type: 'number' },
            analogyGasAreaScenarioId: { type: 'number' },
            netGasVT: { type: 'number' },
            netOilVT: { type: 'number' },
            avgSw: { type: 'number' },
            avgPor: { type: 'number' },
            topTVDSS: { type: 'number' },
            pressureRFTInPSI: { type: 'number' },
            pressureRFTInPPG: { type: 'number' },
            pressureEstimatedInitialInPSI: { type: 'number', exclusiveMinimum: 0 },
            pressureEstimatedInitialInPPG: { type: 'number' },
            discountFactorMechanical: { type: 'number' },
            discountFactorCO2: { type: 'number' },
            discountFactorCementQuality: { type: 'number' },
            discountFactorBC: { type: 'number' },
            payClassification: {
                type: 'string',
                    regexp: '/pdva|new|accelerated|depleted/i'
            },
            openWorksContactCategory: {
                type: 'string',
                    regexp: { pattern: gasTypes.concat(oilTypes).concat(gocTypes).join('|'), flags: 'i' }
            },
        }
    }
}
    }
  }, input, options)
}

export const validateForCalculation = (input, options) => validateWellReserve(input, {
    ...options,
    required: true,
})
export const validateForSave = (input, options) => validateWellReserve(input, options)

export const getInvalidFields = (errors = [], pattern = '') => {
    return errors ? Array.from(new Set(errors.map(error => {
        const matched = error.dataPath.match(pattern) || error.message.match(pattern)
        return matched ? `${error.dataPath} ${error.message}` : undefined
    }))).filter(item => !!item) : []
}

export const getInvalidFieldsArray = (errors = [], fieldName = '') => {
    // eslint-disable-next-line no-useless-escape
    return getInvalidFields(errors, new RegExp(`.${fieldName}\[[0-9]+\]`))
}

export const getInvalidFieldsObject = (errors = [], fieldName = '') => {
    return getInvalidFields(errors, `.${fieldName}`)
}
