import React, { memo } from 'react'
import { Shimmer } from 'office-ui-fabric-react/lib/Shimmer'
import style from './style.module.css'

const shimmerStyle = {
  shimmerWrapper: [
    {
      backgroundColor: '#deecf9'
    }
  ],
  shimmerGradient: [
    {
      backgroundColor: '#deecf9',
      backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)'
    }
  ]
}


export default memo(() => (
    <div className={style.loader}>
    <Shimmer styles={shimmerStyle}/>
    <Shimmer width="75%" styles={shimmerStyle} />
    <Shimmer width="50%" styles={shimmerStyle} />
  </div>
))
