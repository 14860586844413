
/**
 * Remove Empty Key from Object
 * @param {*} obj
 */
export const removeEmpty = (obj: object): object => {
  const result: { [key: string]: any } = {}
  Object.entries(obj).forEach(([key, val]) => {
    if (val || val === 0 || val === false) result[key] = val
  })
  return result
}

/**
 * Define default value to object
 * @param {*} obj
 * @param {*} defaultValue
 */
export const defineDefaultValue = (obj: object = {}, defaultValue: any) => {
  const result: { [key: string]: any } = {}
  Object.entries(obj).forEach(([key, val]) => {
    // eslint-disable-next-line use-isnan
    if (val === null || val === undefined) result[key] = defaultValue
    else result[key] = val
  })
  return result
}
