import * as Constant from '../actions/constants'

const initialState = {
  globalAlertMessage: '',
  isAppReady: false,
  isGlobalLoading: false,
  isGlobalAlertVisible: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Constant.APP_READY:
      return {
        ...state,
        isAppReady: action.isAppReady,
      }
    case Constant.GLOBAL_LOADER:
      return {
        ...state,
        isGlobalLoading: action.isGlobalLoading,
      }
    case Constant.GLOBAL_ALERT_OPEN:
      return {
        ...state,
        isGlobalAlertVisible: true,
        globalAlertMessage: action.message,
      }
    case Constant.GLOBAL_ALERT_CLOSE:
      return {
        ...state,
        isGlobalAlertVisible: false,
      }
    default:
      return state
  }
}
