import React, { memo } from 'react'
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner'
import { CommandBarButton, DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button'
import style from './style.module.css'

export enum ButtonType {
  primary = 'primary',
  icon = 'icon',
  command = 'command',
  default = 'default',
}

export interface IButton {
  loading?: boolean
  disabled?: boolean
  type?: ButtonType
  iconProps?: any
  text?: string
  menuProps?: any
}

export default memo<IButton>(({ loading, disabled, type = ButtonType.default, ...rest }) => {

  let height
  const Component = (() => {
    if (type === ButtonType.primary) {
      return PrimaryButton
    } else if (type === ButtonType.icon) {
      height = 30
      return IconButton
    } else if (type === ButtonType.command) {
      height = 30
      return CommandBarButton
    }
    return DefaultButton
  })()

  return (
    <Component
      {...rest}
      styles={{ label: { display: 'none' }, root: { height } }}
      disabled={loading || disabled}
      iconProps={loading ? {} : rest.iconProps}
    >
      {loading && <><Spinner size={SpinnerSize.xSmall}/>&nbsp;</>}
      <span className={style.text}>{rest.text}</span>
    </Component>
  )
})
