import { openGlobalAlert, closeGlobalAlert } from '../../actions/views'
import { LOCATION_CHANGE } from '../../actions/constants'

export default store => next => action => {
  const result = next(action)
  if (
    action.type.includes('FAILURE') &&
    action.error &&
    typeof action.error.message === 'string' &&
    action.alert !== false &&
    action.error.status !== 499 &&
    action.error.status !== 403
  ) {
    store.dispatch(openGlobalAlert(`Error: ${action.error.message}, Req ID: ${action.error.reqId}, Please contact Power Users`))
  } else if (
    action.type.includes('FAILURE') &&
    action.error &&
    action.alert !== false &&
    action.error.status !== 499
  ) {
    store.dispatch(openGlobalAlert(`Error: ${action.error.code}`))
  } else if (action.type === LOCATION_CHANGE) {
    store.dispatch(closeGlobalAlert())
  }
  return result
}
