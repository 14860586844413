import * as Constants from './constants'
import * as Api from '../apis'
import {getDollarBoE} from '../apis/admin'
import { getWellsSORStatus } from './wells'
import {
  createRecentDrilledReservesIdSelector, createSyncDrilledReservesIdSelector,
  createDrilledReservesIdSelector
} from '../reducers/wellReserves'
import { createWellIdSelector } from '../reducers/wells'
import * as SandReserveOptions from '../reducers/sandReserveOptions'
import {
  validateForCalculation, validateForSave,
  getInvalidFieldsArray, getInvalidFieldsObject
} from '../helpers/validator'
import { mapReservesHeaderAttributes } from '../helpers/wellReserve'

const dropdownOptions = (state) => ({
  findOpenworkCategory: SandReserveOptions.findOpenworkCategory(state),
  findPayClassification: SandReserveOptions.findPayClassification(state),
  findBoProfile: SandReserveOptions.findBoProfile(state),
  findBgProfile: SandReserveOptions.findBgProfile(state),
  findAreaName: SandReserveOptions.findAreaName(state),
  findAreaDiscountFactor: SandReserveOptions.findAreaDiscountFactor(state),
})

export const wellGetCalculateDpiRequest = id => ({
  type: Constants.WELL_CALCULATE_DPI_GET_REQUEST,
  id,
})

export const wellGetCalculateDpiSuccess = payload => ({
  type: Constants.WELL_CALCULATE_DPI_GET_SUCCESS,
  payload,
})

export const wellGetCalculateDpiFailure = error => ({
  type: Constants.WELL_CALCULATE_DPI_GET_FAILURE,
  error,
})

export const getCalculateDpi = (id, ignoreCache) => {
  return (dispatch, getState) => {
    dispatch(wellGetCalculateDpiRequest(id))
    const well = createWellIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!well) return Promise.reject('Well not found')
      const _payload = {
        wellName: well.name,
        project: well.project,
        platform: well.platform,
      }
      return Promise.all([
        Api.getCalculatePlannedDpi(_payload, ignoreCache).then(response => {
          dispatch(wellGetCalculateDpiSuccess(response))
          return response
        }),
        Api.getCalculateDpi(well.status, _payload, ignoreCache).then(response => {
          dispatch(wellGetCalculateDpiSuccess(response))
          return response
        })
      ])
    }).catch(error => {
      dispatch(wellGetCalculateDpiFailure(error))
      return Promise.reject(error)
    })
  }
}

export const wellGetCalculateReservesRequest = id => ({
  type: Constants.WELL_CALCULATE_RESERVES_GET_REQUEST,
  id,
})

export const wellGetCalculateReservesSuccess = payload => ({
  type: Constants.WELL_CALCULATE_RESERVES_GET_SUCCESS,
  payload,
})

export const wellGetCalculateReservesFailure = error => ({
  type: Constants.WELL_CALCULATE_RESERVES_GET_FAILURE,
  error,
})

export const getCalculateReserves = (id, ignoreCache) => {
  return (dispatch, getState) => {
    dispatch(wellGetCalculateReservesRequest(id))
    const well = createWellIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!well) return Promise.reject('Well not found')
      return Api.getCalculateReserves(id, well.status, ignoreCache)
    }).then(response => {
      dispatch(wellGetCalculateReservesSuccess({ ...response, wellName: id }))
      return response
    }).catch(error => {
      dispatch(wellGetCalculateReservesFailure(error))
      return Promise.reject(error)
    })
  }
}

export const wellGetplannedReserveRequest = id => ({
  type: Constants.WELL_PLANNED_RESERVES_REQUEST,
  id,
})

export const wellGetplannedReserveSuccess = payload => ({
  type: Constants.WELL_PLANNED_RESERVES_SUCCESS,
  payload,
})

export const wellGetplannedReserveFailure = error => ({
  type: Constants.WELL_PLANNED_RESERVES_FAILURE,
  error,
})

export const getPlannedReserve = id => {
  return dispatch => {
    dispatch(wellGetplannedReserveRequest(id))
    return Api.getPlannedReserve(id)
      .then(response => {
        dispatch(wellGetplannedReserveSuccess(response))
        return response
      }).catch(error => {
        dispatch(wellGetplannedReserveFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellReservesSaveRequest = id => ({
  type: Constants.WELL_RESERVES_SAVE_REQUEST,
  id,
})

export const wellReservesSaveSuccess = payload => ({
  type: Constants.WELL_RESERVES_SAVE_SUCCESS,
  payload,
})

export const wellReservesSaveFailure = error => ({
  type: Constants.WELL_RESERVES_SAVE_FAILURE,
  error,
})

export const saveRawWellReserves = (id) => {
  return (dispatch, getState) => {
    dispatch(wellReservesSaveRequest(id))

    const sourceDrilledReserves = createSyncDrilledReservesIdSelector(getState(), id)
    const well = createWellIdSelector(getState(), id)
    // const project = createProjectIdByNameSelector(getState(), well.project)

    return Promise.resolve().then(() => {
      if (!sourceDrilledReserves) return Promise.reject('SourceDrilledReserves not found')
      const tableData = sourceDrilledReserves.sands
      const headerData = mapReservesHeaderAttributes(sourceDrilledReserves)
      return dispatch(calculateWellReserves(id, tableData, headerData, sourceDrilledReserves))
        .then(([response]) => {
          return Api.saveWellReserves(response.id, { ...response, projectName: well.project })
        }).catch(() => {
          return Api.saveWellReserves(sourceDrilledReserves.id, { ...sourceDrilledReserves, projectName: well.project })
        })
    }).then(response => {
      dispatch(wellReservesSaveSuccess(response))
      dispatch(getWellsSORStatus([{ name: id }]))
      dispatch(getCalculateReserves(id, true))
      dispatch(getCalculateDpi(id, true))
      return response
    }).catch(error => {
      dispatch(wellReservesSaveFailure(error))
      return Promise.reject(error)
    })
  }
}

export const saveWellReserves = (id, tableData, headerData) => {
  return (dispatch, getState) => {
    dispatch(wellReservesSaveRequest(id))
    
    const well = createWellIdSelector(getState(), id)

    return validateForSave({ header: headerData, value: tableData }, { dropdownOptions: dropdownOptions(getState()) })
      .then(([response, errors]) => {

        const invalidHeader = getInvalidFieldsObject(errors, 'header')
        const invalidValue = getInvalidFieldsArray(errors, 'value')
        if (invalidHeader.length || invalidValue.length) return Promise.reject(errors)

        return dispatch(calculateWellReserves(id, tableData, headerData))
          .then(([response]) => {
            return Api.saveWellReserves(response.id, { ...response, projectName: well.project })
          }).catch(() => {
            const input = Object.assign({},
              createDrilledReservesIdSelector(getState(), id),
              headerData,
              {
                sands: tableData,
                projectName: well.project
              }
            )
            return Api.saveWellReserves(input.id, input)
          })
      }).then(response => {
        dispatch(wellReservesSaveSuccess(response))
        dispatch(getWellsSORStatus([{ name: id }]))
        dispatch(getCalculateReserves(id, true))
        dispatch(getCalculateDpi(id, true))
        return response
      }).catch(error => {
        dispatch(wellReservesSaveFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellReservesMergeRequest = id => ({
  type: Constants.WELL_RESERVES_MERGE_REQUEST,
  id,
})

export const wellReservesMergeSuccess = payload => ({
  type: Constants.WELL_RESERVES_MERGE_SUCCESS,
  payload,
})

export const wellReservesMergeFailure = error => ({
  type: Constants.WELL_RESERVES_MERGE_FAILURE,
  error,
})

export const mergeWellReserves = (wellId) => {
  return (dispatch, getState) => {
    dispatch(wellReservesMergeRequest(wellId))
    const well = createWellIdSelector(getState(), wellId)
    const drilledReserves = createRecentDrilledReservesIdSelector(getState(), wellId)
    const payload = {
      id: drilledReserves.id,
      projectName: well.project,
      platformName: well.platform,
      wellName: well.wellName,
    }
    return Api.mergeWellReserves(payload)
      .then(response => {
        const tableData = response.sands
        const headerData = mapReservesHeaderAttributes(response)
        return dispatch(calculateWellReserves(wellId, tableData, headerData, response))
          .then(([response]) => {
            return Api.saveWellReserves(response.id, response)
          }).catch(() => {
            return Api.saveWellReserves(payload.id, payload)
          })
      }).then(response => {
        dispatch(wellReservesMergeSuccess(response))
        dispatch(getWellsSORStatus([{ name: wellId }]))
        dispatch(getCalculateReserves(wellId, true))
        dispatch(getCalculateDpi(wellId, true))
        return response
      }).catch(error => {
        dispatch(wellReservesMergeFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellReservesCalculateRequest = id => ({
  type: Constants.WELL_RESERVES_CALCULATE_REQUEST,
  id,
})

export const wellReservesCalculateSuccess = payload => ({
  type: Constants.WELL_RESERVES_CALCULATE_SUCCESS,
  payload,
})

export const wellReservesCalculateFailure = error => ({
  type: Constants.WELL_RESERVES_CALCULATE_FAILURE,
  error,
})

export const calculateWellReserves = (id, tableData = [], headerData = {}, initialObject) => {
  return (dispatch, getState) => {
    dispatch(wellReservesCalculateRequest(id))

    return validateForCalculation({ value: tableData, header: headerData }, { dropdownOptions: dropdownOptions(getState()) })
      .then(([response, errors]) => {
        const invalidHeader = getInvalidFieldsObject(errors, 'header')
        if (invalidHeader.length) return Promise.reject(errors)
        const invalidValue = getInvalidFieldsArray(errors, 'value')
        if (invalidValue.length === tableData.length) return Promise.reject(errors)

        const well = createWellIdSelector(getState(), id)
        const payload = Object.assign(
          {},
          initialObject || createDrilledReservesIdSelector(getState(), id),
          headerData,
          {
            sands: tableData,
            platformName: well.platform,
            projectName: well.project,
          },
        )
        return Promise.all([Api.calculateWellReserves(payload), errors])
      }).then(([response, errors]) => {
        dispatch(wellReservesCalculateSuccess(response))
        return [response, errors]
      }).catch(error => {
        dispatch(wellReservesCalculateFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellReservesSyncRequest = id => ({
  type: Constants.WELL_RESERVES_SYNC_REQUEST,
  id,
})

export const wellReservesSyncSuccess = payload => ({
  type: Constants.WELL_RESERVES_SYNC_SUCCESS,
  payload,
})

export const wellReservesSyncFailure = (error, wellName) => ({
  type: Constants.WELL_RESERVES_SYNC_FAILURE,
  wellName,
  error,
  alert: false,
})

export const syncWellReserves = id => {
  return (dispatch, getState) => {
    dispatch(wellReservesSyncRequest(id))
    const well = createWellIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!well) return Promise.reject('Well not found')
      return Api.syncWellReserves(id, well.project, well.platform)
    }).then(response => {
      dispatch(wellReservesSyncSuccess(response))
      return response
    }).catch(error => {
      dispatch(wellReservesSyncFailure(error, id))
      return Promise.reject(error)
    })
  }
}

export const wellReservesResetRequest = id => ({
  type: Constants.WELL_RESERVES_RESET_REQUEST,
  id,
})

export const wellReservesResetSuccess = payload => ({
  type: Constants.WELL_RESERVES_RESET_SUCCESS,
  payload,
})

export const wellReservesResetFailure = error => ({
  type: Constants.WELL_RESERVES_RESET_FAILURE,
  error,
})

export const resetWellReserves = id => {
  return (dispatch, getState) => {
    dispatch(wellReservesResetRequest(id))
    const well = createWellIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!well) return Promise.reject('No sand data available from SOR.')
      return Api.syncWellReserves(id, well.project, well.platform)
    }).then(response => {
      dispatch(wellReservesResetSuccess(response))
      return response
    }).catch(error => {
      dispatch(wellReservesResetFailure(error))
      return Promise.reject(error)
    })
  }
}

export const wellReservesPublishRequest = id => ({
  type: Constants.WELL_RESERVES_PUBLISH_REQUEST,
  id,
})

export const wellReservesPublishSuccess = payload => ({
  type: Constants.WELL_RESERVES_PUBLISH_SUCCESS,
  payload,
})

export const wellReservesPublishFailure = error => ({
  type: Constants.WELL_RESERVES_PUBLISH_FAILURE,
  error,
})

export const publishWellReserves = id => {
  return (dispatch, getState) => {
    dispatch(wellReservesPublishRequest(id))
    const well = createRecentDrilledReservesIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!well) return Promise.reject('No saved sand has been found. Please edit and save your sand data once then try again.')
      return Api.publishWellReserves(well.wellSpaceId)
    }).then(response => {
      dispatch(wellReservesPublishSuccess({ ...response, wellName: well.wellName }))
      dispatch(getCalculateReserves(id, true))
      dispatch(getCalculateDpi(id, true))
      return response
    }).catch(error => {
      dispatch(wellReservesPublishFailure(error))
      return Promise.reject(error)
    })
  }
}

export const wellReservesGetRequest = id => ({
  type: Constants.WELL_RESERVES_GET_REQUEST,
  id,
})

export const wellReservesGetSuccess = payload => ({
  type: Constants.WELL_RESERVES_GET_SUCCESS,
  payload,
})

export const wellReservesGetFailure = (error, wellName) => ({
  type: Constants.WELL_RESERVES_GET_FAILURE,
  wellName,
  error,
  alert: false,
})

export const getWellReserves = id => {
  return dispatch => {
    dispatch(wellReservesGetRequest(id))
    return Api.getWellReserves(id)
      .then(response => {
        dispatch(wellReservesGetSuccess(response))
        return response
      }).catch(error => {
        dispatch(wellReservesGetFailure(error, id))
        return Promise.reject(error)
      })
  }
}

export const sandReserveOptionsGetOpenWorkCategoriesRequest = () => ({
  type: Constants.SAND_RESERVES_GET_OPENWORK_CATEGORIES_REQUEST
})

export const sandReserveOptionsGetOpenWorkCategoriesSuccess = payload => ({
  type: Constants.SAND_RESERVES_GET_OPENWORK_CATEGORIES_SUCCESS,
  payload,
})

export const sandReserveOptionsGetOpenWorkCategoriesFailure = error => ({
  type: Constants.SAND_RESERVES_GET_OPENWORK_CATEGORIES_FAILURE,
  error,
})

export const getOpenWorkCategories = () => {
  return dispatch => {
    dispatch(sandReserveOptionsGetOpenWorkCategoriesRequest())
    return Api.getOpenWorkCategories()
      .then(response => {
        dispatch(sandReserveOptionsGetOpenWorkCategoriesSuccess(response))
        return response
      }).catch(error => {
        dispatch(sandReserveOptionsGetOpenWorkCategoriesFailure(error))
        return Promise.reject(error)
      })
  }
}

export const sandReserveOptionsGetDepletionsRequest = () => ({
  type: Constants.SAND_RESERVES_GET_DEPLETIONS_REQUEST
})

export const sandReserveOptionsGetDepletionsSuccess = payload => ({
  type: Constants.SAND_RESERVES_GET_DEPLETIONS_SUCCESS,
  payload,
})

export const sandReserveOptionsGetDepletionsFailure = error => ({
  type: Constants.SAND_RESERVES_GET_DEPLETIONS_FAILURE,
  error,
})

export const getDepletions = () => {
  return dispatch => {
    dispatch(sandReserveOptionsGetDepletionsRequest())
    return Api.getDepletions()
      .then(response => {
        dispatch(sandReserveOptionsGetDepletionsSuccess(response))
        return response
      }).catch(error => {
        dispatch(sandReserveOptionsGetDepletionsFailure(error))
        return Promise.reject(error)
      })
  }
}

export const sandReserveOptionsGetBoProfilesRequest = () => ({
  type: Constants.SAND_RESERVES_GET_BO_PROFILES_REQUEST
})

export const sandReserveOptionsGetBoProfilesSuccess = payload => ({
  type: Constants.SAND_RESERVES_GET_BO_PROFILES_SUCCESS,
  payload,
})

export const sandReserveOptionsGetBoProfilesFailure = error => ({
  type: Constants.SAND_RESERVES_GET_BO_PROFILES_FAILURE,
  error,
})

export const getBoProfiles = () => {
  return dispatch => {
    dispatch(sandReserveOptionsGetBoProfilesRequest())
    return Api.getBoProfiles()
      .then(response => {
        dispatch(sandReserveOptionsGetBoProfilesSuccess(response))
        return response
      }).catch(error => {
        dispatch(sandReserveOptionsGetBoProfilesFailure(error))
        return Promise.reject(error)
      })
  }
}

export const sandReserveOptionsGetBgProfilesRequest = () => ({
  type: Constants.SAND_RESERVES_GET_BG_PROFILES_REQUEST
})

export const sandReserveOptionsGetBgProfilesSuccess = payload => ({
  type: Constants.SAND_RESERVES_GET_BG_PROFILES_SUCCESS,
  payload,
})

export const sandReserveOptionsGetBgProfilesFailure = error => ({
  type: Constants.SAND_RESERVES_GET_BG_PROFILES_FAILURE,
  error,
})

export const getBgProfiles = () => {
  return dispatch => {
    dispatch(sandReserveOptionsGetBgProfilesRequest())
    return Api.getBgProfiles()
      .then(response => {
        dispatch(sandReserveOptionsGetBgProfilesSuccess(response))
        return response
      }).catch(error => {
        dispatch(sandReserveOptionsGetBgProfilesFailure(error))
        return Promise.reject(error)
      })
  }
}

export const sandReserveOptionsGetAreaNamesRequest = () => ({
  type: Constants.SAND_RESERVES_GET_AREA_NAMES_REQUEST
})

export const sandReserveOptionsGetAreaNamesSuccess = payload => ({
  type: Constants.SAND_RESERVES_GET_AREA_NAMES_SUCCESS,
  payload,
})

export const sandReserveOptionsGetAreaNamesFailure = error => ({
  type: Constants.SAND_RESERVES_GET_AREA_NAMES_FAILURE,
  error,
})

export const getAreaNames = () => {
  return dispatch => {
    dispatch(sandReserveOptionsGetAreaNamesRequest())
    return Api.getAreaNames()
      .then(response => {
        dispatch(sandReserveOptionsGetAreaNamesSuccess(response))
        return response
      }).catch(error => {
        dispatch(sandReserveOptionsGetAreaNamesFailure(error))
        return Promise.reject(error)
      })
  }
}

export const sandReserveOptionsGetAreaDiscountFactorsRequest = () => {
  return {
    type: Constants.SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_REQUEST
  }
}

export const sandReserveOptionsGetAreaDiscountFactorsSuccess = payload => ({
  type: Constants.SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_SUCCESS,
  payload,
})

export const sandReserveOptionsGetAreaDiscountFactorsFailure = error => ({
  type: Constants.SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_FAILURE,
  error,
})

export const getAreaDiscountFactors = () => {
  return dispatch => {
    dispatch(sandReserveOptionsGetAreaDiscountFactorsRequest())
    return Api.getAreaDiscountFactors()
      .then(response => {
        dispatch(sandReserveOptionsGetAreaDiscountFactorsSuccess(response))
        return response
      }).catch(error => {
        dispatch(sandReserveOptionsGetAreaDiscountFactorsFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellDefaultAnalogyGetRequest = id => ({
  type: Constants.WELL_DEFAULT_ANALOGY_GET_REQUEST,
  id,
})

export const wellDefaultAnalogyGetSuccess = payload => ({
  type: Constants.WELL_DEFAULT_ANALOGY_GET_SUCCESS,
  payload,
})

export const wellDefaultAnalogyGetFailure = error => ({
  type: Constants.WELL_DEFAULT_ANALOGY_GET_FAILURE,
  error,
})

export const getWellDefaultAnalogy = id => {
  return dispatch => {
    dispatch(wellDefaultAnalogyGetRequest(id))
    return Api.getWellDefaultAnalogy(id)
      .then(response => {
        return dispatch(wellDefaultAnalogyGetSuccess(response))
      }).catch(error => {
        dispatch(wellDefaultAnalogyGetFailure(error))
        return Promise.reject(error)
      })
  }
}


/* Currently use the same value to Project */
export const getWellDollarBoERequest = projectName => ({
  type: Constants.GET_WELL_DOLLAR_BOE_REQUEST,
  projectName,
})

export const getWellDollarBoESuccess = payload => ({
  type: Constants.GET_WELL_DOLLAR_BOE_SUCCESS,
  payload,
})

export const getWellDollarBoEFailure = error => ({
  type: Constants.GET_WELL_DOLLAR_BOE_FAILURE,
  error,
})

export const getWellDollarBoE = (wellId, projectName) => {
  return (dispatch, getState) => {
    dispatch(getWellDollarBoERequest(projectName))
    const well = createWellIdSelector(getState(), wellId)
    return Promise.resolve().then(() => {
      if(!well) return Promise.reject("Well not found")
      return getDollarBoE(projectName)
    }).then(response => {
      dispatch(getWellDollarBoESuccess({... response, wellName: wellId}))
      return response
    }).catch(error => {
      dispatch(getWellDollarBoEFailure(error))
      return Promise.reject(error)
    })
  }
}
      
