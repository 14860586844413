
export const getPermissions = payload => {

  if (!payload) return []
  if (!payload.projects) return []
  if (!payload.pages) return []

  const result = payload.projects.reduce((prev, item) => {
    return [
      ...prev,
      `${item.name}.${item.permission}`,
      `${item.rllcpReferenceId}.${item.permission}`,
    ]
  }, [])

  if (payload.pages.some(page => page.url.includes('admin'))) {
    result.push('admin')
  }
  
  return result
}
