import * as Constants from './constants'
import * as Api from '../apis'

export const wellGetRequest = id => ({
  type: Constants.WELL_GET_REQUEST,
  id,
})

export const wellGetSuccess = payload => ({
  type: Constants.WELL_GET_SUCCESS,
  payload,
})

export const wellGetFailure = error => ({
  type: Constants.WELL_GET_FAILURE,
  error,
})

export const getWell = (id, ignoreCache) => {
  return dispatch => {
    dispatch(wellGetRequest(id))
    return Api.getWell(id, ignoreCache)
      .then(payload => {
        dispatch(wellGetSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(wellGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellPlannedGetRequest = id => ({
  type: Constants.WELL_PLANNED_LIQUID_RATIO_GET_REQUEST,
  id,
})

export const wellPlannedGetSuccess = payload => ({
  type: Constants.WELL_PLANNED_LIQUID_RATIO_GET_SUCCESS,
  payload,
})

export const wellPlannedGetFailure = error => ({
  type: Constants.WELL_PLANNED_LIQUID_RATIO_GET_FAILURE,
  error,
})

export const getWellPlanned = (id, ignoreCache) => {
  return dispatch => {
    dispatch(wellPlannedGetRequest(id))
    return Api.getWellPlanned(id, ignoreCache)
      .then(payload => {
        dispatch(wellPlannedGetSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(wellPlannedGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellsGetRequest = id => ({
  type: Constants.WELLS_GET_REQUEST,
  id,
})

export const wellsGetSuccess = payload => ({
  type: Constants.WELLS_GET_SUCCESS,
  payload,
})

export const wellsGetFailure = error => ({
  type: Constants.WELLS_GET_FAILURE,
  error,
})

export const getWells = (id, withStatus) => {
  return dispatch => {
    dispatch(wellsGetRequest(id))
    return Api.getWells(id, withStatus)
      .then(payload => {
        dispatch(wellsGetSuccess(payload))
        if (withStatus) {
          dispatch(getWellsSORStatus(payload.map(well => ({ name: well.name }))))
        }
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(wellsGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellGetStatusRequest = id => ({
  type: Constants.WELL_GET_STATUS_REQUEST,
  id,
})

export const wellGetStatusSuccess = payload => ({
  type: Constants.WELL_GET_STATUS_SUCCESS,
  payload,
})

export const wellGetStatusFailure = error => ({
  type: Constants.WELL_GET_STATUS_FAILURE,
  error,
})

export const getWellStatus = (id, ignoreCache) => {
  return dispatch => {
    dispatch(wellGetStatusRequest(id))
    return Api.getWellStatus(id, ignoreCache)
      .then(payload => {
        dispatch(wellGetStatusSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(wellGetStatusFailure(error))
        return Promise.reject(error)
      })
  }
}

export const wellsGetSORStatusRequest = () => ({
  type: Constants.WELLS_GET_SOR_STATUS_REQUEST,
})

export const wellsGetSORStatusSuccess = (payload) => ({
  type: Constants.WELLS_GET_SOR_STATUS_SUCCESS,
  payload,
})

export const wellsGetSORStatusFailure = (error) => ({
  type: Constants.WELLS_GET_SOR_STATUS_FAILURE,
  error,
})

export const getWellsSORStatus = (ids) => {
  if (!Array.isArray(ids) || !ids.length) return
  return dispatch => {
    dispatch(wellsGetSORStatusRequest())
    return Api.getWellsSORStatus(ids)
      .then(payload => {
        dispatch(wellsGetSORStatusSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(wellsGetSORStatusFailure(error))
        return Promise.reject(error)
      })
  }
}