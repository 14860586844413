import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'
import { getPermissions } from '../helpers/permission'

const initialState = {
    access: null,
    user: null,
    token: null,
    expiry: null,
    permissions: [],
    requestUrl: ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Constant.GET_AZURE_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                expiry: action.payload.expiry,
                access: 'PASSED'
            }
        case Constant.USERS_AUTHENS_GET_SUCCESS:
            return {
                ...state,
                user: action.payload
            }
        case Constant.USER_AUTHORIZE_GET_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    base64EncryptionKey: action.payload.base64EncryptionKey,
                    authen: action.payload.authen,
                    pages: action.payload.pages,
                    roleAuthorizes: action.payload.roleAuthorizes,
                    modules:action.payload.modules
                },
                permissions: getPermissions(action.payload)
            }
        case Constant.USER_SET_REDIRECT:
            return {
                ...state,
                requestUrl: action.payload.requestUrl
            }
        case Constant.GET_AZURE_TOKEN_FAILURE:
        case Constant.USERS_AUTHENS_GET_FAILURE:
            return {
                access: 'RESTRICTED'
            }
 
        default:
            return state
    }
}