import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Stack } from 'office-ui-fabric-react/lib/Stack'
import { Icon } from 'office-ui-fabric-react/lib/Icon'
import Hallmark from '../../assets/hallmark.png'
import style from './style.module.css'

export default memo(() => {
    return (
        <Stack className={style.nav} horizontal horizontalAlign="space-between" verticalAlign="center">
            <Stack.Item>
                <div className={style.menu}>
                    <Icon iconName="GlobalNavButton" />
                    <span>menu</span>
                </div>
            </Stack.Item>
            <Stack alignItems="center" horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                <img src={Hallmark} alt="chevron" height="46" width="42" />
                <Stack.Item>
                    <header><Link to="/">surface live</Link></header>
                </Stack.Item>
            </Stack>
            <Stack.Item>
                <div className={style.menu}>
                    <Icon iconName="Zoom" />
                    <span>search</span>
                </div>
            </Stack.Item>
        </Stack>
    )
})