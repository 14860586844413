import * as Constants from './constants'

export const openGlobalAlert = message => ({
  type: Constants.GLOBAL_ALERT_OPEN,
  message
})

export const closeGlobalAlert = () => ({
  type: Constants.GLOBAL_ALERT_CLOSE,
})

export const setAppReady = isAppReady => ({
  type: Constants.APP_READY,
  isAppReady,
})

export const setGlobalLoader = isGlobalLoading => ({
  type: Constants.GLOBAL_LOADER,
  isGlobalLoading,
})

export const appDestroy = () => ({
  type: Constants.APP_DESTROY,
})
