import React, { useMemo } from 'react'
import { loadTheme, Customizer } from 'office-ui-fabric-react'
import { useSelector } from 'react-redux'
import useLocalStorage from '../../hooks/useLocalStorage'
import GlobalAlert from '../../components/GlobalAlert'
import CommandBar from '../../components/CommandBar'
import ChevronNav from '../../components/ChevronNav'
import Welcome from '../../components/Welcome'
import LandingPage from '../../components/LandingPage'
import Footer from '../../components/Footer'
// import Breadcrumb from '../../components/Breadcrumb'
import style from './style.module.css'

loadTheme({
  defaultFontStyle: { fontFamily: `"GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif`, fontWeight: 'regular' },
  fonts: {
    small: {
      fontSize: '11px',
      fontFamily: 'GothamNarrowBook',
    },
    medium: {
      fontSize: '13px',
    },
    large: {
      fontSize: '20px',
      fontWeight: 'semibold'
    },
    xLarge: {
      fontSize: '22px',
      fontWeight: 'semibold'
    }
  },
  palette: {
    themePrimary: '#0b2d71',
    themeLighterAlt: '#f0f3f9',
    themeLighter: '#c7d2e8',
    themeLight: '#9baed4',
    themeTertiary: '#4e6ca9',
    themeSecondary: '#1b3d81',
    themeDarkAlt: '#0a2865',
    themeDark: '#092255',
    themeDarker: '#06193f',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#595959',
    neutralSecondary: '#373737',
    neutralPrimaryAlt: '#2f2f2f',
    neutralPrimary: '#000000',
    neutralDark: '#151515',
    black: '#0b0b0b',
    white: '#ffffff',
  }
})

const scopedSettings = settings => {
  return {
    CommandBar: {
      styles: {
        root: {
          backgroundColor: '#0b2d71',
        }
      }
    },
    CommandBarButton: {
      styles: {
        icon: {
          color: '#fff',
        },
        menuIcon: {
          color: '#fff',
        },
        root: {
          backgroundColor: '#0b2d71',
          color: '#fff',
        },
        rootHovered: {
          backgroundColor: '#0066b2',
          color: '#fff',
        },
        rootPressed: {
          backgroundColor: '#0066b2',
          color: '#fff',
        },
        rootExpanded: {
          backgroundColor: '#0066b2',
          color: '#fff',
        }
      }
    }
  }
}

export default ({ children }) => {
  const [fullMode, setFullMode] = useLocalStorage('fullModeEnabled', false)
  //const { user } = useSelector(state => state.session)
  const { user } = useSelector(state => state.authenSession)
  const { isAppReady } = useSelector(state => state.views)
  const _children = useMemo(() => children, [children])


  return (
    <Customizer scopedSettings={scopedSettings}>
      <LandingPage visible={!isAppReady} />
      <div className={`${!fullMode && style.container} ${!isAppReady ? style.blur : ''}`}>
        <GlobalAlert />
        {
          !fullMode && <ChevronNav />
        }
        <CommandBar />
        {/* <Breadcrumb /> */}
        <section className={style.content}>
          {
            isAppReady && user && (
              <>
                <Welcome user={user} fullMode={fullMode} setFullMode={setFullMode}/>
                {_children}
              </>
            )
          }
        </section>
        {
          !fullMode && <Footer />
        }
      </div>
    </Customizer>
  )
}

