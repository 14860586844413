import * as Constants from './constants'
import * as Api from '../apis'
import { getProjects } from './projects'

export const usersGetRequest = () => ({
  type: Constants.USERS_GET_REQUEST,
})

export const usersGetSuccess = payload => ({
  type: Constants.USERS_GET_SUCCESS,
  payload,
})

export const usersGetFailure = error => ({
  type: Constants.USERS_GET_FAILURE,
  error,
})

export const getUsers = () => {
  return dispatch => {
    dispatch(usersGetRequest())
    return Api.getUsers()
      .then(payload => {
        dispatch(usersGetSuccess(payload))
      }).catch(error => {
        dispatch(usersGetFailure(error))
      })
  }
}

export const userPutRequest = () => ({
  type: Constants.USER_PUT_REQUEST,
})

export const userPutSuccess = payload => ({
  type: Constants.USER_PUT_SUCCESS,
  payload,
})

export const userPutFailure = error => ({
  type: Constants.USER_PUT_FAILURE,
  error,
})

export const putUser = () => {
  return dispatch => {
    dispatch(userPutRequest())
    return Api.getUsers()
      .then(payload => {
        dispatch(userPutSuccess(payload))
      }).catch(error => {
        dispatch(userPutFailure(error))
      })
  }
}

export const userPostRequest = () => ({
  type: Constants.USER_POST_REQUEST,
})

export const userPostSuccess = payload => ({
  type: Constants.USER_POST_SUCCESS,
  payload,
})

export const userPostFailure = error => ({
  type: Constants.USER_POST_FAILURE,
  error,
})

export const postUser = payload => {
  return dispatch => {
    dispatch(userPostRequest())
    return Api.postUser(payload)
      .then(payload => {
        dispatch(userPostSuccess(payload))
      }).catch(error => {
        dispatch(userPostFailure(error))
      })
  }
}

export const usersSyncGetRequest = () => ({
  type: Constants.USERS_SYNC_GET_REQUEST,
})

export const usersSyncGetSuccess = payload => ({
  type: Constants.USERS_SYNC_GET_SUCCESS,
  payload,
})

export const usersSyncGetFailure = error => ({
  type: Constants.USERS_SYNC_GET_FAILURE,
  error,
})

export const syncUsers = () => {
  return dispatch => {
    dispatch(usersSyncGetRequest())
    return Api.syncUsers()
      .then(payload => {
        dispatch(usersSyncGetSuccess(payload))
        return payload
      }).catch(error => {
        dispatch(usersSyncGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const usersRemoveRequest = () => ({
  type: Constants.USERS_REMOVE_REQUEST,
})

export const usersRemoveSuccess = payload => ({
  type: Constants.USERS_REMOVE_SUCCESS,
  payload,
})

export const usersRemoveFailure = error => ({
  type: Constants.USERS_REMOVE_FAILURE,
  error,
})

export const removeUsers = (users) => {
  return dispatch => {
    dispatch(usersRemoveRequest())
    return Api.removeUsers(users)
      .then(payload => {
        dispatch(usersRemoveSuccess(payload))
        dispatch(getUsers())
        return payload
      }).catch(error => {
        dispatch(usersRemoveFailure(error))
        return Promise.reject(error)
      })
  }
}

export const usersAssignProjectRequest = () => ({
  type: Constants.USERS_ASSIGN_PROJECT_REQUEST,
})

export const usersAssignProjectSuccess = payload => ({
  type: Constants.USERS_ASSIGN_PROJECT_SUCCESS,
  payload,
})

export const usersAssignProjectFailure = error => ({
  type: Constants.USERS_ASSIGN_PROJECT_FAILURE,
  error,
})

export const usersAssignProject = () => {
  return dispatch => {
    dispatch(usersAssignProjectRequest())
    return Api.getUsers()
      .then(payload => {
        dispatch(usersAssignProjectSuccess(payload))
      }).catch(error => {
        dispatch(usersAssignProjectFailure(error))
      })
  }
}

export const usersProjectGetRequest = () => ({
  type: Constants.USERS_PROJECT_GET_REQUEST,
})

export const usersProjectGetSuccess = payload => ({
  type: Constants.USERS_PROJECT_GET_SUCCESS,
  payload,
})

export const usersProjectGetFailure = error => ({
  type: Constants.USERS_PROJECT_GET_FAILURE,
  error,
})

export const usersProjectGet = (id) => {
  return dispatch => {
    dispatch(usersProjectGetRequest())
    return Api.getUsers()
      .then(payload => {
        dispatch(usersProjectGetSuccess(payload))
      }).catch(error => {
        dispatch(usersProjectGetFailure(error))
      })
  }
}

export const rolesConfigSaveRequest = () => ({
  type: Constants.ROLE_CONFIG_SAVE_REQUEST,
})

export const rolesConfigSaveSuccess = payload => ({
  type: Constants.ROLE_CONFIG_SAVE_SUCCESS,
  payload,
})

export const rolesConfigSaveFailure = error => ({
  type: Constants.ROLE_CONFIG_SAVE_FAILURE,
  error,
})

export const rolesConfigSave = (id) => {
  return dispatch => {
    dispatch(rolesConfigSaveRequest())
    return Api.getUsers()
      .then(payload => {
        dispatch(rolesConfigSaveSuccess(payload))
      }).catch(error => {
        dispatch(rolesConfigSaveFailure(error))
      })
  }
}

export const authensGetRequest = () => ({
  type: Constants.USERS_AUTHENS_GET_REQUEST,
})

export const authensGetSuccess = payload => ({
  type: Constants.USERS_AUTHENS_GET_SUCCESS,
  payload,
})

export const authensGetFailure = error => ({
  type: Constants.USERS_AUTHENS_GET_FAILURE,
  error,
})

export const getAuthens = () => {
  return dispatch => {
    dispatch(authensGetRequest())
    return Api.getAuthens()
      .then(payload => {
        dispatch(authensGetSuccess(payload))
        return payload
      }).catch(error => {
        dispatch(authensGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const authorizeRoleRequest = () => ({
    type: Constants.USER_AUTHORIZE_GET_REQUEST,
})

export const authorizeRoleSuccess = payload => ({
    type: Constants.USER_AUTHORIZE_GET_SUCCESS,
    payload,
})


export const getAuthenticate = () => (dispatch) => {
    dispatch(authensGetRequest())
        return Api.authenticate()
            .then(payload => {
                dispatch(authensGetSuccess(payload))
                
                if (payload.cai === undefined && payload.cai === null) {
                    throw new Error("Authentication failed as no accepted object from backend")                  
                }
                dispatch(authorizeRoleRequest())
                return Api.authorizeRole()
            })
            .then(payload => {
                dispatch(authorizeRoleSuccess(payload))
                return payload
            })
            .catch(error => {
                dispatch(authensGetFailure(error))
                return Promise.reject(error)
            })
}

export const authenPostRequest = () => ({
  type: Constants.USER_AUTHEN_POST_REQUEST,
})

export const authenPostSuccess = payload => ({
  type: Constants.USER_AUTHEN_POST_SUCCESS,
  payload,
})

export const authenPostFailure = error => ({
  type: Constants.USER_AUTHEN_POST_FAILURE,
  error,
})

export const postAuthen = authen => {
  return dispatch => {
    dispatch(authenPostRequest())
    return Api.postAuthen(authen)
      .then(payload => {
        dispatch(authenPostSuccess(payload))
        dispatch(getUsers())
        return payload
      }).catch(error => {
        dispatch(authenPostFailure(error))
        return Promise.reject(error)
      })
  }
}

export const userRegisterRequest = () => ({
  type: Constants.USERS_REGISTER_REQUEST,
})

export const userRegisterSuccess = payload => ({
  type: Constants.USERS_REGISTER_SUCCESS,
  payload,
})

export const userRegisterFailure = error => ({
  type: Constants.USERS_REGISTER_FAILURE,
  error,
})

export const registerUsers = (users) => {
  return dispatch => {
    dispatch(userRegisterRequest())
    return Api.registerUsers(users)
      .then(payload => {
        dispatch(userRegisterSuccess(payload))
        dispatch(getUsers())
        return payload
      }).catch(error => {
        dispatch(userRegisterFailure(error))
        return Promise.reject(error)
      })
  }
}

export const rolesAuthorizeGetRequest = () => ({
  type: Constants.ROLES_AUTHORIZE_GET_REQUEST,
})

export const rolesAuthorizeGetSuccess = payload => ({
  type: Constants.ROLES_AUTHORIZE_GET_SUCCESS,
  payload,
})

export const rolesAuthorizeGetFailure = error => ({
  type: Constants.ROLES_AUTHORIZE_GET_FAILURE,
  error,
})

export const getRolesAuthorize = locationId => {
  return dispatch => {
    dispatch(rolesAuthorizeGetRequest())
    return Api.getRolesAuthorize(locationId)
      .then(payload => {
        dispatch(rolesAuthorizeGetSuccess(payload))
        return payload
      }).catch(error => {
        dispatch(rolesAuthorizeGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const rolesGetRequest = () => ({
  type: Constants.ROLES_GET_REQUEST,
})

export const rolesGetSuccess = payload => ({
  type: Constants.ROLES_GET_SUCCESS,
  payload,
})

export const rolesGetFailure = error => ({
  type: Constants.ROLES_GET_FAILURE,
  error,
})

export const getRoles = () => {
  return dispatch => {
    dispatch(rolesGetRequest())
    return Api.getRoles()
      .then(payload => {
        dispatch(rolesGetSuccess(payload))
      }).catch(error => {
        dispatch(rolesGetFailure(error))
      })
  }
}

export const locationsGetRequest = () => ({
  type: Constants.LOCATIONS_GET_REQUEST,
})

export const locationsGetSuccess = payload => ({
  type: Constants.LOCATIONS_GET_SUCCESS,
  payload,
})

export const locationsGetFailure = error => ({
  type: Constants.LOCATIONS_GET_FAILURE,
  error,
})

export const getLocations = () => {
  return dispatch => {
    dispatch(locationsGetRequest())
    return Api.getLocations()
      .then(payload => {
        dispatch(locationsGetSuccess(payload))
      }).catch(error => {
        dispatch(locationsGetFailure(error))
      })
  }
}

export const roleAuthorizePostRequest = () => ({
  type: Constants.ROLE_AUTHORIZE_POST_REQUEST,
})

export const roleAuthorizePostSuccess = payload => ({
  type: Constants.ROLE_AUTHORIZE_POST_SUCCESS,
  payload,
})

export const roleAuthorizePostFailure = error => ({
  type: Constants.ROLE_AUTHORIZE_POST_FAILURE,
  error,
})

export const postRoleAuthorize = (roles) => {
  return dispatch => {
    dispatch(roleAuthorizePostRequest())
    return Api.postRoleAuthorize(roles)
      .then(payload => {
        dispatch(roleAuthorizePostSuccess(payload))
        dispatch(getUsers())
        return payload
      }).catch(error => {
        dispatch(roleAuthorizePostFailure(error))
        return Promise.reject(error)
      })
  }
}

export const projectLocationPostRequest = () => ({
  type: Constants.PROJECT_LOCATION_POST_REQUEST,
})

export const projectLocationPostSuccess = payload => ({
  type: Constants.PROJECT_LOCATION_POST_SUCCESS,
  payload,
})

export const projectLocationPostFailure = error => ({
  type: Constants.PROJECT_LOCATION_POST_FAILURE,
  error,
})

export const postProjectLocation = (roles) => {
  return dispatch => {
    dispatch(projectLocationPostRequest())
    return Api.postProjectLocation(roles)
      .then(payload => {
        dispatch(projectLocationPostSuccess(payload))
        dispatch(getProjects())
        return payload
      }).catch(error => {
        dispatch(projectLocationPostFailure(error))
        return Promise.reject(error)
      })
  }
}

export const projectAuthorizePostRequest = () => ({
  type: Constants.PROJECT_AUTHORIZE_POST_REQUEST,
})

export const projectAuthorizePostSuccess = payload => ({
  type: Constants.PROJECT_AUTHORIZE_POST_SUCCESS,
  payload,
})

export const projectAuthorizePostFailure = error => ({
  type: Constants.PROJECT_AUTHORIZE_POST_FAILURE,
  error,
})

export const postProjectAuthorize = (roles) => {
  return dispatch => {
    dispatch(projectAuthorizePostRequest())
    return Api.postProjectAuthorize(roles)
      .then(payload => {
        dispatch(projectAuthorizePostSuccess(payload))
        dispatch(getProjects())
        return payload
      }).catch(error => {
        dispatch(projectAuthorizePostFailure(error))
        return Promise.reject(error)
      })
  }
}

export const projectAuthorizeGetRequest = () => ({
  type: Constants.PROJECT_AUTHORIZE_GET_REQUEST,
})

export const projectAuthorizeGetSuccess = payload => ({
  type: Constants.PROJECT_AUTHORIZE_GET_SUCCESS,
  payload,
})

export const projectAuthorizeGetFailure = error => ({
  type: Constants.PROJECT_AUTHORIZE_GET_FAILURE,
  error,
})

export const getProjectAuthorize = () => {
  return dispatch => {
    dispatch(projectAuthorizeGetRequest())
    return Api.getProjectAuthorize()
      .then(payload => {
        dispatch(projectAuthorizeGetSuccess(payload))
        return payload
      }).catch(error => {
        dispatch(projectAuthorizeGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const projectLocationGetRequest = () => ({
  type: Constants.PROJECT_LOCATIONS_GET_REQUEST,
})

export const projectLocationGetSuccess = payload => ({
  type: Constants.PROJECT_LOCATIONS_GET_SUCCESS,
  payload,
})

export const projectLocationGetFailure = error => ({
  type: Constants.PROJECT_LOCATIONS_GET_FAILURE,
  error,
})

export const getProjectLocations = () => {
  return dispatch => {
    dispatch(projectLocationGetRequest())
    return Api.getProjectLocations()
      .then(payload => {
        dispatch(projectLocationGetSuccess(payload))
        return payload
      }).catch(error => {
        dispatch(projectLocationGetFailure(error))
        return Promise.reject(error)
      })
  }
}


export const setAzureAccessToken = payload => ({
    type: Constants.GET_AZURE_TOKEN_SUCCESS,
    payload
});

export const setRestrictedAccess = () => ({
    type: Constants.USERS_AUTHENS_GET_FAILURE
});

export const getAzureTokenAccessSuccess = payload => ({
    type: Constants.GET_AZURE_TOKEN_SUCCESS,
    payload
})

export const getAzureTokenAccessFailure = error => ({
    type: Constants.GET_AZURE_TOKEN_FAILURE,
    error,
})

export const setRedirectUrl = url => dispatch => {
    dispatch({
        type: Constants.USER_SET_REDIRECT,
        payload: {requestUrl: url}
    })
}

