import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'

export const allIds = (state = [], action) => {
  switch (action.type) {
    case Constant.USERS_GET_SUCCESS: {
      const result = [...state]
      action.payload.forEach(user => {
        if (!result.includes(user.id)) {
          result.push(user.id)
        }
      })
      return result
    }
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case Constant.USERS_GET_SUCCESS: {
      const result = {...state}
      action.payload.forEach(user => {
        result[user.id] = {
          ...result[user.id],
          ...user,
        }
      })
      return result
    }
    default:
      return state
  }
}

export const syncById = (state = {}, action) => {
  switch (action.type) {
    case Constant.USERS_SYNC_GET_SUCCESS: {
      const result = {...state}
      action.payload.forEach(user => {
        result[user.adId] = {
          ...result[user.adId],
          ...user,
        }
      })
      return result
    }
    default:
      return state
  }
}

export const roles = (state = [], action) => {
  switch (action.type) {
    case Constant.ROLES_AUTHORIZE_GET_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export const projects = (state = [], action) => {
  switch (action.type) {
    case Constant.PROJECT_AUTHORIZE_GET_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export const locations = (state = [], action) => {
  switch (action.type) {
    case Constant.PROJECT_LOCATIONS_GET_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export const authens = (state = [], action) => {
  switch (action.type) {
    case Constant.USERS_AUTHENS_GET_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
  syncById,
  roles,
  projects,
  locations,
  authens,
})

export const createAllUsersSelector = state => {
  return state.users.allIds.map(id => state.users.byId[id])
}

export const createAllSyncUsersSelector = state => {
  return Object.values(state.users.syncById)
}

export const createRolesByUserSelector = (state) => (id) => {
  return state.users.roles.filter(role => role.userId === id)
}

export const createAuthenByUserSelector = (state) => (id) => {
  return state.users.authens.filter(authen => authen.userId === id)
}

export const createProjectByNameSelector = (state) => (name) => {
  return state.users.projects.filter(project => project.projectName === name)
}

export const createLocationByNameSelector = (state) => (name) => {
  return state.users.locations.filter(location => location.projectName === name)
}

