import * as Constant from '../../actions/constants'
import { combineReducers } from 'redux'

export const timeCostList = (state = [], action) => {
  switch (action.type) {
    case Constant.ADMIN_TIME_COST_LIST_GET_SUCCESS:
      return action.payload.map(item => item.id)
    default:
      return state
  }
}

export const timeCostById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_TIME_COST_LIST_GET_SUCCESS:
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state)
    // case Constant.ADMIN_TIME_COST_POST_SUCCESS:
    case Constant.ADMIN_TIME_COST_PUT_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    default:
      return state
  }
}

export const costStructureList = (state = [], action) => {
  switch (action.type) {
    case Constant.ADMIN_COST_STRUCTURE_LIST_GET_SUCCESS:
      return action.payload.map(item => item.id)
    default:
      return state
  }
}

export const costStructureById = (state = [], action) => {
  switch (action.type) {
    case Constant.ADMIN_COST_STRUCTURE_LIST_GET_SUCCESS:
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state)
    default:
      return state
  }
}

export const costStructureDetailsById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_COST_DETAILS_LIST_GET_SUCCESS:
      {
        let _state = {}
        action.payload.forEach(item => {
          _state[item.resourceId] = _state[item.resourceId] ? [..._state[item.resourceId], { ...item }] : [{ ...item }]
        })
        return _state
      }
    case Constant.ADMIN_COST_DETAILS_GET_SUCCESS:
      {
        let _state = { ...state }
        _state[action.id] = action.payload
        return _state
      }
    case Constant.ADMIN_COST_DETAILS_POST_SUCCESS:
      return {
        ...state,
        [action.payload.resourceId]: state[action.payload.resourceId] ? [...state[action.payload.resourceId], { ...action.payload }] : [{ ...action.payload }]
      }
    case Constant.ADMIN_COST_DETAILS_DELETE_SUCCESS:
      const items = state[action.payload.resourceId] ? state[action.payload.resourceId].filter(item => item.id !== action.payload.id) : []
      return {
        ...state,
        [action.payload.resourceId]: items,
      }
    default:
      return state
  }
}

export const drillTripRateDetailsById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_DRILL_TRIP_RATE_DETAILS_GET_SUCCESS:
      return {
        ...state,
        [action.id]: { ...action.payload },
      }
    default:
      return state
  }
}

export const predefinedScenarioList = (state = [], action) => {
  switch (action.type) {
    case Constant.ADMIN_PREDEFINED_SCENARIO_LIST_GET_SUCCESS:
      return action.payload.map(item => item.id)
    default:
      return state
  }
}

export const predefinedScenarioById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_PREDEFINED_SCENARIO_LIST_GET_SUCCESS:
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state)
    default:
      return state
  }
}

export const tangibleList = (state = [], action) => {
  switch (action.type) {
    case Constant.ADMIN_TANGIBLE_LIST_GET_SUCCESS:
      return action.payload.map(item => item.id)
    default:
      return state
  }
}

export const tangibleById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_TANGIBLE_LIST_GET_SUCCESS:
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state)
    // case Constant.ADMIN_TIME_COST_POST_SUCCESS:
    case Constant.ADMIN_TANGIBLE_PUT_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    default:
      return state
  }
}

export const opexList = (state = [], action) => {
  switch (action.type) {
    case Constant.ADMIN_OPEX_LIST_GET_SUCCESS:
      return action.payload.map(item => item.id)
    default:
      return state
  }
}

export const opexById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_OPEX_LIST_GET_SUCCESS:
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, state)
    // case Constant.ADMIN_OPEX_POST_SUCCESS:
    case Constant.ADMIN_OPEX_PUT_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    default:
      return state
  }
}

export default combineReducers({
  tangibleList,
  tangibleById,
  opexList,
  opexById,
  timeCostList,
  timeCostById,
  costStructureList,
  costStructureById,
  costStructureDetailsById,
  drillTripRateDetailsById,
  predefinedScenarioList,
  predefinedScenarioById,
})

export const getTimeCostList = (state) => {
  return state.admin.scenarioSetup.timeCostList
    .map(id => state.admin.scenarioSetup.timeCostById[id])
}

export const getActiveTimeCostListByType = (state) => (type) => {
  return getTimeCostList(state).filter(item => item.status === 'ACTIVE' && item.type === type)
}

export const getCostStructureList = (state) => {
  return state.admin.scenarioSetup.costStructureList
    .map(id => state.admin.scenarioSetup.costStructureById[id])
}

export const getCostDetailsById = (state) => (id) => {
  return Object.assign([], state.admin.scenarioSetup.costStructureDetailsById[id])
}

export const getPredefinedScenarioList = (state) => {
  return state.admin.scenarioSetup.predefinedScenarioList
    .map(id => state.admin.scenarioSetup.predefinedScenarioById[id])
}

export const getPredefinedScenarioById = (state) => (id) => {
  return Object.assign([], state.admin.scenarioSetup.predefinedScenarioById[id])
}

export const getTangibleList = (state) => {
  return state.admin.scenarioSetup.tangibleList.map(id => state.admin.scenarioSetup.tangibleById[id]).filter(t => t.status.toLowerCase() === 'active')
}

export const getTangibleListById = (state) => (id) => {
  return Object.assign([], state.admin.scenarioSetup.tangibleById[id])
}

export const getOpexList = (state) => {
  return state.admin.scenarioSetup.opexList.map(id => state.admin.scenarioSetup.opexById[id]).filter(t => t.status.toLowerCase() === 'active')
}

export const getOpexListById = (state) => (id) => {
  return Object.assign([], state.admin.scenarioSetup.opexById[id])
}

export const createTemplatesByTypeSelector = (state, templateType) => {
  if (templateType === 'timeandcost') {
    return getTimeCostList(state)
  } else if (templateType === 'coststructure') {
    return getCostStructureList(state)
  } else if (templateType === 'predefinedscenario') {
    return getPredefinedScenarioList(state)
  } else if (templateType === 'tangible') {
    return getTangibleList(state)
  } else if (templateType === 'opex') {
    return getOpexList(state)
  } else {
    return []
  }
}

export const createDrillTripRateDetailsById = (state, id) => {
  return Object.assign([], state.admin.scenarioSetup.drillTripRateDetailsById[id])
}