
/**
 * This is a function.
 *
 * @param {function} func - A function param
 * @param {int} delay - time in ms
 * @return {function} Callable function to start running debounce
 *
 * @example
 *
 *     debounce(() => console.log('debounce!'), 1000)
 */
export const debounce = (func, delay) => {
  let inDebounce
  return function() {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

/**
 * This is a function.
 *
 * @param {function} func - A function param
 * @param {int} limit - time in ms
 * @return {function} Callable function to start running throttle
 *
 * @example
 *
 *     throttle(() => console.log('throttle!'), 1000)
 */
export const throttle = (func, limit) => {
  let inThrottle
  return function() {
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => inThrottle = false, limit)
    }
  }
}

/**
 * This is a function.
 *
 * @param {int} delay - time in ms
 * @return {Promise} promise callback after delay
 *
 * @example
 *
 *     delay(1000)
 */
export const delay = delay => {
  return new Promise(resolve => {
    setTimeout(resolve, delay)
  })
}

/**
 * This is a function.
 *
 * @param {string} search - location search
 * @param {string} key - key to extract
 * @return {string} return string query params of specify input
 *
 * @example
 *
 *     getQueryStringValue('https://example.com/?q=query', 'q') // return query
 */
export const getQueryStringValue = (search, key) => {  
  return decodeURIComponent(search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));  
}  
