import * as Constant from '../../actions/constants'
import { removeEmpty } from '../../helpers/object'
import { combineReducers } from 'redux'

export const defaultWellAmount = (state = 0, action) => {
  switch (action.type) {
    case Constant.ADMIN_GET_WELL_AMOUNT_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export const dollarBoEValue = (state = 0, action) => {
  switch(action.type){
    case Constant.GET_DOLLAR_BOE_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_GET_PROJECT_SETUP_SUCCESS:
    case Constant.ADMIN_SAVE_PROJECT_SETUP_SUCCESS:
      return {
        ...state,
        [action.payload.projectName]: {
          ...state[action.payload.projectName],
          ...removeEmpty(action.payload),
        }
      }
    default:
      return state
  }
}

export const dpiById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_PROJECT_DPI_GET_SUCCESS:
    case Constant.ADMIN_PROJECT_DPI_POST_SUCCESS:
    case Constant.ADMIN_PROJECT_DPI_PUBLISH_SUCCESS:
      return {
        ...state,
        [action.payload.projectName]: {
          ...state[action.payload.projectName],
          ...removeEmpty(action.payload),
        }
      }
    default:
      return state
  }
}

export default combineReducers({
  byId,
  defaultWellAmount,
  dpiById,
  dollarBoEValue
})

export const createProjectSetupIdSelector = (state, id) => {
  return state.admin.projectSetups.byId[id] ? Object.assign({}, state.admin.projectSetups.byId[id]) : null
}

export const createProjectDpiIdSelector = (state, id) => {
  return state.admin.projectSetups.dpiById[id] ? Object.assign({}, state.admin.projectSetups.dpiById[id]) : null
}
