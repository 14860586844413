import React, { memo } from 'react'
import { connect } from 'react-redux'
import { closeGlobalAlert } from '../../actions/views'
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar'
import style from './style.module.css'

const GlobalAlert = ({ visible, closeGlobalAlert, message }) => {
  if (!visible) return null
  return (
    <div className={style.message}>
      <MessageBar
        messageBarType={MessageBarType.severeWarning}
        onDismiss={closeGlobalAlert}
      >
        {message}
      </MessageBar>
    </div>
  )
}

const mapStateToProps = state => ({
  visible: state.views.isGlobalAlertVisible,
  message: state.views.globalAlertMessage,
})

export default connect(mapStateToProps, { closeGlobalAlert })(memo(GlobalAlert))