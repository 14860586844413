import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'
import { removeEmpty } from '../helpers/object'

export const allIds = (state = [], action) => {
  switch (action.type) {
    case Constant.WELLS_GET_SOR_STATUS_SUCCESS:
    case Constant.WELLS_GET_SUCCESS: {
      const result = [...state]
      action.payload.forEach(well => {
        if (!result.includes(well.name)) {
          result.push(well.name)
        }
      })
      return result
    }
    case Constant.WELL_PLANNED_LIQUID_RATIO_GET_SUCCESS: {
      if (!state.includes(action.payload.wellName)) {
        return [...state, action.payload.wellName]
      }
      return state
    }
    case Constant.WELL_GET_SUCCESS:
    case Constant.WELL_GET_STATUS_SUCCESS: {
      if (!state.includes(action.payload.name)) {
        return [...state, action.payload.name]
      }
      return state
    }
    case Constant.APP_DESTROY: return [];
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case Constant.WELLS_GET_SOR_STATUS_SUCCESS:
    case Constant.WELLS_GET_SUCCESS: {
      const result = {...state}
      action.payload.forEach(well => {
        result[well.name] = {
          ...result[well.name],
          ...removeEmpty(well),
        }
      })
      return result
    }
    case Constant.WELL_CALCULATE_RESERVES_GET_SUCCESS:
    case Constant.WELL_CALCULATE_DPI_GET_SUCCESS:
    case Constant.WELL_PLANNED_LIQUID_RATIO_GET_SUCCESS: {
      return {
        ...state,
        [action.payload.wellName]: {
          ...state[action.payload.wellName],
          ...removeEmpty(action.payload),
        }
      }
    }
   case Constant.GET_WELL_DOLLAR_BOE_SUCCESS:
     {
       return {
         ...state,
         [action.payload.wellName]: {
           ...state[action.payload.wellName],
           cutOff :  (action.payload.cutOff ? action.payload.cutOff : null )
         }
       }
     }
    case Constant.WELL_GET_SUCCESS:
    case Constant.WELL_GET_STATUS_SUCCESS: {
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          ...removeEmpty(action.payload),
        }
      }
    }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
})

export const createWellIdSelector = (state, id) => {
  return state.wells.byId[id] ? Object.assign({}, state.wells.byId[id]) : null
}

export const createWellPlannedIdSelector = (state, id) => {
  return createWellIdSelector(state, id)
}

export const createWellByProjectIdSelector = (state, id) => {
  return createAllWellSelector(state).filter(well => well.projectId === id)
}

export const createAllWellSelector = state => {
  return state.wells.allIds.map(id => state.wells.byId[id])
}
