import React, { memo } from 'react'
import style from './style.module.css'

export default memo(({ visible }) => {
  if (!visible) return null
  return (
    <div className={style.loader}>
          <div className={style['sk-folding-cube']}>
        <div className={`${style['sk-cube1']} ${style['sk-cube']}`}></div>
        <div className={`${style['sk-cube2']} ${style['sk-cube']}`}></div>
        <div className={`${style['sk-cube4']} ${style['sk-cube']}`}></div>
        <div className={`${style['sk-cube3']} ${style['sk-cube']}`}></div>
      </div>
    </div>
  )
})
