import * as Constant from '../../actions/constants'
import { combineReducers } from 'redux'

export const types = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_TEMPLATE_TYPE_GET_SUCCESS:
      return action.payload.reduce((previous, item) => {
        return {
          ...previous,
          [item.id]: item
        }
      }, {})
    default:
      return state
  }
}

export const allIds = (state = [], action) => {
  switch (action.type) {
    case Constant.ADMIN_TEMPLATE_POST_SUCCESS:
      if (!state.includes(action.payload.id)) {
        return [...state, action.payload.id]
      }
      return state
    case Constant.ADMIN_TEMPLATE_LIST_GET_SUCCESS: {
      const result = [...state]
      action.payload.forEach(template => {
        if (!result.includes(template.id)) {
          result.push(template.id)
        }
      })
      return result
    }
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_TEMPLATE_POST_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    case Constant.ADMIN_TEMPLATE_LIST_GET_SUCCESS: {
      const result = {...state}
      action.payload.forEach(template => {
        result[template.id] = {
          ...result[template.id],
          ...template,
        }
      })
      return result
    }
    default:
      return state
  }
}

export const areas = (state = [], action) => {
  switch (action.type) {
    case Constant.ADMIN_AREA_GET_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({
  types,
  allIds,
  byId,
  areas,
})

export const createTemplateTypeSelector = (state) => {
  return state.admin.templates.types
}

export const createTemplateByIdSelector = (state, id) => {
  return state.admin.templates.byId[id] ? Object.assign({}, state.admin.templates.byId[id]) : null
}

export const createFilterTemplateTypeSelector = (state, type) => {
  return Object.values(state.admin.templates.types)
    .filter(item => item.type.toLowerCase().includes(type.toLowerCase()))
}

export const createTemplatesByTypeSelector = (state, type) => {
  const typeIds = createFilterTemplateTypeSelector(state, type).map(item => item.id)
  return state.admin.templates.allIds
    .map(id => state.admin.templates.byId[id])
    .filter(item => typeIds.includes(item.templateTypeId))
    .sort((a, b) => new Date(b.created) - new Date(a.created))
}

export const createActiveTemplatesByTypeSelector = (state, type) => {
  return createTemplatesByTypeSelector(state, type)
    .filter(item => item.status.toLowerCase() === 'active')
}

export const createAreasByType = (state, type) => {
  return state.admin.templates.areas.filter(item => item.hcType.toLowerCase() === type.toLowerCase())
}
