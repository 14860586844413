import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'
import { removeEmpty } from '../helpers/object'

export const allIds = (state = [], action) => {
  switch (action.type) {
    case Constant.PROJECT_SUMMARY_SYNC_SUCCESS:
    case Constant.PROJECT_SUMMARY_POST_SUCCESS:
    case Constant.PROJECT_SUMMARY_PUBLISH_SUCCESS:
    case Constant.PROJECT_SUMMARY_GET_SUCCESS: {
      if (!state.includes(action.payload.projectName)) {
        return [...state, action.payload.projectName]
      }
      return state
    }
    case Constant.APP_DESTROY: return [];
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case Constant.PROJECT_SUMMARY_SYNC_SUCCESS:
    case Constant.PROJECT_SUMMARY_POST_SUCCESS:
    case Constant.PROJECT_SUMMARY_PUBLISH_SUCCESS:
    case Constant.PROJECT_SUMMARY_GET_SUCCESS: {
      return {
        ...state,
        [action.payload.projectName]: {
          ...state[action.payload.projectName],
          ...removeEmpty(action.payload),
        }
      }
    }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export const payById = (state = {}, action) => {
  switch (action.type) {
    case Constant.PROJECT_SUMMARY_PAY_GET_SUCCESS:
      return {
        ...state,
        [action.payload.rllcpProjectId]: action.payload
      }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export const reserveById = (state = {}, action) => {
  switch (action.type) {
    case Constant.PROJECT_SUMMARY_RESERVES_GET_SUCCESS:
      return {
        ...state,
        [action.payload.rllcpProjectId]: action.payload
      }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export const rigById = (state = {}, action) => {
  switch (action.type) {
    case Constant.PROJECT_SUMMARY_RIG_GET_SUCCESS:
      return {
        ...state,
        [action.payload.rllcpProjectId]: action.payload
      }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
  payById,
  rigById,
  reserveById,
})

export const createProjectSummaryIdByNameSelector = (state, name) => {
  const result = createAllProjectSelector(state).find(project => project.projectName === name)
  return result ? Object.assign({}, result) : null
}

export const createProjectSummaryByIdSelector = (state, id) => {
  return state.projectSummary.byId[id] ? Object.assign({}, state.projectSummary.byId[id]) : null
}

export const createAllProjectSelector = state => {
  return state.projectSummary.allIds.map(id => state.projectSummary.byId[id])
}

export const createProjectPayById = (state, id) => {
  return state.projectSummary.payById[id] ? Object.assign({}, state.projectSummary.payById[id]) : null
}

export const createProjectReservesById = (state, id) => {
  return state.projectSummary.reserveById[id] ? Object.assign({}, state.projectSummary.reserveById[id]) : null
}

export const createProjectRigById = (state, id) => {
  return state.projectSummary.rigById[id] ? Object.assign({}, state.projectSummary.rigById[id]) : null
}
