export const LOCATION_CHANGE = 'LOCATION_CHANGE'

export const APP_READY = 'APP_READY'
export const GLOBAL_LOADER = 'GLOBAL_LOADER'

export const APP_DESTROY = 'APP_DESTROY'

export const GLOBAL_ALERT_OPEN = 'GLOBAL_ALERT_OPEN'
export const GLOBAL_ALERT_CLOSE = 'GLOBAL_ALERT_CLOSE'

export const SESSION_LOGIN_REQUEST = 'SESSION_LOGIN_REQUEST'
export const SESSION_LOGIN_SUCCESS = 'SESSION_LOGIN_SUCCESS'
export const SESSION_LOGIN_FAILURE = 'SESSION_LOGIN_FAILURE'

export const SESSION_LOGOUT_REQUEST = 'SESSION_LOGOUT_REQUEST'
export const SESSION_LOGOUT_SUCCESS = 'SESSION_LOGOUT_SUCCESS'
export const SESSION_LOGOUT_FAILURE = 'SESSION_LOGOUT_FAILURE'

export const SESSION_AUTHEN_REQUEST = 'SESSION_AUTHEN_REQUEST'
export const SESSION_AUTHEN_SUCCESS = 'SESSION_AUTHEN_SUCCESS'
export const SESSION_AUTHEN_FAILURE = 'SESSION_AUTHEN_FAILURE'

export const USER_GET_REQUEST = 'USER_GET_REQUEST'
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS'
export const USER_GET_FAILURE = 'USER_GET_FAILURE'

export const USER_PUT_REQUEST = 'USER_PUT_REQUEST'
export const USER_PUT_SUCCESS = 'USER_PUT_SUCCESS'
export const USER_PUT_FAILURE = 'USER_PUT_FAILURE'

export const USER_POST_REQUEST = 'USER_POST_REQUEST'
export const USER_POST_SUCCESS = 'USER_POST_SUCCESS'
export const USER_POST_FAILURE = 'USER_POST_FAILURE'

export const USERS_GET_REQUEST = 'USERS_GET_REQUEST'
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS'
export const USERS_GET_FAILURE = 'USERS_GET_FAILURE'

export const USERS_SYNC_GET_REQUEST = 'USERS_SYNC_GET_REQUEST'
export const USERS_SYNC_GET_SUCCESS = 'USERS_SYNC_GET_SUCCESS'
export const USERS_SYNC_GET_FAILURE = 'USERS_SYNC_GET_FAILURE'

export const USERS_REMOVE_REQUEST = 'USERS_REMOVE_REQUEST'
export const USERS_REMOVE_SUCCESS = 'USERS_REMOVE_SUCCESS'
export const USERS_REMOVE_FAILURE = 'USERS_REMOVE_FAILURE'

export const USERS_ASSIGN_PROJECT_REQUEST = 'USERS_ASSIGN_PROJECT_REQUEST'
export const USERS_ASSIGN_PROJECT_SUCCESS = 'USERS_ASSIGN_PROJECT_SUCCESS'
export const USERS_ASSIGN_PROJECT_FAILURE = 'USERS_ASSIGN_PROJECT_FAILURE'

export const USERS_PROJECT_GET_REQUEST = 'USERS_PROJECT_GET_REQUEST'
export const USERS_PROJECT_GET_SUCCESS = 'USERS_PROJECT_GET_SUCCESS'
export const USERS_PROJECT_GET_FAILURE = 'USERS_PROJECT_GET_FAILURE'

export const USERS_REGISTER_REQUEST = 'USERS_REGISTER_REQUEST'
export const USERS_REGISTER_SUCCESS = 'USERS_REGISTER_SUCCESS'
export const USERS_REGISTER_FAILURE = 'USERS_REGISTER_FAILURE'

export const LOCATIONS_GET_REQUEST = 'LOCATIONS_GET_REQUEST'
export const LOCATIONS_GET_SUCCESS = 'LOCATIONS_GET_SUCCESS'
export const LOCATIONS_GET_FAILURE = 'LOCATIONS_GET_FAILURE'

export const ROLES_GET_REQUEST = 'ROLES_GET_REQUEST'
export const ROLES_GET_SUCCESS = 'ROLES_GET_SUCCESS'
export const ROLES_GET_FAILURE = 'ROLES_GET_FAILURE'

export const ROLES_AUTHORIZE_GET_REQUEST = 'ROLES_AUTHORIZE_GET_REQUEST'
export const ROLES_AUTHORIZE_GET_SUCCESS = 'ROLES_AUTHORIZE_GET_SUCCESS'
export const ROLES_AUTHORIZE_GET_FAILURE = 'ROLES_AUTHORIZE_GET_FAILURE'

export const USERS_AUTHENS_GET_REQUEST = 'USERS_AUTHENS_GET_REQUEST'
export const USERS_AUTHENS_GET_SUCCESS = 'USERS_AUTHENS_GET_SUCCESS'
export const USERS_AUTHENS_GET_FAILURE = 'USERS_AUTHENS_GET_FAILURE'

export const USER_AUTHEN_POST_REQUEST = 'USER_AUTHEN_POST_REQUEST'
export const USER_AUTHEN_POST_SUCCESS = 'USER_AUTHEN_POST_SUCCESS'
export const USER_AUTHEN_POST_FAILURE = 'USER_AUTHEN_POST_FAILURE'

export const ROLE_CONFIG_SAVE_REQUEST = 'ROLE_CONFIG_SAVE_REQUEST'
export const ROLE_CONFIG_SAVE_SUCCESS = 'ROLE_CONFIG_SAVE_SUCCESS'
export const ROLE_CONFIG_SAVE_FAILURE = 'ROLE_CONFIG_SAVE_FAILURE'

export const ROLE_AUTHORIZE_POST_REQUEST = 'ROLE_AUTHORIZE_POST_REQUEST'
export const ROLE_AUTHORIZE_POST_SUCCESS = 'ROLE_AUTHORIZE_POST_SUCCESS'
export const ROLE_AUTHORIZE_POST_FAILURE = 'ROLE_AUTHORIZE_POST_FAILURE'

export const PROJECT_LOCATIONS_GET_REQUEST = 'PROJECT_LOCATIONS_GET_REQUEST'
export const PROJECT_LOCATIONS_GET_SUCCESS = 'PROJECT_LOCATIONS_GET_SUCCESS'
export const PROJECT_LOCATIONS_GET_FAILURE = 'PROJECT_LOCATIONS_GET_FAILURE'

export const PROJECT_LOCATION_POST_REQUEST = 'PROJECT_LOCATION_POST_REQUEST'
export const PROJECT_LOCATION_POST_SUCCESS = 'PROJECT_LOCATION_POST_SUCCESS'
export const PROJECT_LOCATION_POST_FAILURE = 'PROJECT_LOCATION_POST_FAILURE'

export const PROJECT_AUTHORIZE_GET_REQUEST = 'PROJECT_AUTHORIZE_GET_REQUEST'
export const PROJECT_AUTHORIZE_GET_SUCCESS = 'PROJECT_AUTHORIZE_GET_SUCCESS'
export const PROJECT_AUTHORIZE_GET_FAILURE = 'PROJECT_AUTHORIZE_GET_FAILURE'

export const PROJECT_AUTHORIZE_POST_REQUEST = 'PROJECT_AUTHORIZE_POST_REQUEST'
export const PROJECT_AUTHORIZE_POST_SUCCESS = 'PROJECT_AUTHORIZE_POST_SUCCESS'
export const PROJECT_AUTHORIZE_POST_FAILURE = 'PROJECT_AUTHORIZE_POST_FAILURE'

export const PROJECT_GET_REQUEST = 'PROJECT_GET_REQUEST'
export const PROJECT_GET_SUCCESS = 'PROJECT_GET_SUCCESS'
export const PROJECT_GET_FAILURE = 'PROJECT_GET_FAILURE'

export const PROJECTS_GET_REQUEST = 'PROJECTS_GET_REQUEST'
export const PROJECTS_GET_SUCCESS = 'PROJECTS_GET_SUCCESS'
export const PROJECTS_GET_FAILURE = 'PROJECTS_GET_FAILURE'

export const PROJECTS_SYNC_GET_REQUEST = 'PROJECTS_SYNC_GET_REQUEST'
export const PROJECTS_SYNC_GET_SUCCESS = 'PROJECTS_SYNC_GET_SUCCESS'
export const PROJECTS_SYNC_GET_FAILURE = 'PROJECTS_SYNC_GET_FAILURE'

export const ADMIN_PRODUCTION_APPLICABLE_REQUEST = 'ADMIN_PRODUCTION_APPLICABLE_REQUEST'
export const ADMIN_PRODUCTION_APPLICABLE_SUCCESS = 'ADMIN_PRODUCTION_APPLICABLE_SUCCESS'
export const ADMIN_PRODUCTION_APPLICABLE_FAILURE = 'ADMIN_PRODUCTION_APPLICABLE_FAILURE'

export const ADMIN_PRODUCTION_POST_REQUEST = 'ADMIN_PRODUCTION_POST_REQUEST'
export const ADMIN_PRODUCTION_POST_SUCCESS = 'ADMIN_PRODUCTION_POST_SUCCESS'
export const ADMIN_PRODUCTION_POST_FAILURE = 'ADMIN_PRODUCTION_POST_FAILURE'

export const ADMIN_PRODUCTION_PUT_REQUEST = 'ADMIN_PRODUCTION_PUT_REQUEST'
export const ADMIN_PRODUCTION_PUT_SUCCESS = 'ADMIN_PRODUCTION_PUT_SUCCESS'
export const ADMIN_PRODUCTION_PUT_FAILURE = 'ADMIN_PRODUCTION_PUT_FAILURE'

export const ADMIN_PRODUCTION_LIST_REQUEST = 'ADMIN_PRODUCTION_LIST_REQUEST'
export const ADMIN_PRODUCTION_LIST_SUCCESS = 'ADMIN_PRODUCTION_LIST_SUCCESS'
export const ADMIN_PRODUCTION_LIST_FAILURE = 'ADMIN_PRODUCTION_LIST_FAILURE'

export const ADMIN_PRODUCTION_AREA_REQUEST = 'ADMIN_PRODUCTION_AREA_REQUEST'
export const ADMIN_PRODUCTION_AREA_SUCCESS = 'ADMIN_PRODUCTION_AREA_SUCCESS'
export const ADMIN_PRODUCTION_AREA_FAILURE = 'ADMIN_PRODUCTION_AREA_FAILURE'

export const ADMIN_GET_BO_PROFILES_REQUEST = 'ADMIN_GET_BO_PROFILES_REQUEST'
export const ADMIN_GET_BO_PROFILES_SUCCESS = 'ADMIN_GET_BO_PROFILES_SUCCESS'
export const ADMIN_GET_BO_PROFILES_FAILURE = 'ADMIN_GET_BO_PROFILES_FAILURE'

export const ADMIN_GET_BG_PROFILES_REQUEST = 'ADMIN_GET_BG_PROFILES_REQUEST'
export const ADMIN_GET_BG_PROFILES_SUCCESS = 'ADMIN_GET_BG_PROFILES_SUCCESS'
export const ADMIN_GET_BG_PROFILES_FAILURE = 'ADMIN_GET_BG_PROFILES_FAILURE'

export const ADMIN_GET_WELL_AMOUNT_REQUEST = 'ADMIN_GET_WELL_AMOUNT_REQUEST'
export const ADMIN_GET_WELL_AMOUNT_SUCCESS = 'ADMIN_GET_WELL_AMOUNT_SUCCESS'
export const ADMIN_GET_WELL_AMOUNT_FAILURE = 'ADMIN_GET_WELL_AMOUNT_FAILURE'

export const ADMIN_GET_PROJECT_SETUP_REQUEST = 'ADMIN_GET_PROJECT_SETUP_REQUEST'
export const ADMIN_GET_PROJECT_SETUP_SUCCESS = 'ADMIN_GET_PROJECT_SETUP_SUCCESS'
export const ADMIN_GET_PROJECT_SETUP_FAILURE = 'ADMIN_GET_PROJECT_SETUP_FAILURE'

export const ADMIN_SAVE_PROJECT_SETUP_REQUEST = 'ADMIN_SAVE_PROJECT_SETUP_REQUEST'
export const ADMIN_SAVE_PROJECT_SETUP_SUCCESS = 'ADMIN_SAVE_PROJECT_SETUP_SUCCESS'
export const ADMIN_SAVE_PROJECT_SETUP_FAILURE = 'ADMIN_SAVE_PROJECT_SETUP_FAILURE'

export const ADMIN_TEMPLATE_TYPE_GET_REQUEST = 'ADMIN_TEMPLATE_TYPE_GET_REQUEST'
export const ADMIN_TEMPLATE_TYPE_GET_SUCCESS = 'ADMIN_TEMPLATE_TYPE_GET_SUCCESS'
export const ADMIN_TEMPLATE_TYPE_GET_FAILURE = 'ADMIN_TEMPLATE_TYPE_GET_FAILURE'

export const ADMIN_TEMPLATE_LIST_GET_REQUEST = 'ADMIN_TEMPLATE_LIST_GET_REQUEST'
export const ADMIN_TEMPLATE_LIST_GET_SUCCESS = 'ADMIN_TEMPLATE_LIST_GET_SUCCESS'
export const ADMIN_TEMPLATE_LIST_GET_FAILURE = 'ADMIN_TEMPLATE_LIST_GET_FAILURE'

export const ADMIN_TEMPLATE_POST_REQUEST = 'ADMIN_TEMPLATE_POST_REQUEST'
export const ADMIN_TEMPLATE_POST_SUCCESS = 'ADMIN_TEMPLATE_POST_SUCCESS'
export const ADMIN_TEMPLATE_POST_FAILURE = 'ADMIN_TEMPLATE_POST_FAILURE'

export const ADMIN_AREA_GET_REQUEST = 'ADMIN_AREA_GET_REQUEST'
export const ADMIN_AREA_GET_SUCCESS = 'ADMIN_AREA_GET_SUCCESS'
export const ADMIN_AREA_GET_FAILURE = 'ADMIN_AREA_GET_FAILURE'

export const ADMIN_TIME_COST_LIST_GET_REQUEST = 'ADMIN_TIME_COST_LIST_GET_REQUEST'
export const ADMIN_TIME_COST_LIST_GET_SUCCESS = 'ADMIN_TIME_COST_LIST_GET_SUCCESS'
export const ADMIN_TIME_COST_LIST_GET_FAILURE = 'ADMIN_TIME_COST_LIST_GET_FAILURE'

export const ADMIN_TIME_COST_POST_REQUEST = 'ADMIN_TIME_COST_POST_REQUEST'
export const ADMIN_TIME_COST_POST_SUCCESS = 'ADMIN_TIME_COST_POST_SUCCESS'
export const ADMIN_TIME_COST_POST_FAILURE = 'ADMIN_TIME_COST_POST_FAILURE'

export const ADMIN_TIME_COST_PUT_REQUEST = 'ADMIN_TIME_COST_PUT_REQUEST'
export const ADMIN_TIME_COST_PUT_SUCCESS = 'ADMIN_TIME_COST_PUT_SUCCESS'
export const ADMIN_TIME_COST_PUT_FAILURE = 'ADMIN_TIME_COST_PUT_FAILURE'

export const ADMIN_TANGIBLE_LIST_GET_REQUEST = 'ADMIN_TANGIBLE_LIST_GET_REQUEST'
export const ADMIN_TANGIBLE_LIST_GET_SUCCESS = 'ADMIN_TANGIBLE_LIST_GET_SUCCESS'
export const ADMIN_TANGIBLE_LIST_GET_FAILURE = 'ADMIN_TANGIBLE_LIST_GET_FAILURE'

export const ADMIN_TANGIBLE_POST_REQUEST = 'ADMIN_TANGIBLE_POST_REQUEST'
export const ADMIN_TANGIBLE_POST_SUCCESS = 'ADMIN_TANGIBLE_POST_SUCCESS'
export const ADMIN_TANGIBLE_POST_FAILURE = 'ADMIN_TANGIBLE_POST_FAILURE'

export const ADMIN_TANGIBLE_PUT_REQUEST = 'ADMIN_TANGIBLE_PUT_REQUEST'
export const ADMIN_TANGIBLE_PUT_SUCCESS = 'ADMIN_TANGIBLE_PUT_SUCCESS'
export const ADMIN_TANGIBLE_PUT_FAILURE = 'ADMIN_TANGIBLE_PUT_FAILURE'

export const ADMIN_OPEX_LIST_GET_REQUEST = 'ADMIN_OPEX_LIST_GET_REQUEST'
export const ADMIN_OPEX_LIST_GET_SUCCESS = 'ADMIN_OPEX_LIST_GET_SUCCESS'
export const ADMIN_OPEX_LIST_GET_FAILURE = 'ADMIN_OPEX_LIST_GET_FAILURE'

export const ADMIN_OPEX_POST_REQUEST = 'ADMIN_OPEX_POST_REQUEST'
export const ADMIN_OPEX_POST_SUCCESS = 'ADMIN_OPEX_POST_SUCCESS'
export const ADMIN_OPEX_POST_FAILURE = 'ADMIN_OPEX_POST_FAILURE'

export const ADMIN_OPEX_PUT_REQUEST = 'ADMIN_OPEX_PUT_REQUEST'
export const ADMIN_OPEX_PUT_SUCCESS = 'ADMIN_OPEX_PUT_SUCCESS'
export const ADMIN_OPEX_PUT_FAILURE = 'ADMIN_OPEX_PUT_FAILURE'

export const ADMIN_COST_STRUCTURE_LIST_GET_REQUEST = 'ADMIN_COST_STRUCTURE_LIST_GET_REQUEST'
export const ADMIN_COST_STRUCTURE_LIST_GET_SUCCESS = 'ADMIN_COST_STRUCTURE_LIST_GET_SUCCESS'
export const ADMIN_COST_STRUCTURE_LIST_GET_FAILURE = 'ADMIN_COST_STRUCTURE_LIST_GET_FAILURE'

export const ADMIN_COST_STRUCTURE_POST_REQUEST = 'ADMIN_COST_STRUCTURE_POST_REQUEST'
export const ADMIN_COST_STRUCTURE_POST_SUCCESS = 'ADMIN_COST_STRUCTURE_POST_SUCCESS'
export const ADMIN_COST_STRUCTURE_POST_FAILURE = 'ADMIN_COST_STRUCTURE_POST_FAILURE'

export const ADMIN_COST_STRUCTURE_PUT_REQUEST = 'ADMIN_COST_STRUCTURE_PUT_REQUEST'
export const ADMIN_COST_STRUCTURE_PUT_SUCCESS = 'ADMIN_COST_STRUCTURE_PUT_SUCCESS'
export const ADMIN_COST_STRUCTURE_PUT_FAILURE = 'ADMIN_COST_STRUCTURE_PUT_FAILURE'

export const ADMIN_COST_DETAILS_LIST_GET_REQUEST = 'ADMIN_COST_DETAILS_LIST_GET_REQUEST'
export const ADMIN_COST_DETAILS_LIST_GET_SUCCESS = 'ADMIN_COST_DETAILS_LIST_GET_SUCCESS'
export const ADMIN_COST_DETAILS_LIST_GET_FAILURE = 'ADMIN_COST_DETAILS_LIST_GET_FAILURE'

export const ADMIN_COST_DETAILS_GET_REQUEST = 'ADMIN_COST_DETAILS_GET_REQUEST'
export const ADMIN_COST_DETAILS_GET_SUCCESS = 'ADMIN_COST_DETAILS_GET_SUCCESS'
export const ADMIN_COST_DETAILS_GET_FAILURE = 'ADMIN_COST_DETAILS_GET_FAILURE'

export const ADMIN_COST_DETAILS_POST_REQUEST = 'ADMIN_COST_DETAILS_POST_REQUEST'
export const ADMIN_COST_DETAILS_POST_SUCCESS = 'ADMIN_COST_DETAILS_POST_SUCCESS'
export const ADMIN_COST_DETAILS_POST_FAILURE = 'ADMIN_COST_DETAILS_POST_FAILURE'

export const ADMIN_COST_DETAILS_PUT_REQUEST = 'ADMIN_COST_DETAILS_PUT_REQUEST'
export const ADMIN_COST_DETAILS_PUT_SUCCESS = 'ADMIN_COST_DETAILS_PUT_SUCCESS'
export const ADMIN_COST_DETAILS_PUT_FAILURE = 'ADMIN_COST_DETAILS_PUT_FAILURE'

export const ADMIN_COST_DETAILS_DELETE_REQUEST = 'ADMIN_COST_DETAILS_DELETE_REQUEST'
export const ADMIN_COST_DETAILS_DELETE_SUCCESS = 'ADMIN_COST_DETAILS_DELETE_SUCCESS'
export const ADMIN_COST_DETAILS_DELETE_FAILURE = 'ADMIN_COST_DETAILS_DELETE_FAILURE'

export const ADMIN_DRILL_TRIP_RATE_DETAILS_GET_REQUEST = 'ADMIN_DRILL_TRIP_RATE_DETAILS_GET_REQUEST'
export const ADMIN_DRILL_TRIP_RATE_DETAILS_GET_SUCCESS = 'ADMIN_DRILL_TRIP_RATE_DETAILS_GET_SUCCESS'
export const ADMIN_DRILL_TRIP_RATE_DETAILS_GET_FAILURE = 'ADMIN_DRILL_TRIP_RATE_DETAILS_GET_FAILURE'

export const ADMIN_PREDEFINED_SCENARIO_LIST_GET_REQUEST = 'ADMIN_PREDEFINED_SCENARIO_LIST_GET_REQUEST'
export const ADMIN_PREDEFINED_SCENARIO_LIST_GET_SUCCESS = 'ADMIN_PREDEFINED_SCENARIO_LIST_GET_SUCCESS'
export const ADMIN_PREDEFINED_SCENARIO_LIST_GET_FAILURE = 'ADMIN_PREDEFINED_SCENARIO_LIST_GET_FAILURE'

export const ADMIN_PREDEFINED_SCENARIO_POST_REQUEST = 'ADMIN_PREDEFINED_SCENARIO_POST_REQUEST'
export const ADMIN_PREDEFINED_SCENARIO_POST_SUCCESS = 'ADMIN_PREDEFINED_SCENARIO_POST_SUCCESS'
export const ADMIN_PREDEFINED_SCENARIO_POST_FAILURE = 'ADMIN_PREDEFINED_SCENARIO_POST_FAILURE'

export const ADMIN_PREDEFINED_SCENARIO_PUT_REQUEST = 'ADMIN_PREDEFINED_SCENARIO_PUT_REQUEST'
export const ADMIN_PREDEFINED_SCENARIO_PUT_SUCCESS = 'ADMIN_PREDEFINED_SCENARIO_PUT_SUCCESS'
export const ADMIN_PREDEFINED_SCENARIO_PUT_FAILURE = 'ADMIN_PREDEFINED_SCENARIO_PUT_FAILURE'

export const ADMIN_PROJECT_DPI_GET_REQUEST = 'ADMIN_PROJECT_DPI_GET_REQUEST'
export const ADMIN_PROJECT_DPI_GET_SUCCESS = 'ADMIN_PROJECT_DPI_GET_SUCCESS'
export const ADMIN_PROJECT_DPI_GET_FAILURE = 'ADMIN_PROJECT_DPI_GET_FAILURE'

export const ADMIN_PROJECT_DPI_LIST_REQUEST = 'ADMIN_PROJECT_DPI_LIST_REQUEST'
export const ADMIN_PROJECT_DPI_LIST_SUCCESS = 'ADMIN_PROJECT_DPI_LIST_SUCCESS'
export const ADMIN_PROJECT_DPI_LIST_FAILURE = 'ADMIN_PROJECT_DPI_LIST_FAILURE'

export const ADMIN_PROJECT_DPI_POST_REQUEST = 'ADMIN_PROJECT_DPI_POST_REQUEST'
export const ADMIN_PROJECT_DPI_POST_SUCCESS = 'ADMIN_PROJECT_DPI_POST_SUCCESS'
export const ADMIN_PROJECT_DPI_POST_FAILURE = 'ADMIN_PROJECT_DPI_POST_FAILURE'

export const ADMIN_PROJECT_DPI_PUBLISH_REQUEST = 'ADMIN_PROJECT_DPI_PUBLISH_REQUEST'
export const ADMIN_PROJECT_DPI_PUBLISH_SUCCESS = 'ADMIN_PROJECT_DPI_PUBLISH_SUCCESS'
export const ADMIN_PROJECT_DPI_PUBLISH_FAILURE = 'ADMIN_PROJECT_DPI_PUBLISH_FAILURE'

export const WELL_GET_REQUEST = 'WELL_GET_REQUEST'
export const WELL_GET_SUCCESS = 'WELL_GET_SUCCESS'
export const WELL_GET_FAILURE = 'WELL_GET_FAILURE'

export const WELLS_GET_REQUEST = 'WELLS_GET_REQUEST'
export const WELLS_GET_SUCCESS = 'WELLS_GET_SUCCESS'
export const WELLS_GET_FAILURE = 'WELLS_GET_FAILURE'

export const WELLS_GET_SOR_STATUS_REQUEST = 'WELLS_GET_SOR_STATUS_REQUEST'
export const WELLS_GET_SOR_STATUS_SUCCESS = 'WELLS_GET_SOR_STATUS_SUCCESS'
export const WELLS_GET_SOR_STATUS_FAILURE = 'WELLS_GET_SOR_STATUS_FAILURE'

export const WELL_GET_STATUS_REQUEST = 'WELL_GET_STATUS_REQUEST'
export const WELL_GET_STATUS_SUCCESS = 'WELL_GET_STATUS_SUCCESS'
export const WELL_GET_STATUS_FAILURE = 'WELL_GET_STATUS_FAILURE'

export const WELL_CALCULATE_RESERVES_GET_REQUEST = 'WELL_CALCULATE_RESERVES_GET_REQUEST'
export const WELL_CALCULATE_RESERVES_GET_SUCCESS = 'WELL_CALCULATE_RESERVES_GET_SUCCESS'
export const WELL_CALCULATE_RESERVES_GET_FAILURE = 'WELL_CALCULATE_RESERVES_GET_FAILURE'

export const WELL_CALCULATE_DPI_GET_REQUEST = 'WELL_CALCULATE_DPI_GET_REQUEST'
export const WELL_CALCULATE_DPI_GET_SUCCESS = 'WELL_CALCULATE_DPI_GET_SUCCESS'
export const WELL_CALCULATE_DPI_GET_FAILURE = 'WELL_CALCULATE_DPI_GET_FAILURE'

export const WELL_RESERVES_UNDRILLED_GET_REQUEST = 'WELL_RESERVES_UNDRILLED_GET_REQUEST'
export const WELL_RESERVES_UNDRILLED_GET_SUCCESS = 'WELL_RESERVES_UNDRILLED_GET_SUCCESS'
export const WELL_RESERVES_UNDRILLED_GET_FAILURE = 'WELL_RESERVES_UNDRILLED_GET_FAILURE'

export const WELL_RESERVES_UNDRILLED_SAVE_REQUEST = 'WELL_RESERVES_UNDRILLED_SAVE_REQUEST'
export const WELL_RESERVES_UNDRILLED_SAVE_SUCCESS = 'WELL_RESERVES_UNDRILLED_SAVE_SUCCESS'
export const WELL_RESERVES_UNDRILLED_SAVE_FAILURE = 'WELL_RESERVES_UNDRILLED_SAVE_FAILURE'

export const WELL_RESERVES_UNDRILLED_METHOD_GET_REQUEST = 'WELL_RESERVES_UNDRILLED_METHOD_GET_REQUEST'
export const WELL_RESERVES_UNDRILLED_METHOD_GET_SUCCESS = 'WELL_RESERVES_UNDRILLED_METHOD_GET_SUCCESS'
export const WELL_RESERVES_UNDRILLED_METHOD_GET_FAILURE = 'WELL_RESERVES_UNDRILLED_METHOD_GET_FAILURE'

export const WELL_PLANNED_RESERVES_REQUEST = 'WELL_PLANNED_RESERVES_REQUEST'
export const WELL_PLANNED_RESERVES_SUCCESS = 'WELL_PLANNED_RESERVES_SUCCESS'
export const WELL_PLANNED_RESERVES_FAILURE = 'WELL_PLANNED_RESERVES_FAILURE'

export const WELL_RESERVES_CALCULATE_REQUEST = 'WELL_RESERVES_CALCULATE_REQUEST'
export const WELL_RESERVES_CALCULATE_SUCCESS = 'WELL_RESERVES_CALCULATE_SUCCESS'
export const WELL_RESERVES_CALCULATE_FAILURE = 'WELL_RESERVES_CALCULATE_FAILURE'

export const WELL_RESERVES_SYNC_REQUEST = 'WELL_RESERVES_SYNC_REQUEST'
export const WELL_RESERVES_SYNC_SUCCESS = 'WELL_RESERVES_SYNC_SUCCESS'
export const WELL_RESERVES_SYNC_FAILURE = 'WELL_RESERVES_SYNC_FAILURE'

export const WELL_RESERVES_RESET_REQUEST = 'WELL_RESERVES_RESET_REQUEST'
export const WELL_RESERVES_RESET_SUCCESS = 'WELL_RESERVES_RESET_SUCCESS'
export const WELL_RESERVES_RESET_FAILURE = 'WELL_RESERVES_RESET_FAILURE'

export const WELL_RESERVES_PUBLISH_REQUEST = 'WELL_RESERVES_PUBLISH_REQUEST'
export const WELL_RESERVES_PUBLISH_SUCCESS = 'WELL_RESERVES_PUBLISH_SUCCESS'
export const WELL_RESERVES_PUBLISH_FAILURE = 'WELL_RESERVES_PUBLISH_FAILURE'

export const WELL_RESERVES_GET_REQUEST = 'WELL_RESERVES_GET_REQUEST'
export const WELL_RESERVES_GET_SUCCESS = 'WELL_RESERVES_GET_SUCCESS'
export const WELL_RESERVES_GET_FAILURE = 'WELL_RESERVES_GET_FAILURE'

export const WELL_RESERVES_SAVE_REQUEST = 'WELL_RESERVES_SAVE_REQUEST'
export const WELL_RESERVES_SAVE_SUCCESS = 'WELL_RESERVES_SAVE_SUCCESS'
export const WELL_RESERVES_SAVE_FAILURE = 'WELL_RESERVES_SAVE_FAILURE'

export const WELL_RESERVES_MERGE_REQUEST = 'WELL_RESERVES_MERGE_REQUEST'
export const WELL_RESERVES_MERGE_SUCCESS = 'WELL_RESERVES_MERGE_SUCCESS'
export const WELL_RESERVES_MERGE_FAILURE = 'WELL_RESERVES_MERGE_FAILURE'

export const WELL_DECISIONS_GET_REQUEST = 'WELL_DECISIONS_GET_REQUEST'
export const WELL_DECISIONS_GET_SUCCESS = 'WELL_DECISIONS_GET_SUCCESS'
export const WELL_DECISIONS_GET_FAILURE = 'WELL_DECISIONS_GET_FAILURE'

export const WELL_DECISION_GET_REQUEST = 'WELL_DECISION_GET_REQUEST'
export const WELL_DECISION_GET_SUCCESS = 'WELL_DECISION_GET_SUCCESS'
export const WELL_DECISION_GET_FAILURE = 'WELL_DECISION_GET_FAILURE'

export const WELL_DECISION_LATCH_REQUEST = 'WELL_DECISION_LATCH_REQUEST'
export const WELL_DECISION_LATCH_SUCCESS = 'WELL_DECISION_LATCH_SUCCESS'
export const WELL_DECISION_LATCH_FAILURE = 'WELL_DECISION_LATCH_FAILURE'

export const WELL_DECISION_DUPLICATE_REQUEST = 'WELL_DECISION_DUPLICATE_REQUEST'
export const WELL_DECISION_DUPLICATE_SUCCESS = 'WELL_DECISION_DUPLICATE_SUCCESS'
export const WELL_DECISION_DUPLICATE_FAILURE = 'WELL_DECISION_DUPLICATE_FAILURE'

export const WELL_DECISION_DELETE_REQUEST = 'WELL_DECISION_DELETE_REQUEST'
export const WELL_DECISION_DELETE_SUCCESS = 'WELL_DECISION_DELETE_SUCCESS'
export const WELL_DECISION_DELETE_FAILURE = 'WELL_DECISION_DELETE_FAILURE'

export const WELL_DECISION_SAVE_REQUEST = 'WELL_DECISION_SAVE_REQUEST'
export const WELL_DECISION_SAVE_SUCCESS = 'WELL_DECISION_SAVE_SUCCESS'
export const WELL_DECISION_SAVE_FAILURE = 'WELL_DECISION_SAVE_FAILURE'

export const WELL_DECISION_PUBLISH_REQUEST = 'WELL_DECISION_PUBLISH_REQUEST'
export const WELL_DECISION_PUBLISH_SUCCESS = 'WELL_DECISION_PUBLISH_SUCCESS'
export const WELL_DECISION_PUBLISH_FAILURE = 'WELL_DECISION_PUBLISH_FAILURE'

export const SAND_RESERVES_GET_OPENWORK_CATEGORIES_REQUEST = 'SAND_RESERVES_GET_OPENWORK_CATEGORIES_REQUEST'
export const SAND_RESERVES_GET_OPENWORK_CATEGORIES_SUCCESS = 'SAND_RESERVES_GET_OPENWORK_CATEGORIES_SUCCESS'
export const SAND_RESERVES_GET_OPENWORK_CATEGORIES_FAILURE = 'SAND_RESERVES_GET_OPENWORK_CATEGORIES_FAILURE'

export const SAND_RESERVES_GET_DEPLETIONS_REQUEST = 'SAND_RESERVES_GET_DEPLETIONS_REQUEST'
export const SAND_RESERVES_GET_DEPLETIONS_SUCCESS = 'SAND_RESERVES_GET_DEPLETIONS_SUCCESS'
export const SAND_RESERVES_GET_DEPLETIONS_FAILURE = 'SAND_RESERVES_GET_DEPLETIONS_FAILURE'

export const SAND_RESERVES_GET_BO_PROFILES_REQUEST = 'SAND_RESERVES_GET_BO_PROFILES_REQUEST'
export const SAND_RESERVES_GET_BO_PROFILES_SUCCESS = 'SAND_RESERVES_GET_BO_PROFILES_SUCCESS'
export const SAND_RESERVES_GET_BO_PROFILES_FAILURE = 'SAND_RESERVES_GET_BO_PROFILES_FAILURE'

export const SAND_RESERVES_GET_BG_PROFILES_REQUEST = 'SAND_RESERVES_GET_BG_PROFILES_REQUEST'
export const SAND_RESERVES_GET_BG_PROFILES_SUCCESS = 'SAND_RESERVES_GET_BG_PROFILES_SUCCESS'
export const SAND_RESERVES_GET_BG_PROFILES_FAILURE = 'SAND_RESERVES_GET_BG_PROFILES_FAILURE'

export const SAND_RESERVES_GET_AREA_NAMES_REQUEST = 'SAND_RESERVES_GET_AREA_NAMES_REQUEST'
export const SAND_RESERVES_GET_AREA_NAMES_SUCCESS = 'SAND_RESERVES_GET_AREA_NAMES_SUCCESS'
export const SAND_RESERVES_GET_AREA_NAMES_FAILURE = 'SAND_RESERVES_GET_AREA_NAMES_FAILURE'

export const SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_REQUEST = 'SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_REQUEST'
export const SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_SUCCESS = 'SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_SUCCESS'
export const SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_FAILURE = 'SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_FAILURE'

export const WELL_DEFAULT_ANALOGY_GET_REQUEST = 'WELL_DEFAULT_ANALOGY_GET_REQUEST'
export const WELL_DEFAULT_ANALOGY_GET_SUCCESS = 'WELL_DEFAULT_ANALOGY_GET_SUCCESS'
export const WELL_DEFAULT_ANALOGY_GET_FAILURE = 'WELL_DEFAULT_ANALOGY_GET_FAILURE'

export const WELL_PLANNED_LIQUID_RATIO_GET_REQUEST = 'WELL_PLANNED_LIQUID_RATIO_GET_REQUEST'
export const WELL_PLANNED_LIQUID_RATIO_GET_SUCCESS = 'WELL_PLANNED_LIQUID_RATIO_GET_SUCCESS'
export const WELL_PLANNED_LIQUID_RATIO_GET_FAILURE = 'WELL_PLANNED_LIQUID_RATIO_GET_FAILURE'

export const WELL_RESERVES_UNDRILLED_SYNC_REQUEST = 'WELL_RESERVES_UNDRILLED_SYNC_REQUEST'
export const WELL_RESERVES_UNDRILLED_SYNC_SUCCESS = 'WELL_RESERVES_UNDRILLED_SYNC_SUCCESS'
export const WELL_RESERVES_UNDRILLED_SYNC_FAILURE = 'WELL_RESERVES_UNDRILLED_SYNC_FAILURE'

export const PROJECT_SUMMARY_GET_REQUEST = 'PROJECT_SUMMARY_GET_REQUEST'
export const PROJECT_SUMMARY_GET_SUCCESS = 'PROJECT_SUMMARY_GET_SUCCESS'
export const PROJECT_SUMMARY_GET_FAILURE = 'PROJECT_SUMMARY_GET_FAILURE'

export const PROJECT_SUMMARY_SYNC_REQUEST = 'PROJECT_SUMMARY_SYNC_REQUEST'
export const PROJECT_SUMMARY_SYNC_SUCCESS = 'PROJECT_SUMMARY_SYNC_SUCCESS'
export const PROJECT_SUMMARY_SYNC_FAILURE = 'PROJECT_SUMMARY_SYNC_FAILURE'

export const PROJECT_SUMMARY_POST_REQUEST = 'PROJECT_SUMMARY_POST_REQUEST'
export const PROJECT_SUMMARY_POST_SUCCESS = 'PROJECT_SUMMARY_POST_SUCCESS'
export const PROJECT_SUMMARY_POST_FAILURE = 'PROJECT_SUMMARY_POST_FAILURE'

export const PROJECT_SUMMARY_PUBLISH_REQUEST = 'PROJECT_SUMMARY_PUBLISH_REQUEST'
export const PROJECT_SUMMARY_PUBLISH_SUCCESS = 'PROJECT_SUMMARY_PUBLISH_SUCCESS'
export const PROJECT_SUMMARY_PUBLISH_FAILURE = 'PROJECT_SUMMARY_PUBLISH_FAILURE'

export const PROJECT_PRODUCTIVE_GET_REQUEST = 'PROJECT_PRODUCTIVE_GET_REQUEST'
export const PROJECT_PRODUCTIVE_GET_SUCCESS = 'PROJECT_PRODUCTIVE_GET_SUCCESS'
export const PROJECT_PRODUCTIVE_GET_FAILURE = 'PROJECT_PRODUCTIVE_GET_FAILURE'

export const PROJECT_PLANNED_GET_REQUEST = 'PROJECT_PLANNED_GET_REQUEST'
export const PROJECT_PLANNED_GET_SUCCESS = 'PROJECT_PLANNED_GET_SUCCESS'
export const PROJECT_PLANNED_GET_FAILURE = 'PROJECT_PLANNED_GET_FAILURE'

export const PROJECT_RESERVES_GET_REQUEST = 'PROJECT_RESERVES_GET_REQUEST'
export const PROJECT_RESERVES_GET_SUCCESS = 'PROJECT_RESERVES_GET_SUCCESS'
export const PROJECT_RESERVES_GET_FAILURE = 'PROJECT_RESERVES_GET_FAILURE'

export const PROJECT_DPI_GET_REQUEST = 'PROJECT_DPI_GET_REQUEST'
export const PROJECT_DPI_GET_SUCCESS = 'PROJECT_DPI_GET_SUCCESS'
export const PROJECT_DPI_GET_FAILURE = 'PROJECT_DPI_GET_FAILURE'

export const PROJECT_SUMMARY_PAY_GET_REQUEST = 'PROJECT_SUMMARY_PAY_GET_REQUEST'
export const PROJECT_SUMMARY_PAY_GET_SUCCESS = 'PROJECT_SUMMARY_PAY_GET_SUCCESS'
export const PROJECT_SUMMARY_PAY_GET_FAILURE = 'PROJECT_SUMMARY_PAY_GET_FAILURE'

export const PROJECT_SUMMARY_RIG_GET_REQUEST = 'PROJECT_SUMMARY_RIG_GET_REQUEST'
export const PROJECT_SUMMARY_RIG_GET_SUCCESS = 'PROJECT_SUMMARY_RIG_GET_SUCCESS'
export const PROJECT_SUMMARY_RIG_GET_FAILURE = 'PROJECT_SUMMARY_RIG_GET_FAILURE'

export const PROJECT_SUMMARY_RESERVES_GET_REQUEST = 'PROJECT_SUMMARY_RESERVES_GET_REQUEST'
export const PROJECT_SUMMARY_RESERVES_GET_SUCCESS = 'PROJECT_SUMMARY_RESERVES_GET_SUCCESS'
export const PROJECT_SUMMARY_RESERVES_GET_FAILURE = 'PROJECT_SUMMARY_RESERVES_GET_FAILURE'

export const GET_DOLLAR_BOE_REQUEST = 'GET_DOLLAR_BOE_REQUEST'
export const GET_DOLLAR_BOE_SUCCESS = 'GET_DOLLAR_BOE_SUCCESS'
export const GET_DOLLAR_BOE_FAILURE = 'GET_DOLLAR_BOE_FAILURE'

export const GET_WELL_DOLLAR_BOE_REQUEST = 'GET_WELL_DOLLAR_BOE_REQUEST'
export const GET_WELL_DOLLAR_BOE_SUCCESS = 'GET_WELL_DOLLAR_BOE_SUCCESS'
export const GET_WELL_DOLLAR_BOE_FAILURE = 'GET_WELL_DOLLAR_BOE_FAILURE'

export const GET_AZURE_TOKEN_REQUEST = 'GET_AZURE_TOKEN_REQUEST'
export const GET_AZURE_TOKEN_SUCCESS = 'GET_AZURE_TOKEN_SUCCESS'
export const GET_AZURE_TOKEN_FAILURE = 'GET_AZURE_TOKEN_FAILURE'

export const GET_AZURE_AUTHEN_SESSION_REQUEST = 'GET_AZURE_AUTHEN_SESSION_REQUEST'
export const GET_AZURE_AUTHEN_SESSION_SUCCESS = 'GET_AZURE_AUTHEN_SESSION_SUCCESS'
export const GET_AZURE_AUTHEN_SESSION_FAILURE = 'GET_AZURE_AUTHEN_SESSION_FAILURE'

export const USER_AUTHORIZE_GET_REQUEST = 'USER_AUTHORIZE_GET_REQUEST'
export const USER_AUTHORIZE_GET_SUCCESS = 'USER_AUTHORIZE_GET_SUCCESS'

export const USER_SET_REDIRECT = 'USER_SET_REDIRECT'