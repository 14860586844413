import RequestController from '../helpers/requestController'
import { delay } from '../helpers'

/**
 * List all dpi template type
 * 
 * @return {Promise}
 */
export const getTemplateType = () => RequestController.get('template/type', { cache: { ignoreCache: false } })

/**
 * List all dpi template
 * 
 * @param {String} id - project id
 * @return {Promise}
 */
export const getTemplateList = () => RequestController.get('template/list')

/**
 * List all dpi template area
 * 
 * @return {Promise}
 */
export const getArea = () => RequestController.get('template/area')

/**
 * Extract price for price template after @see postTemplate
 * 
 * @param {Object} payload - response from @see postTemplate
 * @return {Promise}
 */
export const extractPrice = payload => RequestController.post('price/post', payload)

/**
 * Get default well amount for specific project
 * 
 * @param {String} name - project name
 * @return {Promise}
 */
export const getWellAmount = name => RequestController.get(`project/well/count?name=${name}`)

/**
 * Get setup attributes for specific project
 * 
 * @param {String} name - project name
 * @return {Promise}
 */
export const getProjectSetup = name => RequestController.get(`project/attribute/get?name=${name}`)

/**
 * Update setup attributes for specific project
 * 
 * @param {Object} payload - modified data from @see getProjectSetup
 * @return {Promise}
 */
export const saveProjectSetup = payload => RequestController.post('project/attribute/post', payload).then(payload => delay(1000).then(() => payload))

/**
 * Get general attachment with specific id
 * 
 * @param {String} id - id of attachment
 * @return {Promise}
 */
export const getAttachment = id => RequestController.get(`attachment/get?id=${id}`)

/**
 * Upload file
 * 
 * @param {Object} payload - should include these properties `name` `size` `value` `extension`
 * @return {Promise}
 */
export const postAttachment = payload => RequestController.post('attachment/post', payload)

/**
 * Post a new template
 * 
 * @param {Object} payload - should include these properties `templateTypeId` `attachmentId` `name`
 * @return {Promise}
 */
export const postTemplate = payload => RequestController.post('template/post', payload)

/**
 * List all time/cost library
 * 
 * @return {Promise}
 */
export const getTimeCostList = () => RequestController.get('resource/library/list')

/**
 * Post a new time/cost library record
 * 
 * @param {Object} payload - should include these properties `name` `type` `value` `status`
 * @return {Promise}
 */
export const postTimeCost = payload => RequestController.post('resource/library/post', payload)

/**
 * Update time/cost library record
 * 
 * @param {Object} payload - modified data from @see getTimeCostList
 * @return {Promise}
 */
export const putTimeCost = payload => RequestController.post('resource/library/put', payload)

/**
 * List all cost structure setup record
 * 
 * @return {Promise}
 */
export const getCostStructureList = () => RequestController.get('resource/list')

/**
 * Post a new cost structure setup record
 * 
 * @param {Object} payload - should include these properties `name` `status`
 * @return {Promise}
 */
export const postCostStructure = payload => RequestController.post('resource/post', payload)

/**
 * Save a cost structure setup record
 * 
 * @param {Object} payload - modified data from @see postCostStructure
 * @return {Promise}
 */
export const putCostStructure = payload => RequestController.post('resource/put', payload)

/**
 * List all cost detail list in cost structure setup
 * 
 * @return {Promise}
 */
export const getCostDetailsList = () => RequestController.get('resource/task/list')

/**
 * Get cost detail for specific id
 * 
 * @param {String} id - cost detail id from @see getCostDetailsList
 * @return {Promise}
 */
export const getCostDetails = id => RequestController.get(`resource/task/list?id=${id}`)

/**
 * Save a cost structure setup record
 * 
 * @param {Object} payload - should include these properties `resourceId` `libraryId`
 * @return {Promise}
 */
export const postCostDetails = payload => RequestController.post('resource/task/post', payload)

/**
 * Update a cost structure setup record
 * 
 * @param {Object} payload - should include these properties `resourceId` `libraryId`
 * @return {Promise}
 */
export const putCostDetails = payload => RequestController.post('resource/task/put', payload)

/**
 * Delete a cost structure setup record
 * 
 * @param {String} id - cost detail id from @see getCostDetailsList
 * @return {Promise}
 */
export const deleteCostDetails = id => RequestController.post(`resource/task/delete?id=${id}`)

/**
 * Get drill trip rate details for specific record
 * 
 * @param {String} id - template id from @see getTemplateList
 * @return {Promise}
 */
export const getDrillTripRateDetails = id => RequestController.get(`rig/rate/list?id=${id}`)

/**
 * Extract drill trip rate for price template after @see postTemplate
 * 
 * @param {Object} payload - response from @see postTemplate
 * @return {Promise}
 */
export const extractDrillTripRate = payload => RequestController.post('rig/post', payload)

/**
 * List all well decision predefined scenarios
 * 
 * @return {Promise}
 */
export const getPredefinedScenarioList = () => RequestController.get('well/scenario/list')

/**
 * Post a new well decision predefined scenario
 * 
 * @param {Object} payload - response from @see getPredefinedScenarioList
 * @return {Promise}
 */
export const postPredefinedScenario = payload => RequestController.post('well/scenario/post', payload)

/**
 * Update well decision predefined scenario
 * 
 * @param {Object} payload - response from @see getPredefinedScenarioList
 * @return {Promise}
 */
export const putPredefinedScenario = payload => RequestController.post('well/scenario/put', payload)

/**
 * @deprecated
 * @return {Promise}
 */
export const getTangibleList = () => RequestController.get('resource/tangible/list')

/**
 * @deprecated
 * @return {Promise}
 */
export const postTangible = payload => RequestController.post('resource/library/post', payload)

/**
 * @deprecated
 * @return {Promise}
 */
export const putTangible = payload => RequestController.post('resource/library/put', payload)

/**
 * @deprecated
 * @return {Promise}
 */
export const getOpexList = () => RequestController.get('resource/opex/list')

/**
 * @deprecated
 * @return {Promise}
 */
export const postOpex = payload => RequestController.post('resource/library/post', payload)

/**
 * @deprecated
 * @return {Promise}
 */
export const putOpex = payload => RequestController.post('resource/library/put', payload)

/**
 * List all production profile generator applicable model `gas` `oil` `seperate`
 * 
 * @return {Promise}
 */
export const getProductionApplicable = () => RequestController.get('production/model/applicable', { cache: { ignoreCache: false } })

/**
 * List all production profile generator records
 * 
 * @return {Promise}
 */
export const getProductionAreaList = () => RequestController.get('area/list')

/**
 * List all production profile generator records
 * 
 * @return {Promise}
 */
export const getProductionList = () => RequestController.get('production/list?status=ACTIVE')

/**
 * Post a new production profile generator record
 * 
 * @param {Object} payload - Same as @see getProductionList
 * @return {Promise}
 */
export const postProduction = payload => RequestController.post('production/post', payload)

/**
 * Validate project dpi setup template before upload, should be used before @see postAttachment
 * 
 * @param {Object} payload - Same as @see postAttachment
 * @return {Promise}
 */
export const projectDpiValidate = payload => RequestController.post('project/dpi/validate', payload)

/**
 * Validate price template before upload, should be used before @see postAttachment
 * 
 * @param {Object} payload - Same as @see postAttachment
 * @return {Promise}
 */
export const priceValidate = payload => RequestController.post('price/template/validate', payload)

/**
 * Validate rig drill trip rate template before upload, should be used before @see postAttachment
 * 
 * @param {Object} payload - Same as @see postAttachment
 * @return {Promise}
 */
export const projectRigTemplateValidate = payload => RequestController.post('rig/template/validate', payload)

/**
 * @deprecated
 * @return {Promise}
 */
export const wellDpiTemplateValidate = payload => RequestController.post('well/dpi/template/validate', payload)

/**
 * Validate dpi template before upload, should be used before @see postAttachment
 * 
 * @param {Object} payload - Same as @see postAttachment
 * @return {Promise}
 */
export const projectDpiTemplateValidate = payload => RequestController.post('project/dpi/template/validate', payload)


/**
 * Validate DollarBoE template before upload, should be used before @see postAttachment
 * 
 * @param {Object} payload - Same as @see postAttachment
 * @return {Promise}
 */
export const dollarBoETemplateValidate = payload => RequestController.post('project/dollarboe/template/validate', payload)

/**
 * Manage the Dollar BoE input
 * 
 * @param {Object} payload - Same as @see postAttachment
 * @return {Promise}
 */

export const uploadDollarBoE = payload => RequestController.post('project/dollarboe/template/upload', payload)

/**
 * Get Dollar BOE Info
 * 
 * @param {Object} payload - Same as @see postAttachment
 * @return {Promise}
 */

export const getDollarBoE = name => RequestController.get(`project/dollarboe/get?projectName=${name}`)

/**
 * Get project setup dpi
 * 
 * @param {String} name - project name
 * @return {Promise}
 */
export const getRecentDpi = name => RequestController.get(`project/dpi/recent?name=${name}`)

/**
 * @deprecated
 * @return {Promise}
 */
export const getProjectDpiList = () => RequestController.get('project/dpi/list')

/**
 * Save a new version of project dpi should call publish right after
 * 
 * @param {Object} payload - modified data from @see getRecentDpi
 * @return {Promise}
 */
export const postProjectDpi = payload => RequestController.post('project/dpi/post', payload)

/**
 * Publish a new version of project dpi
 * 
 * @param {String} id - `projectSpaceId` returned from @see postProjectDpi
 * @return {Promise}
 */
export const publishProjectDpi = id => RequestController.post(`project/dpi/publish?id=${id}`)

/**
 * Get attachment for specific dpi template type
 * 
 * @param {String} id - `templateTypeId` returned from @see getRecentDpi
 * @return {Promise}
 */
export const getTemplateAttachment = id => RequestController.get(`/template/attach?id=${id}`)