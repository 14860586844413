

import * as ColorHelper from './color'
import * as StringHelper from './string'

let Handsontable
if (process.env.NODE_ENV !== 'test') {
    Handsontable = require('handsontable').default
}

export const defaultTextColor = ColorHelper.grey800
export const defaultBackGroundColor = ColorHelper.yellow50
export const defaultHeaderTextColor = ColorHelper.grey800
export const defaultHeaderBackGroudColor = ColorHelper.grey200
export const cellDisableColor = ColorHelper.grey200
export const defaultCellWidth = 100
export const gasTypes = ['GAS', 'GWC', 'GAS AZI', 'GWC AZI']
export const oilTypes = ['OIL', 'OWC', 'Oil AZI', 'OWC AZI']
export const gocTypes = ['GOC', 'GOW', 'GOC AZI', 'GOW AZI']
export const payClassificationYellow = ['PDVA']
export const payClassificationGrey = ['New', 'Accelerated', 'Depleted']
export const payCalculationType = ['CURRENT', 'FREEZ']

export const convertToFunction = (input, defaultValue) => {
    if (typeof input !== 'function') return () => (input || defaultValue)
    return input
}

export const defineDefaultSandProp = (sands = []) => (
    Object.freeze(sands.map(sand => {
        const cellColor = (() => {
            if (sand.readOnly === true) return () => cellDisableColor
            return convertToFunction(sand.cellColor, defaultBackGroundColor)
        })()
        return {
            ...sand,
            data: sand.key,
            defaultValue: sand.defaultValue || null,
            width: sand.width || defaultCellWidth,
            cellColor,
            textColor: convertToFunction(sand.textColor, defaultTextColor),
            headerCellColor: convertToFunction(sand.headerCellColor, defaultHeaderBackGroudColor),
            headerTextColor: convertToFunction(sand.headerTextColor, defaultHeaderTextColor),
            readOnly: convertToFunction(sand.readOnly, false),
            setValueBeforeRender: sand.setValueBeforeRender || undefined,
            setValueAfterChange: sand.setValueAfterChange || undefined,
            source: sand.source || [],
            hiddenColumn: sand.hiddenColumn || false,
            trimWhitespace: sand.trimWhitespace && true,
            strict: sand.strict || undefined,
            allowInvalid: sand.allowInvalid && true,
            filteringCaseSensitive: sand.filteringCaseSensitive && true,
            trimDropdown: sand.trimDropdown && true,
            withToolTip: sand.withToolTip || false,
        }
    }))
)

export const readOnlyFELR = (well = {}) => well.datasource === 'FIN_FELR'

export const readOnlyForTypes = (types = []) => (indexedColumns = {}, values = []) => {
    const index = indexedColumns['openWorksContactCategory']
    return !StringHelper.isInArrayIgnoreCase(types.concat(gocTypes), values[index])
}

export const cellColorForTypes = (types = []) => (indexedColumns = {}, values = []) => {
    const index = indexedColumns['openWorksContactCategory']
    if (StringHelper.isInArrayIgnoreCase(types.concat(gocTypes), values[index])) {
        return ColorHelper.yellow50
    } else {
        return cellDisableColor
    }
}

export const indexColumns = (columns = []) => {
    const result = {}
    columns.forEach((column, index) => result[column.key] = index)
    return result
}

export const transformHeaderColspan = (header = []) => {

    const getDiffColspan = (currentHeader, previousHeader) => {
        const { originIndex, transformedIndex, colspan } = previousHeader
        return (currentHeader.originIndex - (originIndex + colspan)) + transformedIndex + 1
    }

    const sumColspan = array => {
        const result = []
        array.forEach((sand, index) => {
            if (sand.parent) {
                const obj = result.find(item => item.label === sand.parent) || { originIndex: index, colspan: 0, label: sand.parent }
                if (obj.colspan === 0) {
                    result.push(obj)
                }
                obj.colspan++
            }
        })
        return result
    }

    const sortByOriginalIndex = (a, b) => a.originIndex - b.originIndex

    const transformIndex = array => {
        const result = [...array]
        result.forEach((current, index) => {
            current.transformedIndex = current.originIndex
            const previous = result[index - 1]
            if (previous) {
                current.transformedIndex = getDiffColspan(current, previous)
            }
        })
        return result
    }

    return transformIndex(sumColspan(header).sort(sortByOriginalIndex))
}

const getDataAtCell = (instance, row) => key => instance.getDataAtRowProp(row, key)
const setDataAtCell = (instance, row) => (key, value) => instance.setDataAtRowProp(row, key, value)
const getDataHandler = (data = []) => ({
    getDataAtRowProp: (row, col) => (data[row][col]),
    setDataAtRowProp: (row, col, value) => (data[row][col] = value),
})

/// Find source
const findSource = (lookUp = () => { }) => () => (query = '', resolve = () => { }) => {
    const result = lookUp(query) || [query]
    resolve(result)
}

/**
 * Find dropdown options for analogyGasAreaDiscountFactor
 * @param {*} lookUp 
 */
const findSourceAGADF = (lookUp = () => { }) => (get = () => { }) => (query = '', resolve = () => { }) => {
    const analogyGasArea = get('analogyGasAreaName')
    const result = lookUp(analogyGasArea, query) || [query]
    resolve(result)
}

/**
 * Find dropdown options for analogyOilAreaDiscountFactor
 * @param {*} lookUp 
 */
const findSourceAOADF = (lookUp = () => { }) => (get = () => { }) => (query = '', resolve = () => { }) => {
    const analogyOilArea = get('analogyOilAreaName')
    const result = lookUp(analogyOilArea, query) || [query]
    resolve(result)
}

/// Set default value helpers
const setDefaultOWCC = (get = () => { }, set = () => { }, defaultAnalogy = {}, projectAttributes = {}, dropdowns = {}) => {
    const selected = get('openWorksContactCategory')
    let type = 'none'
    if (StringHelper.isInArrayIgnoreCase(gocTypes, selected)) {
        type = 'both'
    } else if (StringHelper.isInArrayIgnoreCase(gasTypes, selected)) {
        type = 'gas'
    } else if (StringHelper.isInArrayIgnoreCase(oilTypes, selected)) {
        type = 'oil'
    }

    const hasDefaultGas = !!(defaultAnalogy && defaultAnalogy.gasAnalogyDataSetID && defaultAnalogy.gasAnalogyDataSetName &&
        defaultAnalogy.gasAnalogyScenarioID && defaultAnalogy.gasAnalogyScenarioName)

    const hasDefaultOil = !!(defaultAnalogy && defaultAnalogy.oilAnalogyDataSetID && defaultAnalogy.oilAnalogyDataSetName &&
        defaultAnalogy.oilAnalogyScenarioID && defaultAnalogy.oilAnalogyScenarioName)

    const hasDefaultBg = !!(projectAttributes && projectAttributes.bgProfileId)
    const hasDefaultBo = !!(projectAttributes && projectAttributes.boProfileId)

    const setDefaultGas = () => {
        if (hasDefaultGas && StringHelper.isValueNullOrEmpty(get('analogyGasAreaName')) &&
            StringHelper.isValueNullOrEmpty(get('analogyGasAreaDiscountFactor'))) {
            set('analogyGasAreaName', defaultAnalogy.gasAnalogyDataSetName)
            set('analogyGasAreaDiscountFactor', defaultAnalogy.gasAnalogyScenarioName)
            set('analogyGasAreaSetId', defaultAnalogy.gasAnalogyDataSetID)
            set('analogyGasAreaScenarioId', defaultAnalogy.gasAnalogyScenarioID)
        }

        if (hasDefaultBg && StringHelper.isValueNullOrEmpty(get('fvfProfileBgProfile'))) {
            const fvfProfileBgProfile = dropdowns.findBgProfileById(projectAttributes.bgProfileId)
            if (fvfProfileBgProfile) {
                set('fvfProfileBgProfile', fvfProfileBgProfile.fvfProfileName)
            } else {
                set('fvfProfileBgProfile', '')
            }
        }
    }

    const setDefaultOil = () => {
        if (hasDefaultOil && StringHelper.isValueNullOrEmpty(get('analogyOilAreaName')) &&
            StringHelper.isValueNullOrEmpty(get('analogyOilAreaDiscountFactor'))) {
            set('analogyOilAreaName', defaultAnalogy.oilAnalogyDataSetName)
            set('analogyOilAreaDiscountFactor', defaultAnalogy.oilAnalogyScenarioName)
            set('analogyOilAreaSetId', defaultAnalogy.oilAnalogyDataSetID)
            set('analogyOilAreaScenarioId', defaultAnalogy.oilAnalogyScenarioID)
        }

        if (hasDefaultBo && StringHelper.isValueNullOrEmpty(get('fvfProfileBoProfile'))) {
            const fvfProfileBoProfile = dropdowns.findBoProfileById(projectAttributes.boProfileId)
            if (fvfProfileBoProfile) {
                set('fvfProfileBoProfile', fvfProfileBoProfile.fvfProfileName)
            } else {
                set('fvfProfileBoProfile', '')
            }
        }
    }

    if (type === 'both') {
        setDefaultGas()
        setDefaultOil()
    } else if (type === 'gas') {
        setDefaultGas()
        set('fvfProfileBoProfile')
        set('analogyOilAreaName')
    } else if (type === 'oil') {
        setDefaultOil()
        set('fvfProfileBgProfile')
        set('analogyGasAreaName')
    } else {
        set('fvfProfileBoProfile')
        set('analogyOilAreaName')
        set('fvfProfileBgProfile')
        set('analogyGasAreaName')
    }
}

/// Set default value before render
const setValueBeforeRenderBoProfile = (lookUp = () => { }) => (get = () => { }, set = () => { }, readOnly = false) => {
    if (!readOnly) {
        let boProfileLabel

        const selected = get('fvfProfileBoProfileId')
        const matched = lookUp(selected)

        if (matched) {
            boProfileLabel = matched.fvfProfileName
        } else {
            set('fvfProfileBoProfileId', selected)
        }

        set('fvfProfileBoProfile', boProfileLabel)
    }
}

const setValueBeforeRenderBgProfile = (lookUp = () => { }) => (get = () => { }, set = () => { }, readOnly = false) => {
    if (!readOnly) {
        let bgProfileLabel

        const selected = get('fvfProfileBgProfileId')
        const matched = lookUp(selected)

        if (matched) {
            bgProfileLabel = matched.fvfProfileName
            set('fvfProfileBgProfile', bgProfileLabel)
        } else {
            set('fvfProfileBgProfileId', '')
        }


    }
}

const setValueBeforeRenderAnalogyOilAreaName = (lookUp = () => { }) => (get = () => { }, set = () => { }, readOnly = false) => {
    if (!readOnly) {
        let areaNameLabel

        const selected = get('analogyOilAreaSetId')
        const matched = lookUp(selected)

        if (matched) {
            areaNameLabel = matched.analogyDataSetName
            set('analogyOilAreaName', areaNameLabel)
        } else {
            set('analogyOilAreaSetId', selected)
        }

    }
}

const setValueBeforeRenderAnalogyGasAreaName = (lookUp = () => { }) => (get = () => { }, set = () => { }, readOnly = false) => {
    if (!readOnly) {
        let areaNameLabel

        const selected = get('analogyGasAreaSetId')
        const matched = lookUp(selected)

        if (matched) {
            areaNameLabel = matched.analogyDataSetName
            set('analogyGasAreaName', areaNameLabel)
        } else {
            set('analogyGasAreaSetId', selected)
        }


    }
}

const setValueBeforeRenderAOADF = (lookUp = () => { }) => (get = () => { }, set = () => { }, readOnly = false) => {
    if (!readOnly) {
        let areaDiscountFactorLabel
        let analogyArea

        const analogyAreaId = get('analogyOilAreaSetId')
        const analogyScenarioId = get('analogyOilAreaScenarioId')

        const matched = lookUp(analogyAreaId, analogyScenarioId)

        if (matched) {
            areaDiscountFactorLabel = matched.analogyScenarioName
            analogyArea = matched.oilArea
            set('analogyOilAreaDiscountFactor', areaDiscountFactorLabel)
            set('analogyOilArea', analogyArea)

        } else {
            set('analogyOilAreaScenarioId', analogyScenarioId)
        }


    }
}

const setValueBeforeRenderAGADF = (lookUp = () => { }) => (get = () => { }, set = () => { }, readOnly = false) => {
    if (!readOnly) {
        let areaDiscountFactorLabel
        let analogyArea

        const analogyAreaId = get('analogyGasAreaSetId')
        const analogyScenarioId = get('analogyGasAreaScenarioId')

        const matched = lookUp(analogyAreaId, analogyScenarioId)

        if (matched) {
            areaDiscountFactorLabel = matched.analogyScenarioName
            analogyArea = matched.gasArea
        } else {
            set('analogyGasAreaScenarioId', analogyScenarioId)
        }

        set('analogyGasAreaDiscountFactor', areaDiscountFactorLabel)
        set('analogyGasArea', analogyArea)
    }
}

const setValueBeforeRenderOWCC = (get = () => { }, set = () => { }) => {
    setValueAfterChangeAzi(get, set)
}

/// Set default value after change
const setValueAfterChangeOWCC = (defaultAnalogy = {}, projectAttributes = {}, dropdowns = {}) => (get = () => { }, set = () => { }) => {
    setDefaultOWCC(get, set, defaultAnalogy, projectAttributes, dropdowns)
    setValueAfterChangeAzi(get, set)
}

const setValueAfterChangeBoProfile = (lookUp = () => { }) => (get = () => { }, set = () => { }) => {
    let boProfileId
    const selectedOpenwork = get('openWorksContactCategory')
    const selected = get('fvfProfileBoProfile')
    if (StringHelper.isInArrayIgnoreCase(gocTypes, selectedOpenwork) || StringHelper.isInArrayIgnoreCase(oilTypes, selectedOpenwork)) {
        const matched = lookUp(selected)

        if (matched) {
            boProfileId = matched.fvfProfileID
        }
    } else if (!StringHelper.isValueNullOrEmpty(selected)) {
        set('fvfProfileBoProfile', selected)
    }

    set('fvfProfileBoProfileId', boProfileId)
    set('fvfProfileBoValue', selected)
}

const setValueAfterChangeBgProfile = (lookUp = () => { }) => (get = () => { }, set = () => { }) => {
    let bgProfileId
    const selectedOpenwork = get('openWorksContactCategory')
    const selected = get('fvfProfileBgProfile')
    if (StringHelper.isInArrayIgnoreCase(gocTypes, selectedOpenwork) || StringHelper.isInArrayIgnoreCase(gasTypes, selectedOpenwork)) {
        const matched = lookUp(selected)

        if (matched) {
            bgProfileId = matched.fvfProfileID
        }
    } else if (!StringHelper.isValueNullOrEmpty(selected)) {
        set('fvfProfileBgProfile', selected)
    }

    set('fvfProfileBgProfileId', bgProfileId)
    set('fvfProfileBgValue', selected)
}

const setValueAfterChangeAnalogyOilAreaName = (lookUpArea = () => { }, lookUpScenario = () => { }) => (get = () => { }, set = () => { }) => {
    let analogyDataSetId
    const selectedOpenwork = get('openWorksContactCategory')
    const selected = get('analogyOilAreaName')
    if (StringHelper.isInArrayIgnoreCase(gocTypes, selectedOpenwork) || StringHelper.isInArrayIgnoreCase(oilTypes, selectedOpenwork)) {
        const matched = lookUpArea(selected)

        if (matched) {
            analogyDataSetId = matched.analogyDataSetID
        }
        set('analogyOilAreaSetId', analogyDataSetId)
    } else if (!StringHelper.isValueNullOrEmpty(selected)) {
        set('analogyOilAreaName', selected)
    }

    setValueAfterChangeAOADF(lookUpScenario)(get, set)
}

const setValueAfterChangeAnalogyGasAreaName = (lookUpArea = () => { }, lookUpScenario = () => { }) => (get = () => { }, set = () => { }) => {
    let analogyDataSetId
    const selectedOpenwork = get('openWorksContactCategory')
    const selected = get('analogyGasAreaName')
    if (StringHelper.isInArrayIgnoreCase(gocTypes, selectedOpenwork) || StringHelper.isInArrayIgnoreCase(gasTypes, selectedOpenwork)) {
        const matched = lookUpArea(selected)

        if (matched) {
            analogyDataSetId = matched.analogyDataSetID
        }

        set('analogyGasAreaSetId', analogyDataSetId)
    } else if (!StringHelper.isValueNullOrEmpty(selected)) {
        set('analogyGasAreaName', selected)
    }
    setValueAfterChangeAGADF(lookUpScenario)(get, set)
}

const setValueAfterChangeAOADF = (lookUp = () => { }) => (get = () => { }, set = () => { }) => {
    let analogyScenarioID
    let analogyArea
    const selectedOpenwork = get('openWorksContactCategory')
    const analogyScenario = get('analogyOilAreaDiscountFactor')
    if (StringHelper.isInArrayIgnoreCase(gocTypes, selectedOpenwork) || StringHelper.isInArrayIgnoreCase(oilTypes, selectedOpenwork)) {
        const analogyAreaName = get('analogyOilAreaName')
        const matched = lookUp(analogyAreaName, analogyScenario)

        if (matched) {
            analogyScenarioID = matched.analogyScenarioID
            analogyArea = matched.oilArea
            set('analogyOilAreaScenarioId', analogyScenarioID)
            set('analogyOilArea', analogyArea)
        }
    } else if (!StringHelper.isValueNullOrEmpty(analogyScenario)) {
        set('analogyOilAreaDiscountFactor', analogyScenario)
    }


}

const setValueAfterChangeAGADF = (lookUp = () => { }) => (get = () => { }, set = () => { }) => {
    let analogyScenarioID
    let analogyArea
    const selectedOpenwork = get('openWorksContactCategory')
    const analogyScenario = get('analogyGasAreaDiscountFactor')
    if (StringHelper.isInArrayIgnoreCase(gocTypes, selectedOpenwork) || StringHelper.isInArrayIgnoreCase(gasTypes, selectedOpenwork)) {
        const analogyAreaName = get('analogyGasAreaName')
        const matched = lookUp(analogyAreaName, analogyScenario)

        if (matched) {
            analogyScenarioID = matched.analogyScenarioID
            analogyArea = matched.gasArea
        }
    } else if (!StringHelper.isValueNullOrEmpty(analogyScenario)) {
        set('analogyGasAreaDiscountFactor', analogyScenario)
    }

    set('analogyGasAreaScenarioId', analogyScenarioID)
    set('analogyGasArea', analogyArea)
}

const setValueAfterChangePos = (defaultValue) => (get = () => { }, set = () => { }) => {
    const data = get('pos')
    if (data < 0 || data > 1) {
        set('pos', defaultValue)
    }
}

const setValueAfterChangeAzi = (get = () => { }, set = () => { }) => {
    const selected = get('openWorksContactCategory')
    const sandReserveCategory = selected ? selected.match(/azi/i) ? 'P3DN' : 'P1DN' : ''
    set('sandReserveCategory', sandReserveCategory)
}

const setValueAfterChangePayClassification = (get = () => { }, set = () => { }) => {
    setValueAfterChangePPG(get, set)
}

const setValueBeforeRenderMBOE = (get = () => { }, set = () => { }) => {
    set('totalMBOE', get('hcLiquidWithBC') + get('hcGasWithBC'))
}

export const ppgToPsi = (ppg = 0, toptvdss = 0) => (ppg * 0.052 * toptvdss * -1)

const setValueAfterChangePPG = (get = () => { }, set = () => { }) => {
    const selected = get('payClassification')
    const pressureRFTInPPG = get('pressureRFTInPPG')
    const pressureEstimatedInitialInPPG = get('pressureEstimatedInitialInPPG')
    if (((selected && selected.toLowerCase().trim() !== 'pdva') || !selected) && (!StringHelper.isValueNullOrEmpty(pressureRFTInPPG) || !StringHelper.isValueNullOrEmpty(pressureEstimatedInitialInPPG))) {
        set('pressureRFTInPPG', pressureRFTInPPG)
        set('pressureEstimatedInitialInPPG', pressureEstimatedInitialInPPG)
    } else {
        const topTVDSS = parseFloat(get('topTVDSS'))
        const pressureRFTInPPGVal = parseFloat(pressureRFTInPPG)
        const pressureEstimatedInitialInPPGVal = parseFloat(pressureEstimatedInitialInPPG)
        const pressureRFTInPSI = ppgToPsi(pressureRFTInPPGVal, topTVDSS)
        const pressureEstimatedInitialInPSI = ppgToPsi(pressureEstimatedInitialInPPGVal, topTVDSS)
        set('pressureRFTInPSI', pressureRFTInPSI || undefined)
        set('pressureEstimatedInitialInPSI', pressureEstimatedInitialInPSI || undefined)
        set('pressurePPi', (pressureRFTInPSI / pressureEstimatedInitialInPSI) || undefined)
    }
}

export const sandProperties = ({
    well = {}, readOnly = true,
    dropdowns = {}, defaultAnalogy = {},
    projectAttributes = {}
}) => defineDefaultSandProp([
    {
        key: 'usi',
        name: 'Sand USI',
        type: 'text',
        showInCollapse: true,
        width: 200,
        readOnly: true,
        hiddenColumn: true,
    },
    {
        key: 'pos',
        name: 'PoS',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        cellColor: () => ColorHelper.yellow50,
        defaultValue: 1,
        setValueAfterChange: setValueAfterChangePos(1),
        showInCollapse: true,
        width: 80,
    },
    {
        key: 'name',
        name: 'Sand Name',
        type: 'text',
        showInCollapse: true,
        readOnly: true,
        width: 100,
        defaultSort: true,
    },
    {
        key: 'openWorksContactCategory',
        name: 'OpenWorks<br/>Contact<br/>Category',
        type: 'autocomplete',
        // type: 'text',
        // editor: 'select',
        textColor: (indexedColumns = [], value = []) => {
            const index = indexedColumns['openWorksContactCategory']
            if (StringHelper.isInArrayIgnoreCase(gasTypes, value[index])) {
                return ColorHelper.red800
            } else if (StringHelper.isInArrayIgnoreCase(oilTypes, value[index])) {
                return ColorHelper.green500
            } else if (StringHelper.isInArrayIgnoreCase(gocTypes, value[index])) {
                return ColorHelper.amber800
            } else {
                return defaultTextColor
            }
        },
        source: dropdowns.findOpenworkCategoryLabels,
        selectOptions: dropdowns.findOpenworkCategoryLabels,
        setValueAfterChange: setValueAfterChangeOWCC(defaultAnalogy, projectAttributes, dropdowns),
        setValueBeforeRender: setValueBeforeRenderOWCC,
        trimWhitespace: false,
        strict: true,
        allowInvalid: true,
        filteringCaseSensitive: false,
        showInCollapse: true,
        width: 100,
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'sandReserveCategory',
        name: 'Sand<br/>Reserves<br/>Category',
        type: 'text',
        width: 100,
        showInCollapse: true,
        readOnly: true,
    },
    {
        key: 'fvfProfileBoProfileId',
        name: 'Bo Profile Id',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        setValueBeforeRender: setValueBeforeRenderBoProfile(dropdowns.findBoProfileById),
        hiddenColumn: true,
        readOnly: true,
    },
    {
        key: 'fvfProfileBgProfileId',
        name: 'Bg Profile Id',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        setValueBeforeRender: setValueBeforeRenderBgProfile(dropdowns.findBgProfileById),
        hiddenColumn: true,
        readOnly: true,
    },
    {
        key: 'analogyOilAreaSetId',
        name: 'Oil Area<br/> Id',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        setValueBeforeRender: setValueBeforeRenderAnalogyOilAreaName(dropdowns.findAreaNameById),
        hiddenColumn: true,
        readOnly: true,
    },
    {
        key: 'analogyOilAreaScenarioId',
        name: 'Oil Area<br/>Discount Factor Id',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        setValueBeforeRender: setValueBeforeRenderAOADF(dropdowns.findAreaDiscountFactorById),
        hiddenColumn: true,
        readOnly: true,
    },
    {
        key: 'analogyGasAreaSetId',
        name: 'Gas Area<br/> Id',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        setValueBeforeRender: setValueBeforeRenderAnalogyGasAreaName(dropdowns.findAreaNameById),
        hiddenColumn: true,
        readOnly: true,
    },
    {
        key: 'analogyGasAreaScenarioId',
        name: 'Gas Area<br/>Discount Factor Id',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        setValueBeforeRender: setValueBeforeRenderAGADF(dropdowns.findAreaDiscountFactorById),
        hiddenColumn: true,
        readOnly: true,
    },
    {
        key: 'totalMBOE',
        name: 'Total MBOE',
        type: 'numeric',
        setValueBeforeRender: setValueBeforeRenderMBOE,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
        showInCollapse: true,
    },
    {
        key: 'hcLiquidWithBC',
        name: 'HC liquid<br/>w/ BC<br/>(MSTB)',
        type: 'numeric',
        headerTextColor: () => ColorHelper.blue800,
        headerCellColor: () => ColorHelper.lightGreen100,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
        showInCollapse: true,
    },
    {
        key: 'oilP1DNWithoutBC',
        name: 'Oil Volume<br/>w/o BC<br/>(MSTB)',
        type: 'numeric',
        headerTextColor: () => ColorHelper.blue800,
        headerCellColor: () => ColorHelper.lightGreen100,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
    },
    {
        key: 'oilP1DNWithBC',
        name: 'Oil Volume<br/>w/ BC<br/>(MSTB)',
        type: 'numeric',
        headerTextColor: () => ColorHelper.blue800,
        headerCellColor: () => ColorHelper.lightGreen100,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
    },
    {
        key: 'condensateP1DNWithBC',
        name: 'Condensate Volume<br/>w/ BC<br/>(MSTB)',
        type: 'numeric',
        width: 160,
        headerTextColor: () => ColorHelper.blue800,
        headerCellColor: () => ColorHelper.lightGreen100,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
    },
    {
        key: 'hcGasWithBC',
        name: 'HC Gas<br/>w/ BC<br/>(MSTB)',
        type: 'numeric',
        headerTextColor: () => ColorHelper.blue800,
        headerCellColor: () => ColorHelper.red100,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
        showInCollapse: true,
    },
    {
        key: 'freeGasP1DNWithoutBC',
        name: 'Free Gas Volume<br/>w/o BC<br/>(MMSCF)',
        type: 'numeric',
        headerTextColor: () => ColorHelper.blue800,
        headerCellColor: () => ColorHelper.red100,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
    },
    {
        key: 'freeGasP1DNWithBC',
        name: 'Free Gas Volume<br/>w/ BC<br/>(MMSCF)',
        type: 'numeric',
        headerTextColor: () => ColorHelper.blue800,
        headerCellColor: () => ColorHelper.red100,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
    },
    {
        key: 'solutionGasP1DNWithBC',
        name: 'Solution Gas Volume<br/>w/ BC<br/>(MMSCF)',
        type: 'numeric',
        headerTextColor: () => ColorHelper.blue800,
        headerCellColor: () => ColorHelper.red100,
        width: 160,
        numericFormat: {
            pattern: '0.000',
        },
        readOnly: true,
    },
    {
        key: 'payClassification',
        name: 'Pay<br/>Classification',
        type: 'autocomplete',
        source: dropdowns.findPayClassificationLabels,
        setValueAfterChange: setValueAfterChangePayClassification,
        trimWhitespace: false,
        strict: true,
        allowInvalid: false,
        showInCollapse: true,
    },
    {
        key: 'pressureRFTInPSI',
        parent: 'Pressure',
        name: 'RFT (psig)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        readOnly: true,
    },
    {
        key: 'pressureRFTInPPG',
        parent: 'Pressure',
        name: 'RFT (PPG)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        setValueAfterChange: setValueAfterChangePPG,
        readOnly: (indexedColumns, values) => {
            const index = indexedColumns['payClassification']
            return payClassificationGrey?.includes(values[index])
        },
        cellColor: (indexedColumns, values) => {
            const index = indexedColumns['payClassification']
            if (payClassificationGrey?.includes(values[index])) return cellDisableColor
            return ColorHelper.yellow50
        },
        showInCollapse: true,
    },
    {
        key: 'pressureEstimatedInitialInPSI',
        parent: 'Pressure',
        name: 'Estimated Initial<br/>(psig)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        readOnly: true,
    },
    {
        key: 'pressureEstimatedInitialInPPG',
        parent: 'Pressure',
        name: 'Estimated Initial<br/>(PPG)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        setValueAfterChange: setValueAfterChangePPG,
        readOnly: (indexedColumns, values) => {
            const index = indexedColumns['payClassification']
            return payClassificationGrey.includes(values[index])
        },
        cellColor: (indexedColumns, values) => {
            const index = indexedColumns['payClassification']
            if (payClassificationGrey.includes(values[index])) return cellDisableColor
            return ColorHelper.yellow50
        },
    },
    {
        key: 'pressurePPi',
        parent: 'Pressure',
        name: 'P/Pi',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'fvfProfileBoProfile',
        parent: 'FVF Profile',
        name: 'Bo Profile',
        type: 'autocomplete',
        cellColor: cellColorForTypes(oilTypes),
        setValueAfterChange: setValueAfterChangeBoProfile(dropdowns.findBoProfile),
        source: findSource(dropdowns.findBoProfileLabels),
        trimWhitespace: false,
        strict: false,
        allowInvalid: true,
        filteringCaseSensitive: false,
        trimDropdown: false,
        withToolTip: true,
    },
    {
        key: 'fvfProfileBgProfile',
        parent: 'FVF Profile',
        name: 'Bg Profile',
        type: 'autocomplete',
        cellColor: cellColorForTypes(gasTypes),
        setValueAfterChange: setValueAfterChangeBgProfile(dropdowns.findBgProfile),
        source: findSource(dropdowns.findBgProfileLabels),
        trimWhitespace: false,
        strict: false,
        allowInvalid: true,
        filteringCaseSensitive: false,
        trimDropdown: false,
        withToolTip: true,
    },
    {
        key: 'fvfProfileBoValue',
        parent: 'FVF Profile',
        name: 'Bo Value',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00000',
        },
        readOnly: true,
    },
    {
        key: 'fvfProfileBgValue',
        parent: 'FVF Profile',
        name: 'Bg Value',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00000',
        },
        readOnly: true,
    },
    {
        key: 'analogyOilAreaName',
        parent: 'Analogy',
        name: 'Oil Area<br/>Name',
        type: 'autocomplete',
        cellColor: cellColorForTypes(oilTypes),
        setValueAfterChange: setValueAfterChangeAnalogyOilAreaName(dropdowns.findAreaName, dropdowns.findAreaDiscountFactor),
        source: findSource(dropdowns.findAreaNameLabels),
        width: 220,
        trimWhitespace: false,
        strict: false,
        allowInvalid: true,
        filteringCaseSensitive: false,
        trimDropdown: false,
        withToolTip: true,
    },
    {
        key: 'analogyOilAreaDiscountFactor',
        parent: 'Analogy',
        name: 'Oil Area<br/>Discount Factor',
        type: 'autocomplete',
        cellColor: cellColorForTypes(oilTypes),
        setValueAfterChange: setValueAfterChangeAOADF(dropdowns.findAreaDiscountFactor),
        source: findSourceAOADF(dropdowns.findAreaDiscountFactorLabels),
        trimWhitespace: false,
        strict: false,
        allowInvalid: true,
        filteringCaseSensitive: false,
        trimDropdown: false,
        withToolTip: true,
    },
    {
        key: 'analogyGasAreaName',
        parent: 'Analogy',
        name: 'Gas Area<br/>Name',
        type: 'autocomplete',
        cellColor: cellColorForTypes(gasTypes),
        setValueAfterChange: setValueAfterChangeAnalogyGasAreaName(dropdowns.findAreaName, dropdowns.findAreaDiscountFactor),
        source: findSource(dropdowns.findAreaNameLabels),
        width: 220,
        trimWhitespace: false,
        strict: false,
        allowInvalid: true,
        filteringCaseSensitive: false,
        trimDropdown: false,
        withToolTip: true,
    },
    {
        key: 'analogyGasAreaDiscountFactor',
        parent: 'Analogy',
        name: 'Gas Area<br/>Discount Factor',
        type: 'autocomplete',
        cellColor: cellColorForTypes(gasTypes),
        setValueAfterChange: setValueAfterChangeAGADF(dropdowns.findAreaDiscountFactor),
        source: findSourceAGADF(dropdowns.findAreaDiscountFactorLabels),
        trimWhitespace: false,
        strict: false,
        allowInvalid: true,
        filteringCaseSensitive: false,
        trimDropdown: false,
        withToolTip: true,
    },
    {
        key: 'analogyOilArea',
        parent: 'Analogy',
        name: 'Current Oil Area<br/>(Acres)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'analogyGasArea',
        parent: 'Analogy',
        name: 'Current Gas Area<br/>(Acres)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'oilAnalogyAreaFreez',
        parent: 'Analogy',
        name: 'Freeze Oil Area <br/>(Acres)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'gasAnalogyAreaFreez',
        parent: 'Analogy',
        name: 'Freeze Gas Area <br/>(Acres)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },

    {
        key: 'calculationType',
        parent: 'Analogy',
        name: 'Calculation Type',
        type: 'autocomplete',
        //cellColor: cellColorForTypes(gasTypes),
        //setValueAfterChange: setValueAfterChangeAGADF(dropdowns.findAreaDiscountFactor),
        source: findSource(dropdowns.findPayCalulationLabels),
        trimWhitespace: false,
        strict: false,
        allowInvalid: true,
        filteringCaseSensitive: false,
        trimDropdown: false,
        withToolTip: true

    },
    {
        key: 'discountFactorOilDepletion',
        parent: 'Discount Factor',
        name: 'Oil Depletion<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },

    {
        key: 'discountFactorGasDepletion',
        parent: 'Discount Factor',
        name: 'Gas Depletion<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'discountFactorMechanical',
        parent: 'Discount Factor',
        name: 'Mechanical<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        defaultValue: 100,
    },
    {
        key: 'discountFactorCementQuality',
        parent: 'Discount Factor',
        name: 'Cement Quality<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        defaultValue: 100,
    },
    {
        key: 'discountFactorCO2',
        parent: 'Discount Factor',
        name: 'CO2<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        defaultValue: 100,
    },
    {
        key: 'discountFactorBC',
        parent: 'Discount Factor',
        name: 'BC<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        defaultValue: 100,
    },
    {
        key: 'inPlaceByVolumetricOOIP',
        parent: 'In-Place by Volumetric',
        name: 'OOIP<br/>(MSTB)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'inPlaceByVolumetricOGIP',
        parent: 'In-Place by Volumetric',
        name: 'OGIP<br/>(MMSCF)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'recoveryEfficiencyOil',
        parent: 'Recovery Efficiency',
        name: 'Oil<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'recoveryEfficiencyGas',
        parent: 'Recovery Efficiency',
        name: 'Gas<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        readOnly: true,
    },
    {
        key: 'grossIntervalFrom',
        parent: 'Gross Interval',
        name: 'From<br/>(MD ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'grossIntervalTo',
        parent: 'Gross Interval',
        name: 'To<br/>(MD ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        readOnly: true,
    },
    {
        key: 'topTVDSS',
        name: 'Top<br/>(TVDSS ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        readOnly: true,
    },
    {
        key: 'gocTVDSS',
        name: 'GOC<br/>(TVDSS ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'hwcTVDSS',
        name: 'HWC<br/>(TVDSS ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'grossSandMT',
        parent: 'Gross Sand',
        name: 'MT (ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        showInCollapse: true,
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'grossSandVT',
        parent: 'Gross Sand',
        name: 'VT (ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        showInCollapse: true,
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'netGasVT',
        name: 'Net Gas<br/>(VT ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        showInCollapse: true,
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'netOilVT',
        name: 'Net Oil<br/>(VT ft)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        showInCollapse: true,
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'maximumResistivity',
        name: 'Maximum<br/>Resistivity',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        showInCollapse: true,
        readOnly: true,
    },
    {
        key: 'vsh',
        name: 'Vsh',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        showInCollapse: true,
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'avgPor',
        name: 'Avg Por<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        showInCollapse: true,
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'avgSw',
        name: 'Avg Sw<br/>(%)',
        type: 'numeric',
        numericFormat: {
            pattern: '0.00',
        },
        showInCollapse: true,
        readOnly: readOnlyFELR(well),
    },
    {
        key: 'tg',
        name: 'TG (U)',
        type: 'numeric',
        numericFormat: {
            pattern: '0',
        },
        showInCollapse: true,
        readOnly: true,
    },
    {
        key: 'remarks',
        name: 'Remarks',
        type: 'text',
        showInCollapse: true,
        readOnly: true,
        withToolTip: true,
    },
])

/// Transform
let changeRemain = 0
const setChangeRemain = (change = 0, onChange = () => { }) => {
    changeRemain += change
    if (changeRemain < 0)
        changeRemain = 0
    onChange(changeRemain)
}

export const transformHeader = ({
    columns = [], allColumns = [], transformedHeaderColspan = [],
    indexedColumns = {}, readOnly, callbacks = {}
}) => ({
    columns,
    allColumns,
    colWidths: columns.map(sand => sand.width),
    nestedHeaders: [
        columns.map((item, index) => {
            return transformedHeaderColspan.find(item => item.transformedIndex === index) || ''
        }),
        columns.map(sand => sand.name)
    ],
    afterGetColHeader(col, th) {
        if (!columns[col]) return
        th.style.background = columns[col].headerCellColor()
        th.style.color = columns[col].headerTextColor()
    },
    cells(row, col) {
        function renderer(instance, td, row, col, prop, value, cellProperties) { //col returns "index"
            if (process.env.NODE_ENV === 'test') return
            if (!columns[col]) return

            const sandProperty = columns[col]
            const _getDataAtCell = getDataAtCell(instance, row)
            const dataAtRow = instance.getDataAtRow(row)
            const dataAtCell = instance.getDataAtCell(row, col)



            //Set display type
            if (sandProperty.type === 'numeric') {
                Handsontable.renderers.NumericRenderer.apply(this, arguments)
            } else if (sandProperty.type === 'autocomplete') {
                if (readOnly) {
                    Handsontable.renderers.TextRenderer.apply(this, arguments)
                } else {
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments)
                }
            } else {
                Handsontable.renderers.TextRenderer.apply(this, arguments)
            }

            //Set html style
            let innerHTML = td.innerHTML
            if (dataAtCell && sandProperty.numericFormat && sandProperty.numericFormat.pattern === '0') {
                innerHTML = (parseFloat(dataAtCell) || 0).toFixed(0)
            }
            td.innerHTML = !!innerHTML ? `<div title="${dataAtCell ? dataAtCell : ''}">${innerHTML}</div>` : innerHTML
            td.style.background = sandProperty.cellColor(indexedColumns, dataAtRow)
            td.style.color = sandProperty.textColor(indexedColumns, dataAtRow)

            //Set cell properties
            cellProperties.readOnly = sandProperty.readOnly(indexedColumns, dataAtRow)
            cellProperties.trimWhitespace = sandProperty.trimWhitespace
            cellProperties.strict = sandProperty.strict
            cellProperties.allowInvalid = sandProperty.allowInvalid
            cellProperties.source = getDropdownOptions(sandProperty.source, _getDataAtCell)
            cellProperties.filteringCaseSensitive = sandProperty.filteringCaseSensitive
            cellProperties.trimDropdown = sandProperty.trimDropdown

            if (readOnly) { //Force ReadOnly Override
                cellProperties.readOnly = readOnly
            }
        }
        return { renderer }
    },
    afterLoadData(initialLoad) {
        getFilteredSummary(this, callbacks)
    },
    afterTrimRow(currentTrimConfig, destinationTrimConfig) {
        getFilteredSummary(this, callbacks)
    },
    afterUntrimRow(currentTrimConfig, destinationTrimConfig) {
        getFilteredSummary(this, callbacks)
    },
    afterSelectionEnd(row, col, row2, col2) {
        getSelectedSummary(this, row, col, row2, col2, columns, callbacks)
    },
    beforeChange(changes, source) {
        if (changes) {
            setChangeRemain(1, callbacks.onChange)
        }
    },
    afterChange(changes, source) {
        if (changes) {
            changes.forEach(([row, col, oldValue, newValue]) => { //col returns "key"
                if (oldValue !== newValue) {
                    const sandProperty = columns[indexedColumns[col]]

                    if (sandProperty) {
                        const _getDataAtCell = getDataAtCell(this, row)
                        const _setDataAtCell = setDataAtCell(this, row)

                        if (sandProperty.defaultValue && StringHelper.isValueNullOrEmpty(_getDataAtCell(sandProperty.key))) {
                            _setDataAtCell(sandProperty.key, sandProperty.defaultValue)
                        }

                        if (sandProperty.setValueAfterChange) {
                            sandProperty.setValueAfterChange(_getDataAtCell, _setDataAtCell)
                        }
                    }
                }
            })

            setChangeRemain(-1, callbacks.onChange)
        }
    },
})

const getDropdownOptions = (source = [], get = () => { }) => {
    if (typeof source === 'function') {
        return source(get)
    } else {
        return source
    }
}

export const getFilteredSummary = (instance, callbacks) => {
    let hcLiquidWithBC = 0
    let hcGasWithBC = 0

    const plugin = instance.getPlugin('trimRows')
    const dataSource = instance.getSourceData().filter((_, row) => !plugin?.trimmedRows?.includes(row))
    const _dataHandler = getDataHandler(dataSource)

    for (let i = 0; i < dataSource.length; i++) {
        const _getDataAtCell = getDataAtCell(_dataHandler, i)

        if (_getDataAtCell('sandReserveCategory') === 'P1DN') {
            const _hcLiquidWithBC = _getDataAtCell('hcLiquidWithBC')
            const _freeGasP1DNWithBC = _getDataAtCell('freeGasP1DNWithBC')
            const _solutionGasP1DNWithBC = _getDataAtCell('solutionGasP1DNWithBC')

            if (_hcLiquidWithBC !== null && isFinite(_hcLiquidWithBC)) {
                hcLiquidWithBC = hcLiquidWithBC + (_hcLiquidWithBC * 1)
            }

            if (_freeGasP1DNWithBC !== null && isFinite(_freeGasP1DNWithBC)) {
                hcGasWithBC = hcGasWithBC + (_freeGasP1DNWithBC * 1)
            }

            if (_solutionGasP1DNWithBC !== null && isFinite(_solutionGasP1DNWithBC)) {
                hcGasWithBC = hcGasWithBC + (_solutionGasP1DNWithBC * 1)
            }
        }
    }

    callbacks.onFiltered({ hcLiquidWithBC, hcGasWithBC })
}

const getSelectedSummary = (instance, row, col, row2, col2, columns, callbacks) => {
    let count = 0
    let sum = 0

    if (row > row2) {
        const _row = row
        row = row2
        row2 = _row
    }

    if (col > col2) {
        const _col = col
        col = col2
        col2 = _col
    }

    for (let i = row; i <= row2; i++) {
        const _getDataAtCell = getDataAtCell(instance, i)

        for (let j = col; j <= col2; j++) {
            const sandProperty = columns[j]
            const value = _getDataAtCell(sandProperty.key)

            if (value !== null && isFinite(value)) {
                sum = sum + (value * 1)
            }

            count++;
        }
    }

    callbacks.onSelected({ count, sum })
}

export const transformData = ({ data, allColumns, readOnly }) => {

    const _dataHandler = getDataHandler(data)

    data.forEach((row, rowIndex) => {
        const _getDataAtCell = getDataAtCell(_dataHandler, rowIndex)
        const _setDataAtCell = setDataAtCell(_dataHandler, rowIndex)

        allColumns.forEach((sandProperty, colIndex) => {
            // set default value
            if (sandProperty.defaultValue && StringHelper.isValueNullOrEmpty(_getDataAtCell(sandProperty.key))) {
                _setDataAtCell(sandProperty.key, sandProperty.defaultValue)
            }

            // map dropdown id to label
            if (sandProperty.setValueBeforeRender) {
                sandProperty.setValueBeforeRender(_getDataAtCell, _setDataAtCell, readOnly)
            }
        })
    })

    const defaultSort = allColumns.find(item => item.defaultSort)

    return data.sort((a, b) => {
        if (a[defaultSort.key] > b[defaultSort.key]) {
            return 1
        }
        if (b[defaultSort.key] > a[defaultSort.key]) {
            return -1
        }
        return 0
    })
}

export const transformToSheet = (
    data = [], expand = false, readOnly = false,
    dropdowns = {}, well = {}, defaultAnalogy = {},
    projectAttributes = {}, callbacks = {}
) => {
    const sandOptions = {
        well,
        readOnly,
        dropdowns,
        defaultAnalogy,
        projectAttributes,
    }
    const allColumns = expand ? sandProperties(sandOptions) : sandProperties(sandOptions).filter(sand => sand.showInCollapse)
    const columns = allColumns.filter(sand => !sand.hiddenColumn)
    const transformedHeaderColspan = transformHeaderColspan(columns)
    const indexedColumns = indexColumns(columns)
    const options = {
        data,
        columns,
        allColumns,
        transformedHeaderColspan,
        indexedColumns,
        expand,
        readOnly,
        well,
        dropdowns,
        defaultAnalogy,
        callbacks,
    }

    return {
        data: transformData(options),
        header: transformHeader(options),
    }
}

export const transformToJson = (columns = [], sheetData = [], sourceData = []) => {
    const dataHandler = getDataHandler(sheetData)
    return sheetData.map((row, rowIndex) => {
        const _getDataAtCell = getDataAtCell(dataHandler, rowIndex)
        /**
           * In case after change doesn't map cascading value
           * 
           const _setDataAtCell = setDataAtCell(dataHandler, rowIndex)
        */
        const result = sourceData.length
            ? { ...sourceData[rowIndex] }
            : {}
        columns.forEach(sandProperty => {
            /**
             * In case after change doesn't map cascading value
             * 
              if (sandProperty.setValueAfterChange) {
                sandProperty.setValueAfterChange(_getDataAtCell, _setDataAtCell)
              }
            */
            result[sandProperty] = _getDataAtCell(sandProperty)
            // eslint-disable-next-line use-isnan
            if (result[sandProperty] === null || result[sandProperty] === '' || result[sandProperty] === NaN) {
                result[sandProperty] = undefined
            }
        })
        return result
    })
}

export const getSanitizedColumnName = () => {
    return sandProperties({}).map(property => {
        return {
            key: property.key,
            name: property.name.replace(/<br\/>/g, ' ')
        }
    })
}

export const sandHeaderAttributesProperties = {
    wellType: 'well type',
    bcPos: 'bc pos (%)',
    bcCompressionRatio: 'bc compress. ratio',
    pipelinePressure: 'pipeline pressure (psi)',
    oilPipelinePressureCoeff: 'oil pipeline coeff.',
    gasPipelinePressureCoeff: 'gas pipeline coeff.',
    gor: 'gor (mmscf/stb)',
    cgr: 'cgr (stb/mmscf)',
    calculationType: 'calucation type'
}

export const mapReservesHeaderAttributes = data => {
    return Object.keys(sandHeaderAttributesProperties).reduce((prev, key) => {
        return {
            ...prev,
            [key]: data[key]
        }
    }, {})
}

export const getAllReservesDataAttributes = () => {
    return sandProperties({})
}