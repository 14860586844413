import * as Constants from './constants'
import * as Api from '../apis'
import { createUndrilledReservesIdSelector } from '../reducers/wellReservesUndrilled'
import { createWellIdSelector } from '../reducers/wells'
import { getCalculateReserves, getCalculateDpi } from '../actions/wellReserves'

export const undrilledMethodGetRequest = () => ({
  type: Constants.WELL_RESERVES_UNDRILLED_METHOD_GET_REQUEST,
})

export const undrilledMethodGetSuccess = payload => ({
  type: Constants.WELL_RESERVES_UNDRILLED_METHOD_GET_SUCCESS,
  payload,
})

export const undrilledMethodGetFailure = error => ({
  type: Constants.WELL_RESERVES_UNDRILLED_METHOD_GET_FAILURE,
  error,
})

export const getMethodUndrilled = () => {
  return dispatch => {
    dispatch(undrilledMethodGetRequest())
    return Api.getMethodUndrilled()
      .then(response => {
        dispatch(undrilledMethodGetSuccess(response))
        return Promise.resolve(response)
      }).catch(error => {
        dispatch(undrilledMethodGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const undrilledSyncRequest = id => ({
  type: Constants.WELL_RESERVES_UNDRILLED_SYNC_REQUEST,
  id,
})

export const undrilledSyncSuccess = payload => ({
  type: Constants.WELL_RESERVES_UNDRILLED_SYNC_SUCCESS,
  payload,
})

export const undrilledSyncFailure = error => ({
  type: Constants.WELL_RESERVES_UNDRILLED_SYNC_FAILURE,
  error,
  alert: false,
})

export const syncUndrilledReserves = id => {
  return (dispatch, getState) => {
    dispatch(undrilledSyncRequest(id))
    const well = createWellIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!well) return Promise.reject('No sand data available from SOR.')
      return Api.syncWellUndrilledReserves(id, well.project, well.platform)
    }).then(response => {
      dispatch(undrilledSyncSuccess(response))
      return Promise.resolve(response)
    }).catch(error => {
      dispatch(undrilledSyncFailure(error))
      return Promise.reject(error)
    })
  }
}

export const undrilledGetRequest = id => ({
  type: Constants.WELL_RESERVES_UNDRILLED_GET_REQUEST,
  id,
})

export const undrilledGetSuccess = payload => ({
  type: Constants.WELL_RESERVES_UNDRILLED_GET_SUCCESS,
  payload,
})

export const undrilledGetFailure = error => ({
  type: Constants.WELL_RESERVES_UNDRILLED_GET_FAILURE,
  error,
  alert: false,
})

export const getUndrilledReserves = id => {
  return dispatch => {
    dispatch(undrilledGetRequest(id))
    return Api.getUndrilledReserves(id)
      .then(response => {
        dispatch(undrilledGetSuccess(response))
        return Promise.resolve(response)
      }).catch(error => {
        dispatch(syncUndrilledReserves(id))
        dispatch(undrilledGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const undrilledSaveRequest = id => ({
  type: Constants.WELL_RESERVES_UNDRILLED_SAVE_REQUEST,
  id,
})

export const undrilledSaveSuccess = payload => ({
  type: Constants.WELL_RESERVES_UNDRILLED_SAVE_SUCCESS,
  payload,
})

export const undrilledSaveFailure = error => ({
  type: Constants.WELL_RESERVES_UNDRILLED_SAVE_FAILURE,
  error,
})

export const saveUndrilledReserves = (id, data) => {
  return (dispatch, getState) => {
    dispatch(undrilledSaveRequest(id))

    const wellUndrill = createUndrilledReservesIdSelector(getState(), id)
    const well = createWellIdSelector(getState(), id)
    const payload = {
      ...wellUndrill,
      ...data,
      wellName: id,
      projectName: well.project,
    }

    return Promise.resolve()
      .then(() => {
        return Api.calculateWellUndrillReserves(payload)
          .then(response => {
            return Api.saveUndrilledReserves(id, response)
          }).then(response => {
            return Api.publishWellReserves(response.wellSpaceId).then(() => response)
          })
      }).then(response => {
        dispatch(undrilledSaveSuccess({ ...response, wellName: id }))
        dispatch(getCalculateReserves(id, true))
        dispatch(getCalculateDpi(id, true))
        return Promise.resolve(response)
      }).catch(error => {
        dispatch(undrilledSaveFailure(error))
        return Promise.reject(error)
      })
  }
}
