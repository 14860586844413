import React, { memo, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Stack } from 'office-ui-fabric-react/lib/Stack'
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona'
import Button, { ButtonType } from '../Button'
import { setAppReady, setGlobalLoader, appDestroy } from '../../actions/views'
import { clearCache } from '../../apis'

export default memo(({ user, fullMode, setFullMode }) => {
  if (!user) return null

  const dispatch = useDispatch()
  const reload = useCallback(() => {
    dispatch(appDestroy())
    dispatch(setAppReady(false))
    dispatch(setGlobalLoader(true))
    clearCache().then(() => {
      dispatch(setAppReady(true))
      dispatch(setGlobalLoader(false))
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const menus = useMemo(() => [{
    key: '0',
    text: `full screen: ${fullMode ? 'on' : 'off'}`,
    iconProps: { iconName: fullMode ? 'CheckboxCompositeReversed' : 'Checkbox' },
    onClick: () => setFullMode(!fullMode),
  }, {
    key: '1',
    text: 'invalidate cache and refresh',
    iconProps: { iconName: 'Refresh' },
    onClick: reload,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }], [fullMode])

  return (
    <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 8 }} styles={{ root: { marginBottom: 8 } }}>
      <Persona imageInitials={user.cai.slice(0, 2)} text={user.displayName} title={`${user.displayName} [${user.unique}]`} size={PersonaSize.size24} />
      <Button
        type={ButtonType.icon}
        menuProps={{
          items: menus
        }}
      />
    </Stack>
  )
})

