import * as Constant from '../../actions/constants'
import { combineReducers } from 'redux'

export const productionAreaById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_PRODUCTION_AREA_SUCCESS:
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, {})
    default:
      return state
  }
}

export const productionApplicableById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_PRODUCTION_APPLICABLE_SUCCESS:
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, {})
    default:
      return state
  }
}

export const productionById = (state = {}, action) => {
  switch (action.type) {
    case Constant.ADMIN_PRODUCTION_LIST_SUCCESS:
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        }
      }, {})
    default:
      return state
  }
}

export default combineReducers({
  productionAreaById,
  productionApplicableById,
  productionById,
})

export const createProductionAreaSelector = (state) => {
  return Object.values(state.admin.productionProfile.productionAreaById).sort((a, b) => (a.name || '').trim().localeCompare((b.name || '').trim()))
}

export const createProductionApplicableSelector = (state) => {
  return Object.values(state.admin.productionProfile.productionApplicableById)
}

export const createProductionListSelector = (state) => {
  return Object.values(state.admin.productionProfile.productionById).sort((a, b) => (a.asset || '').trim().localeCompare((b.asset || '').trim()))
}

export const createProductionById = (state, id) => {
  return state.admin.productionProfile.productionById[id] ?
    Object.assign({}, state.admin.productionProfile.productionById[id]) : null
}