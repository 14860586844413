import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'

export const allIds = (state = [], action) => {
  switch (action.type) {
    case Constant.WELL_DECISION_PUBLISH_SUCCESS:
    case Constant.WELL_DECISION_GET_SUCCESS: {
      if (!state.includes(action.payload.wellName)) {
        return [...state, action.payload.wellName]
      }
      return state
    }
    default:
      return state
  }
}

const selectTabId = (state = {}, action) => {
  if (!state[action.wellName])
    state[action.wellName] = {}

  if (!state[action.wellName][action.tabId])
    state[action.wellName][action.tabId] = {}

  let _state = {
    ...state,
    [action.wellName]: {
      ...state[action.wellName],
      
    }
  }
  delete _state[action.wellName].add
  _state[action.wellName][action.tabId] = {..._state[action.wellName][action.tabId], ...action.payload}

  return _state
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case Constant.WELL_DECISION_LATCH_SUCCESS: {
      return selectTabId(state, action)
    }
    case Constant.WELL_DECISION_DUPLICATE_SUCCESS: {
      action.tabId = 'add'
      return selectTabId(state, action)
    }
    case Constant.WELL_DECISION_SAVE_SUCCESS:
    case Constant.WELL_DECISION_PUBLISH_SUCCESS:
    case Constant.WELL_DECISION_GET_SUCCESS: {
      action.tabId = action.payload.tabId
      action.wellName = action.payload.wellName
      return selectTabId(state, action)
    }
    case Constant.WELL_DECISIONS_GET_SUCCESS: {
      if (!state[action.wellName])
        state[action.wellName] = {}

      return {
        ...state,
        [action.wellName]: action.payload
      }
    }
    case Constant.WELL_DECISION_DELETE_SUCCESS: {
      let _state = { ...state }

      if (_state[action.wellName] && _state[action.wellName][action.tabId])
        delete(_state[action.wellName][action.tabId])

      return _state
    }
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
})

export const createWellDecisionsSelector = (state, wellName) => {
  return state.wellDecisions.byId[wellName]
}

export const createWellDecisionIdSelector = (state, wellName, tabId = null) => {
  return tabId && state.wellDecisions.byId[wellName] && state.wellDecisions.byId[wellName][tabId] ? Object.assign({}, state.wellDecisions.byId[wellName][tabId]) : null
}