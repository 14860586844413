/**
 * Format message into given format
 * @param {string} format 
 * @param {object} translations 
 */
export function formatMessage(format: string, translations: any): string {
  let text = `${format}`
  Object.keys(translations).forEach(key =>
    text = text.replace(`{${key}}`, translations[key])
  )
  return text
}

/**
 * Format into locale string
 * @param {*} style 
 * @param {number} value 
 * @param {*} options 
 */

interface LocaleOption {
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
  currency?: string
}

export const formatLocaleString = (style: string) => (value: string, options?: LocaleOption): string => {
  let _options: any = {
    style: style,
    minimumFractionDigits: options && options.minimumFractionDigits ? options.minimumFractionDigits : 0,
    maximumFractionDigits: options && options.maximumFractionDigits ? options.maximumFractionDigits : 0,
  }

  if (style === 'currency') {
    _options.currency = options && options.currency ? options.currency : 'USD'
  }

  return (Number.parseFloat(value) || 0).toLocaleString('en-US', _options)
}

/**
 * Display capital case in title case
 * @param {*} text 
 */
export function capitalToTitleCase(text: string): string {
  text = (text ? `${(text)}` : '').replace(/([A-Z])/g, ' $1');
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Check if text is in Array ignore cases 
 * @param {Array} array 
 * @param {any} text 
 */

export function isInArrayIgnoreCase(array: string[], text: string): Boolean {
  return array.some(a => a.toUpperCase() === `${text}`.toUpperCase())
}

/**
 * Check if text is null or empty or undefined
 * @param {Text} value 
 */
export function isValueNullOrEmpty(value: any): Boolean {
  return value === '' || value === null || value === undefined
}

/**
 * Check if text is contains onyl number
 * @param {Text} value 
 */
export function isTextValueNumber(value: string): Boolean {
  return !value || !!(value && `${value}`.trim().match(/^[0-9]*\.?[0-9]*$/gm))
}

/**
 * Sort array by given string key
 * @param key 
 */
export function sortArrayByStringKey(key: string): any {
  return (a: any, b: any) => {
    if (a[key] > b[key]) return 1
    if (b[key] > a[key]) return -1
    return 0;
  }
}