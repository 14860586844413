import * as Constant from '../actions/constants'
import { getPermissions } from '../helpers/permission'

const initialState = {
  user: null,
  permissions: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Constant.SESSION_AUTHEN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        permissions: getPermissions(action.payload),
      }
    default:
      return state
  }
}
