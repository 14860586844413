import React, { memo } from 'react'
import style from './style.module.css'

export default memo(() => {
  return (
    <footer className={style.footer}>
      <div className={style.inner}>
        <div className="line_1">

          <div className={style.footer_links}>
            ©2019 Chevron Corporation
              </div>

          <div className={style.footer_links}>
            Company Confidential
              </div>

          <div className={style.footer_links}>
            <a href="https://www.chevron.com/privacy">Privacy Statement</a>
          </div>

          <div className={style.footer_links}>
            <a href="https://www.chevron.com/accessibility">Accessibility</a>
          </div>

        </div>

        <div className="line_2">
          <div className={style.footer_links}>
            OpCo: <a href="http://thailandupstream.chevron.com" target="_blank" rel="noopener noreferrer">ASBU Thailand</a>
          </div>

          <div className={style.footer_links}>
            Content Contact: <a href="mailto:GRP_BANGKOK_SURFACE_LIVE_ADMIN@chevron.com">SURFACE-LIVE Admin</a>
          </div>

          <div className={style.footer_links}>
            Technical Contact: <a href="mailto:itsupport@chevron.com">Global IT Service Desk</a>
          </div>
        </div>
      </div>
    </footer>
  )
})
