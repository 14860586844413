import axios, { CancelToken } from 'axios'
import { setupCache } from './axios-cache-adapter/src'
import localforage from 'localforage'
import * as Msal from 'msal'
import { connect } from 'react-redux';
import React, { Component } from 'react';
import store from '../index'
import { useHistory, withRouter } from  'react-router-dom'

const baseURL = process.env.REACT_APP_BASE_API_URL;


const forageStore = localforage.createInstance({
  name: 'store'
});

const defaultConfig = {
  cancelToken: undefined,
  cache: {
    store: forageStore, // Pass `localforage` store to `axios-cache-adapter`
    maxAge: 180 * 60 * 1000, //180 minutes
    exclude: {
      // {Boolean} Exclude requests with query parameters.
      query: false
    },
    ignoreCache: true,
    // Attempt reading stale cache data when response status is either 4xx or 5xx
    readOnError: (error, request) => {
      return error.response.status >= 400 && error.response.status < 600
    },
    // Override invalidate cache to also cache post request
    invalidate: (async (config, request) => {
      if (request.cache && request.cache.ignoreCache) {
        await config.store.removeItem(config.uuid)
      }
    }),
    // Deactivate `clearOnStale` option so that we can actually read stale cache data
    clearOnStale: false,
  },
}

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: process.env.NODE_ENV !== 'test' && setupCache(defaultConfig.cache).adapter
})

/**
 *
 * Status code
 *  
 */
export const codeMessage = {
  0: 'Unknown',
  200: 'The server successfully returned the requested data.',
  201: 'New or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'The data was deleted successfully.',
  400: '400! The request was made with an error and the server did not perform any operations to create or modify data.',
  401: '401! User does not have permission or Access Token is expired (Please refresh the page to avoid incomplete record interactions).',
  403: '403! The user is authorized, but access is forbidden.',
  404: '404! The request is made for a record that does not exist and the server does not operate.',
  406: '406! The format of the request is not available.',
  410: '410! The requested resource is permanently deleted and will not be retrieved.',
  422: '422! A validation error occurred when creating an object.',
  499: '499! Client closed request',
  500: '500! An error occurred on the server. Please check the server.',
  502: '502! Gateway error.',
  503: '503! The service is unavailable and the server is temporarily overloaded or maintained.',
  504: '504! The gateway timed out.',
};

/**
 *
 * Exception message 
 *  
 */
export const exceptionMessage = {
  'System.NullReferenceException': 'System internal Error : Null reference',
  'CVX.SurfaceLive.Exceptions.ClientNotSuccessException': 'SURFACE cannot connect to TCRS',
  'CVX.SurfaceLive.Exceptions.ApiNotFoundException': 'SURFACE encounters error with API',
  'CVX.SurfaceLive.Exceptions.AttachmentNotFoundException ': 'Attachment not found. ',
  'CVX.SurfaceLive.Exceptions.ConfigurationNotFoundException': 'System internal error: appsetting key is not found',
  'CVX.SurfaceLive.Exceptions.ConfigurationNotValidException': 'System internal error: appsetting key is not found',
  'CVX.SurfaceLive.Exceptions.JobNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.JobNotProductiveException': 'N/A',
  'CVX.SurfaceLive.Exceptions.PageNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.PlatformNotFoundException': 'SURFACE cannot find platform in TCRS',
  'CVX.SurfaceLive.Exceptions.PlatformNotValidException': 'SURFACE cannot find platform in TCRS',
  'CVX.SurfaceLive.Exceptions.PriceNotFoundException': 'Gas and Oil Price are not found',
  'CVX.SurfaceLive.Exceptions.PriceNotValidException': 'Gas and Oil Price are not found',
  'CVX.SurfaceLive.Exceptions.ProductionNotFoundException': 'Production Profile is not found',
  'CVX.SurfaceLive.Exceptions.ProductionNotValidException': 'Production Profile is not found',
  'CVX.SurfaceLive.Exceptions.ProjectNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.ProjectNotValidException': 'N/A',
  'CVX.SurfaceLive.Exceptions.ProjectAttributeNotFoundException': 'Project Setting is missing. Please go to "Settings" and complete project setup',
  'CVX.SurfaceLive.Exceptions.ProjectWellNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.ProjectDrilledNotFoundException': 'DPI setup is missing. Please go to "Settings" and complete project setup',
  'CVX.SurfaceLive.Exceptions.ProjectAuthorizeNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.ProjectLocationNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.ProjectActivityNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.ResourceNotFoundException': 'Time/Cost Items are not found',
  'CVX.SurfaceLive.Exceptions.ResourceNotValidException': 'Time/Cost Items are not found',
  'CVX.SurfaceLive.Exceptions.RigNotFoundException': 'Drill Trip Rate is not found',
  'CVX.SurfaceLive.Exceptions.RigNotValidException': 'Drill Trip Rate is not found',
  'CVX.SurfaceLive.Exceptions.RoleNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.RoleAuthorizeNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.RoleNotValidException': 'N/A',
  'CVX.SurfaceLive.Exceptions.SandNotFoundException': 'SURFACE cannot retrieve reserves from TCRS (Req ID:xxxxxxxxx). ',
  'CVX.SurfaceLive.Exceptions.SandNotValidException': 'N/A',
  'CVX.SurfaceLive.Exceptions.SandNotCalculateException': 'N/A',
  'CVX.SurfaceLive.Exceptions.SessionLoggNotFoundException': 'System internal Error : Session is not found',
  'CVX.SurfaceLive.Exceptions.TemplateNotFoundException': 'DPI setup is missing. Please go to "Settings" and complete project setup',
  'CVX.SurfaceLive.Exceptions.TemplateNotValidException': 'Template upload failed',
  'CVX.SurfaceLive.Exceptions.InvalidTokenException': 'System internal Error : Token is invalid',
  'CVX.SurfaceLive.Exceptions.UserNotFoundException': 'System internal Error : User is not found',
  'CVX.SurfaceLive.Exceptions.UserAuthenNotFoundException': 'System internal Error : User is not found',
  'CVX.SurfaceLive.Exceptions.UserNotValidException': 'System internal Error : User is not found',
  'CVX.SurfaceLive.Exceptions.WellNotFoundException': 'System internal Error : Well is not found',
  'CVX.SurfaceLive.Exceptions.WellNotValidException': 'N/A',
  'CVX.SurfaceLive.Exceptions.WellNotProductiveException': 'N/A',
  'CVX.SurfaceLive.Exceptions.WellNotPlannedException': 'N/A',
  'CVX.SurfaceLive.Exceptions.WellAnalogyNotFoundException': 'SURFACE cannot retrieve well analogy from TCRS (Req ID:xxxxxxxxx). ',
  'CVX.SurfaceLive.Exceptions.WellScenarioNotFoundException': 'SURFACE cannot retrieve well scenario from TCRS (Req ID:xxxxxxxxx). ',
  'CVX.SurfaceLive.Exceptions.WellPropertiesNotFoundException': 'System internal Error : Well Setup cannot be saved',
  'CVX.SurfaceLive.Exceptions.WellPropertiesNotValidException': 'System internal Error : Well Setup cannot be saved',
  'CVX.SurfaceLive.Exceptions.WellDrilledNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.WellUndrilledNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.WellActivityNotFoundException': 'N/A',
  'CVX.SurfaceLive.Exceptions.InvalidOpeartionException': 'N/A',
  'CVX.SurfaceLive.Exceptions.AttachmentNotValidException': 'SURFACE cannot recognize the attachment. Please verify the format and correctness',
};

class RequestController extends Component {
    
    constructor(props) {
        super(props);
    }

    static historyHook = null;

    static setHistoryHook(_hook) {
        this.historyHook = _hook;
    }

    static getAccount() {
        return this.msalInstance.getAccount()
    }

    static getSessionToken() {
        return this.sessionToken
    }

    static setSessionToken(sessionToken) {
        this.sessionToken = sessionToken
    }


    static requestNewToken() {
        this.historyHook.push('/Authenticate');
    }


    static fetch(url, options = {}, config = {}) {

        const _options = {
            method: (options && options.method ? options.method : 'GET'),
            data: (options && options.data ? options.data : null)
        }

        const headers = {}
        if (options && options.headers) {
            Object.keys(options.headers).forEach(key => headers[key] = options.headers[key])
        }

        if (store.getState().authenSession.token !== null) {
            headers['Authorization'] = `Bearer ${store.getState().authenSession.token}`;
        }

        const axiosOptions = {
            url,
            headers,
            method: _options.method,
            data: _options.data,
            //withCredentials: true,
            // `axios-cache-adapter` options
            //cache: config.cache,
        }

        return api.request(axiosOptions)
            .then(res => Promise.resolve(res.data))
            .catch(res => {
                if (res.response.status === 401) {
                    this.requestNewToken();
                }
                else if (axios.isCancel(res)) {
                    return Promise.reject({
                        reqId: '',
                        code: codeMessage[499],
                        message: '',
                        status: 499
                    })
                }
                try {
                    return Promise.reject({
                        reqId: res.response.data.RequestId,
                        code: codeMessage[res.response.status],
                        message: exceptionMessage[res.response.data.Type] || res.response.data.Message,
                        status: res.response.status
                    })
                } catch (e) {
                    return Promise.reject({
                        reqId: '',
                        code: codeMessage[0],
                        message: e,
                        status: 0
                    })
                }
            })
    }

    static transformPath(path) {
        let result = `${path}`
        if (result[0] === '/') result = result.slice(1)
        return result
    }

    static query(path, options, config) {
        return this.fetch(`${baseURL}${this.transformPath(path)}`, options, config)
    }

    static post(path, data, config) {
        return this.query(path, {
            method: 'POST',
            data,
        }, config)
    }

    static put(path, data, config) {
        return this.query(path, {
            method: 'PUT',
            data,
        }, config)
    }

    static delete(path, data, config) {
        return this.query(path, {
            method: 'DELETE',
            data,
        }, config)
    }

    static get(path, config) {
        return this.query(path, {}, config)
    }

    static authenBodyTransform(payload) {
        return {
            accessToken: payload.accessToken,
            idToken: payload.idToken.rawIdToken
        }
    }

    static becomeSession() {
        const options = {
            scopes: ['user.read', 'mail.send']
        }
        return this.msalInstance.acquireTokenSilent(options)
            .then(response => {
                this.setSessionToken(response.accessToken)
                return this.post('user/authen', this.authenBodyTransform(response))
            })
    }

    static microsoftLogIn() {
        const options = {
            scopes: ['user.read', 'mail.send']
        }
        this.msalInstance.loginRedirect(options)
    }

    static logOut() {
        this.setSessionToken(null)
        if (process.env.NODE_ENV !== 'test') this.msalInstance.logout()
        return Promise.resolve()
    }

    static clearCache() {
        return forageStore.clear()
    }

}

//export default RequestController
export default withRouter(RequestController)
//export default new RequestController()
//export default connect()(RequestController)