import { combineReducers } from 'redux'

const loading = (state = {}, action) => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action.type);
  
  // not a *_REQUEST / *_SUCCESS / *_FAILURE actions, so we ignore them
  if (!matches) return state;  
  if (action.error && action.error.code && action.error.code.includes('403')) return state;
  if (action.error && action.error.code && action.error.code.includes('499')) return state;
  
  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'REQUEST',
  };
};

const error = (state = {}, action) => {
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(action.type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    [requestName]: requestState === 'FAILURE' ? action.message : '',
  };
};

export default combineReducers({
  loading,
  error,
})

export const createLoadingSelector = actions => state => {
  return !!actions.find(action => state.api.loading[action])
}

export const createErrorMessageSelector = actions => state => {
  const errors = actions.map(action => state.api.error[action])
  if (errors && errors[0]) {
    return errors[0]
  }
  return ''
}
