import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'
import { getDrilledReserves } from '../helpers/wellSummary'

export const allIds = (state = [], action) => {
  switch (action.type) {
    case Constant.WELL_RESERVES_GET_SUCCESS:
    case Constant.WELL_RESERVES_SYNC_SUCCESS:
    case Constant.WELL_RESERVES_SAVE_SUCCESS:
    case Constant.WELL_RESERVES_MERGE_SUCCESS:
    case Constant.WELL_RESERVES_PUBLISH_SUCCESS:
    case Constant.WELL_DEFAULT_ANALOGY_GET_SUCCESS: {
      if (!state.includes(action.payload.wellName)) {
        return [...state, action.payload.wellName]
      }
      return state
    }
    case Constant.WELL_RESERVES_GET_FAILURE:
    case Constant.WELL_RESERVES_SYNC_FAILURE: {
      return state.filter(wellName => wellName !== action.wellName)
    }
    case Constant.APP_DESTROY: return [];
    default:
      return state
  }
}

export const byIdRecent = (state = {}, action) => {
  switch (action.type) {
    case Constant.WELL_RESERVES_GET_SUCCESS:
    case Constant.WELL_RESERVES_SAVE_SUCCESS:
    case Constant.WELL_RESERVES_MERGE_SUCCESS:
    case Constant.WELL_RESERVES_PUBLISH_SUCCESS:
      return {
        ...state,
        [action.payload.wellName]: {
          ...state[action.payload.wellName],
          ...action.payload,
        }
      }
    case Constant.WELL_RESERVES_GET_FAILURE: {
      if (action.error && action.error.status === 404) {
        return {
          ...state,
          [action.wellName]: undefined
        }
      }
      return state
    }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export const byIdSync = (state = {}, action) => {
  switch (action.type) {
    case Constant.WELL_RESERVES_SYNC_SUCCESS: {
      return {
        ...state,
        [action.payload.wellName]: {
          ...state[action.payload.wellName],
          ...action.payload,
        }
      }
    }
    case Constant.WELL_RESERVES_SYNC_FAILURE: {
      if (action.error && action.error.status === 404) {
        return {
          ...state,
          [action.wellName]: undefined
        }
      }
      return state
    }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export const byIdDefaultAnalogy = (state = {}, action) => {
  switch (action.type) {
    case Constant.WELL_DEFAULT_ANALOGY_GET_SUCCESS:
      return {
        ...state,
        [action.payload.wellName]: {
          ...state[action.payload.wellName],
          ...action.payload,
        }
      }
    case Constant.APP_DESTROY: return {};
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byIdSync,
  byIdRecent,
  byIdDefaultAnalogy,
})

export const createRecentDrilledReservesIdSelector = (state, id) => {
  return state.wellReserves.byIdRecent[id] ? Object.assign({}, state.wellReserves.byIdRecent[id]) : null
}

export const createSyncDrilledReservesIdSelector = (state, id) => {
  return state.wellReserves.byIdSync[id] ? Object.assign({}, state.wellReserves.byIdSync[id]) : null
}

export const createDrilledReservesIdSelector = (state, id) => {
  return getDrilledReserves(createRecentDrilledReservesIdSelector(state, id), createSyncDrilledReservesIdSelector(state, id))
}

export const createAllWellReservesSelector = state => {
  return state.wellReserves.allIds.map(id => state.wellReserves.byIdRecent[id])
}

export const createDefaultAnalogyIdSelector = (state, id) => {
  return state.wellReserves.byIdDefaultAnalogy[id] ? Object.assign({}, state.wellReserves.byIdDefaultAnalogy[id]) : null
}