import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import errorMiddleware from './middlewares/error'
import reducers from '../reducers'

export default function (initialState = {}) {

  const middleware = [
    thunk,
    errorMiddleware,
  ]

  let devToolsExtension = f => f
  if (process.env.NODE_ENV !== 'production') {
    middleware.push(require('redux-logger').default)
    if (window.__REDUX_DEVTOOLS_EXTENSION__) devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__()
  }

  const enhancer = compose(applyMiddleware(...middleware), devToolsExtension)

  const store = createStore(
    reducers,
    initialState,
    enhancer
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default
      store.replaceReducer(nextReducer)
    })
  }

  return store
}
