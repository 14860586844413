import { combineReducers } from 'redux'
import productionProfile from './productionProfile'
import scenarioSetup from './scenarioSetup'
import projectSetups from './projectSetups'
import templates from './templates'
import roles from './roles'
import projects from './projects'
import locations from './locations'

export default combineReducers({
  productionProfile,
  scenarioSetup,
  projectSetups,
  templates,
  roles,
  projects,
  locations,
})
