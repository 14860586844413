import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'
import { removeEmpty } from '../helpers/object'

export const allIds = (state = [], action) => {
  switch (action.type) {
    case Constant.PROJECT_GET_SUCCESS: {
      if (!state.includes(action.payload.id)) {
        return [...state, action.payload.id]
      }
      return state
    }
    case Constant.PROJECTS_SYNC_GET_SUCCESS: {
      const result = [...state]
      action.payload.forEach(project => {
        if (!result.includes(project.id)) {
          result.push(project.id)
        }
      })
      return result
    }
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case Constant.PROJECT_GET_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...removeEmpty(action.payload),
        }
      }
    }
    case Constant.PROJECTS_SYNC_GET_SUCCESS: {
      const result = {...state}
      action.payload.forEach(project => {
        result[project.id] = {
          ...result[project.id],
          ...removeEmpty(project),
        }
      })
      return result
    }
    case Constant.PROJECT_RESERVES_GET_SUCCESS:
    case Constant.PROJECT_DPI_GET_SUCCESS:
    case Constant.PROJECT_PRODUCTIVE_GET_SUCCESS:
    case Constant.PROJECT_PLANNED_GET_SUCCESS: 
     {
      return {
        ...state,
        [action.payload.rllcpReferenceId]: {
          ...state[action.payload.rllcpReferenceId],
          ...removeEmpty(action.payload),
        }
      }
    }
    case Constant.GET_DOLLAR_BOE_SUCCESS: {
      return {
        ...state,
        [action.payload.rllcpReferenceId]: {
          ...state[action.payload.rllcpReferenceId],
          plannedDollarBOE :  (action.payload.planned ? action.payload.planned : null )
        }
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
})

export const createProjectIdByNameSelector = (state, id) => {
  const result =  createAllProjectSelector(state).find(project => project.name === id)
  return result ? Object.assign({}, result) : null
}

export const createProjectIdSelector = (state, id) => {
  return state.projects.byId[id] ? Object.assign({}, state.projects.byId[id]) : null
}

export const createAllProjectSelector = state => {
  return state.projects.allIds.map(id => state.projects.byId[id])
}

