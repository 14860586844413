//import { getApiToken } from '../api/tcrsApi';
//import stateHelper from './stateHelper'
import { loginRequest } from "../authConfig";
import * as Constant from '../actions/constants'

class staticContext {
  constructor() {

  }

  static _instance;
  static _accounts;  
  static _dispatch;
  static _session;

  static init({useSelector, useDispatch, useMsal}) {

    const { instance, accounts } = useMsal();
    this._instance = instance;
    this._accounts = accounts;

    const session = useSelector(state => state.authenSession);
    this._session = session;

    const dispatch = useDispatch();
    this._dispatch = dispatch;
  }

  static isTokenExpired() {
      if (this._info.token 
          && this._info.expiry
          && this._info.expiry > (new Date(Date.now() + (1000 * 60 * 5)))){
        return false;
    }
    else
      return true;
  }


   static verifyToken = () => {

       console.log("------BEFORE VERIFY TOKEN SESSION --------");
       console.log(this._session)

    if (!this._session.token || this.isTokenExpired()){
      console.log('Require to get new token');
      const token = this.renewToken();
      if(token !== null)
          this._dispatch({
              type: Constant.GET_AZURE_TOKEN_SUCCESS,
              payload: {
                  token: token.accessToken,
                  expiry: token.expiresOn
              }
          })
       }

       console.log("------AFTER VERIFY TOKEN SESSION --------");
       console.log(this._session)

       return this._session;
    }

    static renewToken = () => {
        
        return this._instance.acquireTokenSilent({
            ...loginRequest,
            account: this.accounts[0]
        }).catch((error) => {
            // do nothing
        });
    }
}

export default staticContext;
