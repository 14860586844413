
declare interface ICompareChanges {
  usi: string
  text: string
  isAdd: boolean
  isRemove: boolean
}

declare interface IReserves {
  usi: string
  name: string
  value: string
  sands: []
  updatedDate: Date
  sorUpdatedDate: Date
  activityDate: Date
  isPublisher: boolean
}

export const displayCompareChanges = (
  userDrilledReserves: IReserves[] = [],
  sourceDrilledReserves: IReserves[] = [],
  filter: string = ''
): ICompareChanges[] => {
  const findUSI = (find: { usi: string }) => (item: { usi: string }) => item.usi === find.usi
  // const filterName = item => item.name.toLowerCase().includes(filter.toLowerCase())
  const filterText = (item: ICompareChanges) => item.text.toLowerCase().includes(filter.toLowerCase())
  const sortString = (a: ICompareChanges, b: ICompareChanges) => {
    if (a.usi > b.usi) return 1
    if (b.usi > a.usi) return -1
    return 0;
  }
  const removeDup = (prev: ICompareChanges[], current: ICompareChanges) => {
    if (prev.find(findUSI(current))) return prev
    return prev.concat(current)
  }

  return userDrilledReserves.concat(sourceDrilledReserves)
    .map(i => ({
      usi: i.usi,
      text: i.name,
      isAdd: !userDrilledReserves.find(findUSI(i)),
      isRemove: !sourceDrilledReserves.find(findUSI(i)),
    }))
    .filter(filterText)
    .sort(sortString)
    .reduce(removeDup, [])
}

/// Save flow
export const hasDrilledReservesValue = (drilledReserves: IReserves) => (drilledReserves && drilledReserves.sands && drilledReserves.sands.length)

export const hasDrilledReserves = (userDrilledReserves: IReserves, sourceDrilledReserves: IReserves) => (hasDrilledReservesValue(userDrilledReserves) || hasDrilledReservesValue(sourceDrilledReserves))

export const getDrilledReserves = (userDrilledReserves: IReserves, sourceDrilledReserves: IReserves) => {
  if (sourceDrilledReserves) {
    if (userDrilledReserves) {
      return userDrilledReserves
    } else {
      return sourceDrilledReserves
    }
  } else if (userDrilledReserves) {
    // has only user's version, this should not be happened actually
    return userDrilledReserves
  } else {
    // has neither user nor source version
    return null
  }
}

export const hasIncomingChange = (
  userDrilledReserves: IReserves,
  sourceDrilledReserves: IReserves
): boolean => (
  userDrilledReserves && sourceDrilledReserves && new Date(sourceDrilledReserves.sorUpdatedDate) > new Date(userDrilledReserves.activityDate)
)

export const gasToLiquid = (gasInMMScf: number): number => gasInMMScf / 6

export const liquidToGas = (liquidInMBOE: number): number => liquidInMBOE * 6

export const gasAndOilTotal = (liquidInMBOE: number, gasInMMScf: number): number => liquidInMBOE + gasToLiquid(gasInMMScf)

export const convertGasAndOilRatio = (totalInMBOE: number, liquidRatio: number): { liquidInMBOE: number, gasInMMScf: number } => ({
  liquidInMBOE: totalInMBOE * liquidRatio,
  gasInMMScf: liquidToGas(totalInMBOE - (totalInMBOE * liquidRatio))
})
