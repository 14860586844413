//https://github.com/duhseekoh/demo-access-control-in-react/blob/master/example-apps/v3-access-control-logic/AccessControl.js
import { memo } from 'react'
import { connect } from 'react-redux'

const isPermitted = (userPermissions, allowedPermissions, oneperm) => {
  //Permit when no allowedPermissions specify
  if (allowedPermissions.length === 0) return true

  //Permit when userPermissions match with allowedPermissions (oneperm means atleast one permission is required)
  return allowedPermissions[oneperm ? 'some' : 'every'](permission =>
    userPermissions.includes(permission)
  )
}

const AccessControl = memo(({
  oneperm,
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
}) =>
  isPermitted(userPermissions, allowedPermissions, oneperm)
    ? children
    : renderNoAccess()
)

AccessControl.defaultProps = {
  oneperm: false,
  allowedPermissions: [],
  userPermissions: [],
  renderNoAccess: () => null,
}

//export default connect(state => ({ userPermissions: state.session.permissions }))(AccessControl)
export default connect(state => ({ userPermissions: state.authenSession.permissions }))(AccessControl)
