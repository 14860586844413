import * as Constants from './constants'
import * as Api from '../apis'
import * as ApiAdmin from '../apis/admin'
import { createProjectIdSelector } from '../reducers/projects'
import { getDollarBoEFailure, getDollarBoERequest, getDollarBoESuccess } from './admin/projectSetup'

export const projectGetRequest = id => ({
  type: Constants.PROJECT_GET_REQUEST,
  id,
})

export const projectGetSuccess = payload => ({
  type: Constants.PROJECT_GET_SUCCESS,
  payload,
})

export const projectGetFailure = error => ({
  type: Constants.PROJECT_GET_FAILURE,
  error,
})

export const getProject = id => {
  return dispatch => {
    dispatch(projectGetRequest(id))
    return Api.getProject(id)
      .then(payload => {
        dispatch(projectGetSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(projectGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const projectsSyncGetRequest = () => ({
  type: Constants.PROJECTS_SYNC_GET_REQUEST,
})

export const projectsSyncGetSuccess = payload => ({
  type: Constants.PROJECTS_SYNC_GET_SUCCESS,
  payload,
})

export const projectsSyncGetFailure = error => ({
  type: Constants.PROJECTS_SYNC_GET_FAILURE,
  error,
})

export const getSyncProjects = () => {
  return dispatch => {
    dispatch(projectsSyncGetRequest())
    return Api.getSyncProjects()
      .then(payload => {
        dispatch(projectsSyncGetSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(projectsSyncGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const projectsGetRequest = () => ({
  type: Constants.PROJECTS_GET_REQUEST,
})

export const projectsGetSuccess = payload => ({
  type: Constants.PROJECTS_GET_SUCCESS,
  payload,
})

export const projectsGetFailure = error => ({
  type: Constants.PROJECTS_GET_FAILURE,
  error,
})

export const getProjects = () => {
  return dispatch => {
    dispatch(projectsGetRequest())
    return Api.getProjects()
      .then(payload => {
        dispatch(projectsGetSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(projectsGetFailure(error))
        return Promise.reject(error)
      })
  }
}

export const projectProductiveGetRequest = id => ({
  type: Constants.PROJECT_PRODUCTIVE_GET_REQUEST,
  id,
})

export const projectProductiveGetSuccess = payload => ({
  type: Constants.PROJECT_PRODUCTIVE_GET_SUCCESS,
  payload,
})

export const projectProductiveGetFailure = error => ({
  type: Constants.PROJECT_PRODUCTIVE_GET_FAILURE,
  error,
})

export const getProjectProductive = (id, ignoreCache) => {
  return (dispatch, getState) => {
    dispatch(projectProductiveGetRequest(id))
    const project = createProjectIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!project) return Promise.reject('No project available from SOR.')
      return Api.getProjectProductive(id, project.name, ignoreCache)
    }).then(payload => {
      dispatch(projectProductiveGetSuccess(payload))
      return Promise.resolve(payload)
    }).catch(error => {
      dispatch(projectProductiveGetFailure(error))
      return Promise.reject(error)
    })
  }
}

export const projectPlannedGetRequest = id => ({
  type: Constants.PROJECT_PLANNED_GET_REQUEST,
  id,
})

export const projectPlannedGetSuccess = payload => ({
  type: Constants.PROJECT_PLANNED_GET_SUCCESS,
  payload,
})

export const projectPlannedGetFailure = error => ({
  type: Constants.PROJECT_PLANNED_GET_FAILURE,
  error,
})

export const getProjectPlanned = (id, ignoreCache) => {
  return (dispatch, getState) => {
    dispatch(projectPlannedGetRequest(id))
    const project = createProjectIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!project) return Promise.reject('No project available from SOR.')
      return Api.getProjectPlanned(id, project.name, ignoreCache)
    }).then(payload => {
      dispatch(projectPlannedGetSuccess(payload))
      return Promise.resolve(payload)
    }).catch(error => {
      dispatch(projectPlannedGetFailure(error))
      return Promise.reject(error)
    })
  }
}

export const projectReservesGetRequest = id => ({
  type: Constants.PROJECT_RESERVES_GET_REQUEST,
  id,
})

export const projectReservesGetSuccess = payload => ({
  type: Constants.PROJECT_RESERVES_GET_SUCCESS,
  payload,
})

export const projectReservesGetFailure = error => ({
  type: Constants.PROJECT_RESERVES_GET_FAILURE,
  error,
})

export const getProjectCalculateReserves = (id, ignoreCache) => {
  return (dispatch, getState) => {
    dispatch(projectReservesGetRequest(id))
    const project = createProjectIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!project) return Promise.reject('No project available from SOR.')
      return Api.getProjectCalculateReserves(id, project.name, ignoreCache)
    }).then(payload => {
      dispatch(projectReservesGetSuccess({ ...payload, rllcpReferenceId: id }))
      return Promise.resolve(payload)
    }).catch(error => {
      dispatch(projectReservesGetFailure(error))
      return Promise.reject(error)
    })
  }
}

export const projectDpiGetRequest = id => ({
  type: Constants.PROJECT_DPI_GET_REQUEST,
  id,
})

export const projectDpiGetSuccess = payload => ({
  type: Constants.PROJECT_DPI_GET_SUCCESS,
  payload,
})

export const projectDpiGetFailure = error => ({
  type: Constants.PROJECT_DPI_GET_FAILURE,
  error,
})

export const getProjectDpi = (id, ignoreCache) => {
  return (dispatch, getState) => {
    dispatch(projectDpiGetRequest(id))
    const project = createProjectIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if (!project) return Promise.reject('No project available from SOR.')
      const payload = {
        projectName: project.name,
        platformName: project.platformName,
        rllcpProjectId: id,
      }

      return Api.getProjectDpi(payload, ignoreCache)
    }).then(payload => {
      dispatch(projectDpiGetSuccess({ ...payload, rllcpReferenceId: id }))
      return Promise.resolve(payload)
    }).catch(error => {
      dispatch(projectDpiGetFailure(error))
      return Promise.reject(error)
    })
  }
}


export const getDollarBoE = (id, projectName) => {
  return (dispatch, getState) => {
    dispatch(getDollarBoERequest(projectName))
    const project = createProjectIdSelector(getState(), id)
    return Promise.resolve().then(() => {
      if(!project) return Promise.reject("No project available from SOR.")
      return ApiAdmin.getDollarBoE(projectName)
    }).then(response => {
      dispatch(getDollarBoESuccess({...response, rllcpReferenceId: id}))
      return Promise.resolve(response)
    }).catch(error => {
      dispatch(getDollarBoEFailure(error))
      return Promise.reject(error)
    })
  }
}
