import * as Constants from '../constants'
import * as AdminApi from '../../apis/admin'

export const getWellAmountRequest = name => ({
  type: Constants.ADMIN_GET_WELL_AMOUNT_REQUEST,
  name,
})

export const getWellAmountSuccess = payload => ({
  type: Constants.ADMIN_GET_WELL_AMOUNT_SUCCESS,
  payload,
})

export const getWellAmountFailure = error => ({
  type: Constants.ADMIN_GET_WELL_AMOUNT_FAILURE,
  error,
})

export const getWellAmount = (name) => {
  return dispatch => {
    dispatch(getWellAmountRequest(name))
    return AdminApi.getWellAmount(name)
      .then(payload => {
        dispatch(getWellAmountSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(getWellAmountFailure(error))
        return Promise.reject(error)
      })
  }
}

export const getProjectSetupRequest = id => ({
  type: Constants.ADMIN_GET_PROJECT_SETUP_REQUEST,
  id,
})

export const getProjectSetupSuccess = (payload) => ({
  type: Constants.ADMIN_GET_PROJECT_SETUP_SUCCESS,
  payload,
})

export const getProjectSetupFailure = error => ({
  type: Constants.ADMIN_GET_PROJECT_SETUP_FAILURE,
  error,
  alert: false,
})

export const getProjectSetup = (id) => {
  return dispatch => {
    dispatch(getProjectSetupRequest(id))
    return AdminApi.getProjectSetup(id)
      .then(payload => {
        dispatch(getProjectSetupSuccess(payload))
        return Promise.resolve(payload)
      }).catch(error => {
        dispatch(getProjectSetupFailure(error))
        return Promise.reject(error)
      })
  }
}

export const saveProjectSetupRequest = id => ({
  type: Constants.ADMIN_SAVE_PROJECT_SETUP_REQUEST,
  id,
})

export const saveProjectSetupSuccess = (id, payload) => ({
  type: Constants.ADMIN_SAVE_PROJECT_SETUP_SUCCESS,
  id,
  payload,
})

export const saveProjectSetupFailure = error => ({
  type: Constants.ADMIN_SAVE_PROJECT_SETUP_FAILURE,
  error,
})

export const saveProjectSetup = (id, form) => {
  return dispatch => {
    dispatch(saveProjectSetupRequest(id))
    return AdminApi.saveProjectSetup(form)
      .then(response => {
        dispatch(saveProjectSetupSuccess(id, response))
        return Promise.resolve(response)
      }).catch(error => {
        dispatch(saveProjectSetupFailure(error))
        return Promise.reject(error)
      })
  }
}

export const getProjectDpiListRequest = id => ({
  type: Constants.ADMIN_PROJECT_DPI_LIST_REQUEST,
  id,
})

export const getProjectDpiListSuccess = (payload) => ({
  type: Constants.ADMIN_PROJECT_DPI_LIST_REQUEST,
  payload,
})

export const getProjectDpiListFailure = error => ({
  type: Constants.ADMIN_PROJECT_DPI_LIST_FAILURE,
  error,
})

export const getProjectDpiList = (id) => {
  return dispatch => {
    dispatch(getProjectDpiListRequest(id))
    return AdminApi.getProjectDpiList(id)
      .then(response => {
        dispatch(getProjectDpiListSuccess(response))
        return Promise.resolve(response)
      }).catch(error => {
        dispatch(getProjectDpiListFailure(error))
        return Promise.reject(error)
      })
  }
}

export const getDollarBoERequest = (projectName) => ({
  type: Constants.GET_DOLLAR_BOE_REQUEST,
  projectName
})

export const getDollarBoESuccess = (payload) => ({
  type: Constants.GET_DOLLAR_BOE_SUCCESS,
  payload
})

export const getDollarBoEFailure = (error) => ({
  type: Constants.GET_DOLLAR_BOE_FAILURE,
  error
})

export const getDollarBoE = (projectName) => {
  return dispatch => {
    dispatch(getDollarBoERequest(projectName))
    return AdminApi.getDollarBoE(projectName)
    .then(response => {
      dispatch(getDollarBoESuccess(response))
      return Promise.resolve(response)
    }).catch(error => {
      dispatch(getDollarBoEFailure(error))
      return Promise.reject(error)
    })
  }
}

export const getRecentDpiRequest = id => ({
  type: Constants.ADMIN_PROJECT_DPI_GET_REQUEST,
  id,
})

export const getRecentDpiSuccess = (payload) => ({
  type: Constants.ADMIN_PROJECT_DPI_GET_SUCCESS,
  payload,
})

export const getRecentDpiFailure = error => ({
  type: Constants.ADMIN_PROJECT_DPI_GET_FAILURE,
  error,
  alert: false,
})

export const getRecentDpi = (id) => {
  return dispatch => {
    dispatch(getRecentDpiRequest(id))
    return AdminApi.getRecentDpi(id)
      .then(response => {
        dispatch(getRecentDpiSuccess(response))
        return Promise.resolve(response)
      }).catch(error => {
        dispatch(getRecentDpiFailure(error))
        return Promise.reject(error)
      })
  }
}

export const postProjectDpiRequest = id => ({
  type: Constants.ADMIN_PROJECT_DPI_POST_REQUEST,
  id,
})

export const postProjectDpiSuccess = (payload) => ({
  type: Constants.ADMIN_PROJECT_DPI_POST_SUCCESS,
  payload,
})

export const postProjectDpiFailure = error => ({
  type: Constants.ADMIN_PROJECT_DPI_POST_FAILURE,
  error,
})

export const postProjectDpi = (id, attachmentToUpload, payload) => {
  return dispatch => {
    dispatch(postProjectDpiRequest(id))

    return Promise.resolve().then(() => {
      if (attachmentToUpload) {
        return AdminApi.postAttachment(attachmentToUpload)
      }
      return Promise.resolve({ id: payload.attachmentId, name: payload.attachmantName })
    }).then(res => {
      const _payload = Object.assign({}, payload, {
        projectName: id,
        attachmentId: res.id,
        attachmantName: res.name,
      })
      return AdminApi.postProjectDpi(_payload)
    }).then(response => {
      dispatch(publishProjectDpi(response.projectSpaceId, response.projectName))
      dispatch(postProjectDpiSuccess(response))
      return Promise.resolve(response)
    }).catch(error => {
      dispatch(postProjectDpiFailure(error))
      return Promise.reject(error)
    })
  }
}

export const publishProjectDpiRequest = id => ({
  type: Constants.ADMIN_PROJECT_DPI_PUBLISH_REQUEST,
  id,
})

export const publishProjectDpiSuccess = (payload) => ({
  type: Constants.ADMIN_PROJECT_DPI_PUBLISH_SUCCESS,
  payload,
})

export const publishProjectDpiFailure = error => ({
  type: Constants.ADMIN_PROJECT_DPI_PUBLISH_FAILURE,
  error,
})

export const publishProjectDpi = (id, projectName) => {
  return dispatch => {
    dispatch(publishProjectDpiRequest(id))

    return AdminApi.publishProjectDpi(id)
    .then(response => {
      dispatch(publishProjectDpiSuccess({ projectName, ...response }))
      return Promise.resolve(response)
    }).catch(error => {
      dispatch(publishProjectDpiFailure(error))
      return Promise.reject(error)
    })
  }
}
