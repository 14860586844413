import * as Constant from '../actions/constants'
import { combineReducers } from 'redux'

export const optionsGenerator = (type) => (state = [], action) => {
  if (action.type === type) return action.payload ? action.payload : []
  return state
}

export const openWorkCategories = optionsGenerator(Constant.SAND_RESERVES_GET_OPENWORK_CATEGORIES_SUCCESS)
export const depletions = optionsGenerator(Constant.SAND_RESERVES_GET_DEPLETIONS_SUCCESS)
export const boProfiles = optionsGenerator(Constant.SAND_RESERVES_GET_BO_PROFILES_SUCCESS)
export const bgProfiles = optionsGenerator(Constant.SAND_RESERVES_GET_BG_PROFILES_SUCCESS)
export const areaNames = optionsGenerator(Constant.SAND_RESERVES_GET_AREA_NAMES_SUCCESS)
export const areaDiscountFactors = optionsGenerator(Constant.SAND_RESERVES_GET_AREA_DISCOUNT_FACTORS_SUCCESS)

export default combineReducers({
  openWorkCategories,
  depletions,
  boProfiles,
  bgProfiles,
  areaNames,
  areaDiscountFactors,
})

export const getBoDropdownOptions = (state) => {
  return state.sandReserveOptions.boProfiles.sort((a, b) => a.fvfProfileName.trim().localeCompare(b.fvfProfileName.trim()))
}

export const getBgDropdownOptions = (state) => {
  return state.sandReserveOptions.bgProfiles.sort((a, b) => a.fvfProfileName.trim().localeCompare(b.fvfProfileName.trim()))
}

export const findOpenworkCategoryLabels = (state) => () => {
  return state.sandReserveOptions.openWorkCategories.map(item => item.value.trim()).sort()
}

export const findPayClassificationLabels = (state) => () => {
    var result = state.sandReserveOptions.depletions.map(item => item.type.trim()).sort()
    return result
}


export const findPayCalulationLabels = (state) => () => {
    return ["CURRENT","FREEZE"];
}

export const findBoProfileLabels = (state) => () => {
  return state.sandReserveOptions.boProfiles.map(item => item.fvfProfileName.trim()).sort()
}

export const findBgProfileLabels = (state) => () => {
  return state.sandReserveOptions.bgProfiles.map(item => item.fvfProfileName.trim()).sort()
}

export const findAreaNameLabels = (state) => () => {
  return state.sandReserveOptions.areaNames.map(item => item.analogyDataSetName.trim()).sort()
}

export const findOpenworkCategory = (state) => (query) => {
  const result = state.sandReserveOptions.openWorkCategories.find(item =>
    query && item.value.trim().toLowerCase() === query.trim().toLowerCase()
  )
  return result ? Object.assign({}, result) : result
}

export const findPayClassification = (state) => (query) => {
  const result = state.sandReserveOptions.depletions.find(item =>
    query && item.type.trim().toLowerCase() === query.trim().toLowerCase()
  )
  return result ? Object.assign({}, result) : result
}

export const findBoProfile = (state) => (query) => {
  const result = state.sandReserveOptions.boProfiles.find(item =>
    query && item.fvfProfileName.trim().toLowerCase() === query.trim().toLowerCase()
  )
  return result ? Object.assign({}, result) : result
}

export const findBgProfile = (state) => (query) => {
  const result = state.sandReserveOptions.bgProfiles.find(item =>
    query && item.fvfProfileName.trim().toLowerCase() === query.trim().toLowerCase()
  )
  return result ? Object.assign({}, result) : result
}

export const findAreaName = (state) => (query) => {
  const result = state.sandReserveOptions.areaNames.find(item =>
    query && item.analogyDataSetName.trim().toLowerCase() === query.trim().toLowerCase()
  )
  return result ? Object.assign({}, result) : result
}

export const findAreaDiscountFactorLabels = (state) => (analogyDataSetName) => {
  const matched = findAreaName(state)(analogyDataSetName)
  let result = []

  if (matched) {
    result = state.sandReserveOptions.areaDiscountFactors.filter(item =>
      item.analogyDataSetID === matched.analogyDataSetID
    ).map(item => item.analogyScenarioName.trim()).sort()
  }

  return result
}

export const findAreaDiscountFactor = (state) => (analogyDataSetName, query) => {
  
  const matched = findAreaName(state)(analogyDataSetName)
  let result = null

  if (matched) {
    result = state.sandReserveOptions.areaDiscountFactors.find(item =>
      item.analogyDataSetID === matched.analogyDataSetID &&
      query && item.analogyScenarioName.trim().toLowerCase() === query.trim().toLowerCase()
    )
  }

  return result ? Object.assign({}, result) : result
}

export const findOpenworkCategoryById = (state) => (id) => {
  const result = state.sandReserveOptions.openWorkCategories.find(item => item.id === id)
  return result ? Object.assign({}, result, { category: result.category.trim() }) : result
}

export const findPayClassificationById = (state) => (id) => {
  const result = state.sandReserveOptions.depletions.find(item => item.id === id)
  return result ? Object.assign({}, result, { type: result.type.trim() }) : result
}

export const findBoProfileById = (state) => (id) => {
  const result = state.sandReserveOptions.boProfiles.find(item => item.fvfProfileID === id)
  return result ? Object.assign({}, result, { fvfProfileName: result.fvfProfileName.trim() }) : result
}

export const findBgProfileById = (state) => (id) => {
  const result = state.sandReserveOptions.bgProfiles.find(item => item.fvfProfileID === id)
  return result ? Object.assign({}, result, { fvfProfileName: result.fvfProfileName.trim() }) : result
}

export const findAreaNameById = (state) => (id) => {
  const result = state.sandReserveOptions.areaNames.find(item => item.analogyDataSetID === id)
  return result ? Object.assign({}, result, { analogyDataSetName: result.analogyDataSetName.trim() }) : result
}

export const findAreaDiscountFactorById = (state) => (analogyDataSetID, analogyScenarioID) => {
  const result = state.sandReserveOptions.areaDiscountFactors.find(item =>
    item.analogyDataSetID === analogyDataSetID && item.analogyScenarioID === analogyScenarioID
  )
  return result ? Object.assign({}, result, { analogyScenarioName: result.analogyScenarioName.trim() }) : result
}
