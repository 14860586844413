import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import { initializeIcons } from '@uifabric/icons'
import { Router } from 'react-router-dom'
import { initWithStore } from './decorator/withModal'
import { LOCATION_CHANGE } from './actions/constants'

import { setAppReady } from './actions/views'
import {
  getOpenWorkCategories, getDepletions, getBoProfiles,
  getBgProfiles, getAreaNames, getAreaDiscountFactors
} from './actions/wellReserves'
import { getMethodUndrilled } from './actions/wellReservesUndrilled'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import createStore from './store'
import AppRoot from './AppRoot'
import 'handsontable/dist/handsontable.full.css'
import './index.css'
import './fonts-gotham.css'
import 'react-typist/dist/Typist.css'

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import "bootstrap/dist/css/bootstrap.min.css";

// Register icons and pull the fonts from the default SharePoint CDN:
initializeIcons('/fonts/')

if (window.location.pathname.includes('/edit')) {
  window.location.replace(window.location.pathname.split('/edit')[0])
}
const pca = new PublicClientApplication(msalConfig);

const store = createStore()
initWithStore(store)
const history = createBrowserHistory({ forceRefresh: true });
history.listen((location, action) => {
  store.dispatch({ type: LOCATION_CHANGE, location })
})



ReactDOM.render( 
        <MsalProvider instance={pca}>
            <Provider store={store}>
            <Router history={history}>
                <React.Fragment>
                    <AppRoot />
                </React.Fragment>
                </Router>
            </Provider>
        </MsalProvider>,
    document.getElementById("root")
);

//)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

export default store;