import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar'
import { getSyncProjects } from '../../actions/projects'
import { createLoadingSelector } from '../../reducers/api'
import { createAllProjectSelector } from '../../reducers/projects'
import AccessControl from '../AccessControl'
import { sortArrayByStringKey } from '../../helpers/string'
import style from './style.module.css'

class CommandBarComp extends PureComponent {

  componentDidMount() {
    this.props.getSyncProjects()
  }

  projectSubItems = () => {
    const { isFetching, projects, history } = this.props

    if (isFetching) return [{ key: '1', name: 'Loading...' }]
    if (!projects.length) return [{ key: '1', name: 'No project' }]

    return projects.map(project => ({
      className: project.isRigMovedOut && style.grey,
      key: project.id,
      text: project.name,
      onClick: () => history.push(`/project/${project.id}`),
    })).sort(sortArrayByStringKey('text'))
  }

  render() {
    const { location, history } = this.props

    const menuItems = location.pathname.includes('/admin') ?
      [{
        text: 'general setting',
        key: '/general',
        iconProps: {
          iconName: 'Settings'
        },
        onClick: () => history.push('/admin/general'),
      },
      {
        text: 'roles & responsibilities',
        key: '/roles',
        iconProps: {
          iconName: 'People'
        },
        onClick: () => history.push('/admin/users/roles')
      }] : [{
        text: 'live',
        key: 'live',
        // iconProps: {
        //   iconName: 'GlobalNavButton'
        // },
        subMenuProps: {
          items: this.projectSubItems()
        }
      }]
    const farItems = location.pathname.includes('/admin') ?
      [{
        text: 'switch to live',
        key: '/project',
        onClick: () => history.push('/'),
      }] : [{
        text: 'switch to admin',
        key: '/admin',
        onClick: () => history.push('/admin/general'),
      }]

    return (
      <AccessControl
        allowedPermissions={['admin']}
        renderNoAccess={() => (
          <CommandBar
            styles={{ root: { height: '45px' } }}
            items={menuItems}
          />
        )}
      >
        <CommandBar
          styles={{ root: { height: '45px' } }}
          items={menuItems}
          farItems={farItems}
        />
      </AccessControl>
    )
  }

}

const mapStateToProps = state => ({
  isFetching: createLoadingSelector(['PROJECTS_SYNC_GET'])(state),
  projects: createAllProjectSelector(state)
})

export default withRouter(connect(mapStateToProps, { getSyncProjects })(CommandBarComp))
