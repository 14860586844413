import React, { Suspense, lazy, useState } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect, useLocation, Router, useHistory } from 'react-router-dom'
import App from './containers/App'
import PageLoader from './components/PageLoader'
import AccessControl from './components/AccessControl'
import { setRedirectUrl } from "./actions/users";
import staticContext from './helpers/staticContext'
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from 'react-redux';

const Authenticator = lazy(() => import('./containers/AuthenticationPage'))
const IndexPage = lazy(() => import('./containers/IndexPage'))
const UsersPage = lazy(() => import('./containers/UsersPage'))
const UsersRolesPage = lazy(() => import('./containers/UsersRolesPage'))
const UsersProjectsPage = lazy(() => import('./containers/UsersProjectsPage'))
// const UsersAccessPage = lazy(() => import('./containers/UsersAccessPage'))
const ProjectPage = lazy(() => import('./containers/ProjectPage'))
const ProjectSettingsPage = lazy(() => import('./containers/ProjectSettingsPage'))
const ProjectSummaryPage = lazy(() => import('./containers/ProjectSummaryPage'))
const WellSummaryPage = lazy(() => import('./containers/WellSummaryPage'))
const WellReservesEditPage = lazy(() => import('./containers/WellReservesEditPage'))
// const FlexPage = lazy(() => import('./containers/FlexPage'))
// const AdminProjectPage = lazy(() => import('./containers/AdminProjectPage'))
const AdminGeneralPage = lazy(() => import('./containers/AdminGeneralPage'))
const AdminMasterDpiPage = lazy(() => import('./containers/AdminMasterDpiPage'))
// const AdminWellDpiPage = lazy(() => import('./containers/AdminWellDpiPage'))
const AdminGOPricePage = lazy(() => import('./containers/AdminGOPricePage'))
const AdminWellScenarioPage = lazy(() => import('./containers/AdminWellScenarioPage'))
const AdminTimeCostPage = lazy(() => import('./containers/AdminTimeCostPage'))
const AdminCostStructure = lazy(() => import('./containers/AdminCostStructure'))
const AdminDrillTripRate = lazy(() => import('./containers/AdminDrillTripRate'))
const AdminPreDefinedScenario = lazy(() => import('./containers/AdminPreDefinedScenario'))
const AdminProductionProfile = lazy(() => import('./containers/AdminProductionProfile'))
const AdminDollarBoEPage = lazy(() => import('./containers/AdminDollarBoEPage'))

const PrivateRoute = ({ identification, children, setRedirectUrl }) => {
    let location = useLocation();
    //let now = new Date();
    //console.log(new Date(Date.now() + 3480000));
    //console.log("vs." + (identification.expiry > new Date(Date.now() + 3480000)))

    if (location.pathname !== "/Authenticate" && location.pathname !== identification.requestUrl) {
        setRedirectUrl(location.pathname)
    }
    return (
        (identification.access === "RESTRICTED") || ((identification.user !== null) && (identification.expiry !== null) && (identification.expiry > new Date(Date.now() + (1000 * 60 * 10)))) ? ((location.pathname !== "/Authenticate") ?
            children : <></>) :
            <>
                <BrowserRouter>
                <Switch>
                    <Route exact path="/Authenticate" component={Authenticator} />
                    {<Redirect to={{ pathname: '/Authenticate' }} />}
                 </Switch>
                </BrowserRouter>
            </>

    )
}



const AppRoot = session => {

    staticContext.init({ useSelector, useDispatch, useMsal });

    return (
        <>
            <BrowserRouter>
                <Suspense fallback={<PageLoader />}>
                    <Switch>
                        <PrivateRoute identification={session} setRedirectUrl={session.setRedirectUrl} >
                        <AuthenticatedTemplate>
                            <App>
                                <Route exact path="/" component={IndexPage} />
                                <Route exact path="/home" component={IndexPage} />
                                <Route exact path="/Authenticate" component={IndexPage} />
                                <Route exact path="/project/:projectId/well/:wellId/edit"
                                    render={props => (
                                        <AccessControl
                                            allowedPermissions={[`${props.match.params.projectId}.FULL`]}
                                            renderNoAccess={() => <Redirect to="/403" />}
                                        >
                                            <Suspense fallback={<PageLoader />}>
                                                <Route component={WellReservesEditPage} />
                                            </Suspense>
                                        </AccessControl>
                                    )}
                                />
                                <Route exact path={['/project/:projectId', '/project/:projectId/settings', '/project/:projectId/well/:wellId',
                                    '/project/:projectId/well/:wellId/drilled', '/project/:projectId/well/:wellId/undrilled',
                                    '/project/:projectId/well/:wellId/decision', '/project/:projectId/well/:wellId/decision/:tabId']}
                                    render={props => (
                                        <AccessControl
                                            oneperm
                                            allowedPermissions={[`${props.match.params.projectId}.READ`, `${props.match.params.projectId}.FULL`]}
                                            renderNoAccess={() => <Redirect to="/403" />}
                                        >
                                            <ProjectPage>
                                                <Suspense fallback={<PageLoader />}>
                                                    <Route exact path="/project/:projectId" component={ProjectSummaryPage} />
                                                    <Route exact path="/project/:projectId/settings" component={ProjectSettingsPage} />
                                                    <Route exact path={['/project/:projectId/well/:wellId',
                                                        '/project/:projectId/well/:wellId/drilled', '/project/:projectId/well/:wellId/undrilled',
                                                        '/project/:projectId/well/:wellId/decision', '/project/:projectId/well/:wellId/decision/:tabId']}
                                                        component={WellSummaryPage} />
                                                </Suspense>
                                            </ProjectPage>
                                        </AccessControl>
                                    )}
                                />
                                <Route exact path={['/admin/general/', '/admin/general/*']}
                                    render={() => (
                                        <AccessControl
                                            allowedPermissions={['admin']}
                                            renderNoAccess={() => <Redirect to="/403" />}
                                        >
                                            <AdminGeneralPage>
                                                <Suspense fallback={<PageLoader />}>
                                                    <Route exact path="/admin/general" component={AdminMasterDpiPage} />
                                                    <Route exact path="/admin/general/masterDpi" component={AdminMasterDpiPage} />
                                                    <Route exact path="/admin/general/dollarBoE" component={AdminDollarBoEPage} />
                                                    <Route exact path="/admin/general/goPrice" component={AdminGOPricePage} />
                                                    {/* <Route exact path="/admin/general/wellDpi" component={AdminWellDpiPage} /> */}
                                                    <Route exact path="/admin/general/productionProfile" component={AdminProductionProfile} />
                                                    <Route exact path="/admin/general/wellScenario/*"
                                                        render={() => (
                                                            <AdminWellScenarioPage>
                                                                <Suspense fallback={<PageLoader />}>
                                                                    <Route exact path="/admin/general/wellScenario/timeCost" component={AdminTimeCostPage} />
                                                                    <Route exact path="/admin/general/wellScenario/costStructure" component={AdminCostStructure} />
                                                                    <Route exact path="/admin/general/wellScenario/drillTripRate" component={AdminDrillTripRate} />
                                                                    {/* <Route exact path="/admin/general/wellScenario/tangibleRatio" component={AdminTangibleRatio} /> */}
                                                                    {/* <Route exact path="/admin/general/wellScenario/opex" component={AdminOpex} /> */}
                                                                    <Route exact path="/admin/general/wellScenario/scenario" component={AdminPreDefinedScenario} />
                                                                </Suspense>
                                                            </AdminWellScenarioPage>
                                                        )}
                                                    />
                                                </Suspense>

                                            </AdminGeneralPage>
                                        </AccessControl>
                                    )}
                                />
                                <Route exact path={['/admin/users', '/admin/users/*']}
                                    render={() => (
                                        <AccessControl
                                            allowedPermissions={['admin']}
                                            renderNoAccess={() => <Redirect to="/403" />}
                                        >
                                            <UsersPage>
                                                <Suspense fallback={<PageLoader />}>
                                                    <Route exact path="/admin/users/roles" component={UsersRolesPage} />
                                                    <Route exact path="/admin/users/projects" component={UsersProjectsPage} />
                                                </Suspense>
                                            </UsersPage>
                                        </AccessControl>
                                    )}
                                />
                            </App>
                        </AuthenticatedTemplate>
                        </PrivateRoute>

                        <Route exact path="/403" component={() => <h3>403 Access denied</h3>} />
                        <Route exact path="/404" component={() => <h3>404 Not Found</h3>} />

                    </Switch>
                </Suspense>
            </BrowserRouter>

        </>
    )
}

export default connect(state => state.authenSession, { setRedirectUrl })(AppRoot)
